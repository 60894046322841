import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable } from "rxjs";
import { IWhatsappConversationMetric } from "../../interfaces/whatsapp/whatsapp-conversation-metric.interface";

export class ActivityWhatsappDataSource extends DataSource<any> {
  constructor(private subject: BehaviorSubject<any[]>) {
    super();
  }

  connect(): Observable<any[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}

export class WhatsappConversationMetricsDataSource extends DataSource<IWhatsappConversationMetric> {
  constructor(private subject: BehaviorSubject<IWhatsappConversationMetric[]>) {
    super();
  }

  connect(): Observable<IWhatsappConversationMetric[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}
