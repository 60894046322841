import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class ViewInboundActiveNumberSettingsFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = fb.group({
      user: [null, Validators.required],
      authUser: [null, Validators.required],
      secret: [null, Validators.required],
      host: [null, Validators.required],
      port: [null, Validators.required],
      extension: [null, Validators.required],
      telephoneNumber: [null],
      additionalInfo: [null],
      stasisApp: [null]
    });
  }

  getControlName(controleName: string): UntypedFormControl {
    return this.form.get(controleName) as UntypedFormControl;
  }

  createSerializedConnectionSettings(): string {
    const data = this.form.value;

    let connectionSettings: any = {
      User: data.user,
      AuthUser: data.authUser,
      Secret: data.secret,
      Host: data.host,
      Port: data.port,
      Extension: data.extension,
      TelephoneNumber: data.telephoneNumber,
      AdditionalInfo: data.additionalInfo,
      StasisApp: data.stasisApp
    };

    const serializedObj: string = JSON.stringify(connectionSettings);
    return serializedObj;
  }

}
