<form [class.disabled-form]="isStatistics == true" class="survey-form">
  <ng-container *ngIf="!showMessage">
    <ng-container *ngIf="isStatistics != true; else statistics">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="survey-title">{{ survey.name }}</h2>

        <button type="button" class="no-style">
          <i *ngIf="canClose" (click)="close(null)" class="lni lni-close"></i>
        </button>
      </div>
      <p class="mb-3 text-secondary">{{ survey.description }}</p>
    </ng-container>

    <ng-template #statistics>
      <div class="mb-3 line-h">
        <div>
          <b>Customer:</b> {{customerEmail}}
        </div>
        <div>
          <b>Survey:</b> {{surveyName}}
        </div>
      </div>
    </ng-template>

    <div class="question-group" *ngFor="let group of survey.questionGroupList">
      <div class="group-card">
        <div class="group-header px-3 py-2">
          <i *ngIf="group.icon" class="{{ group.icon }} group-icon mr-3"></i>
          <h6 class="group-title">{{ group.name }}</h6>
        </div>
        <div class="group-content p-3">
          <p *ngIf="group.description != ''" class="mb-2 text-secondary">{{ group.description }}</p>

          <div class="question" *ngFor="let question of group.surveyQuestionList">
            <div [ngSwitch]="question.typology">
              <label class="question-label">
                {{ question.name }}
                <span *ngIf="question.isRequired" class="text-danger">*</span>
              </label>

              <div style="max-width: 98%" *ngIf="question.description != ''">
                <span *ngIf="question.description.length <= 344; else ellipsis" class="small text-secondary">{{question.description}}</span>
                <ng-template #ellipsis class="small text-secondary">
                  <span class="small text-secondary">
                    {{question.description.slice(0,344)}}
                    <span matTooltipClass="survey-custom-tooltip" matTooltip="{{question.description}}">...</span>
                  </span>
                </ng-template>
              </div>

              <!-- Boolean Question -->
              <div *ngSwitchCase="'Boolean'" class="question-boolean d-flex flex-row align-items-center">
                <div class="d-flex flex-row align-items-center mr-4" *ngFor="let option of question.listOptions">
                  <input type="radio"
                         [value]="option.value"
                         [(ngModel)]="question.answerValue"
                         name="question-{{question.id}}"
                         id="option-{{question.id}}-{{option.value}}"
                         (change)="validateRequiredQuestions()">
                  <label for="option-{{question.id}}-{{option.value}}" class="label-txt">{{ option.value }}</label>
                </div>
              </div>

              <!-- MultiSelection Question -->
              <div *ngSwitchCase="'MultiSelection'" class="question-multi d-flex flex-row align-items-center">
                <div class="d-flex flex-row align-items-center mr-4" *ngFor="let option of question.listOptions">
                  <input type="checkbox"
                         [value]="option.value"
                         [checked]="question.answerValues?.includes(option.value)"
                         (change)="onCheckboxChange($event, option.value, question.answerValues); validateRequiredQuestions()"
                         name="question-{{question.id}}"
                         id="option-{{question.id}}-{{option.value}}">
                  <label for="option-{{question.id}}-{{option.value}}" class="label-txt">{{ option.value }}</label>
                </div>
              </div>

              <!-- TextArea Question -->
              <div *ngSwitchCase="'TextArea'" class="question-textarea">
                <div *ngFor="let option of question.listOptions">
                  <textarea (input)="onTextareaInput($event, option); validateRequiredQuestions()"
                            [placeholder]="question.defaultValue || ''"
                            name="question-{{question.id}}"></textarea>
                </div>
              </div>

              <!-- SingleSelection Question -->
              <div *ngSwitchCase="'SingleSelection'" class="question-single d-flex flex-row align-items-center">
                <div class="d-flex flex-row align-items-center mr-4" *ngFor="let option of question.listOptions">
                  <input type="radio"
                         [value]="option.value"
                         [(ngModel)]="question.answerValue"
                         id="option-{{question.id}}-{{option.value}}"
                         name="question-{{question.id}}"
                         (change)="validateRequiredQuestions()">
                  <label for="option-{{question.id}}-{{option.value}}" class="label-txt">{{ option.value }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="showMessage">
    <div class="d-flex align-items-center justify-content-end">
      <button type="button" class="no-style">
        <i (click)="closeMessage()" class="lni lni-close"></i>
      </button>
    </div>

    <div class="mb-3">
      <h5 class="done-message text-center">
        Thank you for completing the survey!
      </h5>

      <div class="d-flex justify-content-center" *ngIf="hasTasks(surveyStatistics)">
        <h6 style="max-width: 65%" class="done-message text-center">
          Based on your selections, we’ve created a list of tasks to guide you in using the Dillo platform. You can view the list by clicking the blue button in the top right corner.
        </h6>
      </div>
    </div>
  </div>

  <div *ngIf="isStatistics != true && showMessage != true" class="form-actions d-flex flex-row justify-content-center">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <button type="button"
              class="btn link-primary"
              (click)="saveSurvey()"
              [disabled]="loading || !isFormValid">
        Save
      </button>
      <button type="button" *ngIf="survey.isRequired != true" class="no-style text-secondary mt-3" (click)="skipSurvey()" [disabled]="loading">I'll do this later</button>

      <button type="button" *ngIf="survey.isRequired != true" class="no-style text-secondary mt-1" (click)="rejectSurvey()" [disabled]="loading">Don't show this again</button>
    </div>
  </div>
</form>
