import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ICountry } from '../../../core/interfaces/common/country.interface';
import { IPaymentMethod } from '../../../core/interfaces/payment/payment-method';

@Injectable()
export class EditPaymentMethodFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = fb.group({
      cardNumber: [null, Validators.required],
      expMonth: [null, Validators.required],
      expYear: [null, [Validators.required,
        Validators.min(new Date().getFullYear())]],
      isDefault: [false],
      cardholderName: [null, Validators.required],
      country: [null, Validators.required],
      address: [null, Validators.required],
      city: [null, Validators.required],
      state: [null, Validators.required],
      postalCode: [null, Validators.required]
    })
  }

  getFormControl(property: string): UntypedFormControl {
   return this.form.get(property) as UntypedFormControl;
  }

  createCardInfo(cardInfo): IPaymentMethod {
    const data = this.form.value;

    let paymentMethod: IPaymentMethod = {
      last4: data.cardNumber,
      expirationMonth: data.expMonth,
      expirationYear: data.expYear,
      brand: cardInfo.issuer,
      id: cardInfo.id,
      isDefault: data.isDefault,
      type: cardInfo.type,
      cardholderName: data.cardholderName,
      country: cardInfo.country,
      address: data.address,
      city: data.city,
      postalCode: data.postalCode,
      state: data.state,
      email: data.email,
      telephoneNumber: data.telephoneNumber
    };

    return paymentMethod;
  }


  setValidator(property: string) {
    var formControl = this.form.get(property);
    formControl.setValidators(Validators.required);
  }

  clearValidators(property: string) {
    var formControl = this.form.get(property);
    formControl.clearValidators();
  }

  forbiddenNamesValidator(names: ICountry[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = names.findIndex(name => {
        return (new RegExp('\^' + name.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    };
  }
}
