import { formatDate } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { AuthorizeService } from '../../../../../../api-authorization/authorize.service';
import { CallType } from '../../../../core/constants/call-type';
import { ApiBaseUrl } from '../../../../core/constants/common.constants';
import { ScreenSize } from '../../../../core/constants/screen-size.constants';
import { ActivityWhatsappDataSource } from '../../../../core/datasource/whatsapp/whatsapp-activity-datasource';
import { IActivityRequest } from '../../../../core/interfaces/common/activity-request.interface';
import { IExportFileRequestParams } from '../../../../core/interfaces/common/export-file-request-params.interface';
import { FilterGridData } from '../../../../core/interfaces/filter/filter-operator';
import { IRequestHistory } from '../../../../core/interfaces/voice/voice-request-history';
import { WhatsappInboundStatistics } from '../../../../core/interfaces/whatsapp/whatsapp-inbound-statistics';
import { WhatsappOutboundStatistics } from '../../../../core/interfaces/whatsapp/whatsapp-outbound-statistics';
import { IWhatsappSelectTemplate } from '../../../../core/interfaces/whatsapp/whatsapp-provider.interface';
import { WhatsappStatisticsService } from '../../../../core/services/whatsapp/whatsapp-statistics.service';
import { RandomMessageComponent } from '../../random-message/random-message.component';
import { DetailedActivityFormService } from './detailed-activity-form.service';

@Component({
  selector: 'app-detailed-activity',
  templateUrl: './detailed-activity.component.html',
  styleUrl: './detailed-activity.component.scss',
  providers: [DetailedActivityFormService, WhatsappStatisticsService]
})
export class DetailedActivityComponent {

  public height: Number = 0; //the height of the grid

  private page: number = 1;
  public pageSize: number = 20;
  private pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private advancedFilters: string[] = ['guid'];

  public totalItems: number;
  public dataSourceOutbound: ActivityWhatsappDataSource | null;
  public dataSourceInbound: ActivityWhatsappDataSource | null;

  public activityInbound: BehaviorSubject<WhatsappInboundStatistics[]> = new BehaviorSubject<WhatsappInboundStatistics[]>([]);
  public activityOutbound: BehaviorSubject<WhatsappOutboundStatistics[]> = new BehaviorSubject<WhatsappOutboundStatistics[]>([]);

  public displayedColumnsOutbound = ['id', 'Sender', 'TelephoneNumber', 'Timestamp', 'SentOn', 'Status', 'messageType', 'action'];
  public displayedColumnsInbound = ['id', 'InboundActiveNumber', 'Source', 'Timestamp', 'ReceivedOnUtcTimestamp', 'messageType', 'action'];

  public maxDate: Date = new Date();
  public minEndDate: Date = new Date(`${this.maxDate.getFullYear()}-${this.maxDate.getMonth() + 1}-1`);
  public showMatCard: boolean = false;
  public loading: boolean = false;
  public noItems: boolean = false;
  public narrowResults: boolean = false;
  public disabledSubmitBtn: boolean = true;
  public token: string;
  public filterBtn: string = 'Advanced filters';
  public iconClass: string = 'lni-angle-double-down';
  public timeZoneId = "";
  public typeArray: string[] = [CallType.Outbound, CallType.Inbound];
  public statusArray: string[] = ['Processed', 'Pending'];

  //from admin
  public adminRequest: IActivityRequest = null;
  public lastFilterDate: Date = null;
  public innerWidth: any;
  public tabletMode: boolean = false;

  public filterArray: FilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;

  public narrowResultsSbj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() isAdmin: boolean = false;
  @Input() requestCustomerCode: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() openDialog = new EventEmitter<any>();

  constructor(private whatsappStatisticsService: WhatsappStatisticsService,
    private formService: DetailedActivityFormService,
    private authorizeService: AuthorizeService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog, private titleService: Title) {

    this.titleService.setTitle("WhatsApp Activity - Dillo");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;

    if (this.innerWidth < ScreenSize.BreakpointLaptop) {
      this.tabletMode = true;
      return;
    }

    this.tabletMode = false;
  }

  ngOnInit() {
    const observer = new ResizeObserver(entries => {
      //important! adjust the value to be subtracted for a good fit of the table on the page 
      this.height = entries[0].contentRect.height - 450;
    });

    observer.observe(document.querySelector('body'));

    window.innerWidth < ScreenSize.BreakpointLaptop ? this.tabletMode = true : this.tabletMode = false;

    this.narrowResultsSbj.subscribe(x => {
      this.narrowResults = x;
      if (!x) {
        this.formService.clearValidators(this.advancedFilters);
        this.filterBtn = 'Advanced filters';
        this.iconClass = 'lni-angle-double-down';
        this.guid.setValue(null);
        this.guid.markAsUntouched();
      }
      else {
        this.formService.setGuidValidators("guid");
        this.filterBtn = ' Hide advanced filters';
        this.iconClass = 'lni-angle-double-up'
      }
    });

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p == null) {
        return;
      }

      this.loadActivityHistory();
    });

    this.type.valueChanges.subscribe(value => {
      this.resetAllData();
    });

    this.status.valueChanges.subscribe(value => {
      if (value == 'Processed') {
        this.displayedColumnsOutbound = ['id', 'Sender', 'TelephoneNumber', 'Timestamp', 'SentOn', 'Status', 'messageType', 'action'];
      }
      else {
        this.displayedColumnsOutbound = ['id', 'Sender', 'TelephoneNumber', 'Timestamp', 'messageType', 'action'];
      }

      this.resetAllData();
    });

    this.dataSourceOutbound = new ActivityWhatsappDataSource(this.activityOutbound);
    this.dataSourceInbound = new ActivityWhatsappDataSource(this.activityInbound);

    this.authorizeService.getAccessToken().subscribe(x => this.token = x);
    this.timeZoneId = localStorage.getItem("timeZoneId");
  }

  get form() {
    return this.formService.form;
  }

  get startDate() {
    return this.formService.getFormControl('startDate');
  }

  get endDate() {
    return this.formService.getFormControl('endDate');
  }

  get guid() {
    return this.formService.getFormControl('guid');
  }

  get type() {
    return this.formService.getFormControl('type');
  }

  get status() {
    return this.formService.getFormControl('status');
  }

  search() {
    this.pageSubject.next(1);
  }

  openSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

  createWhatsappTemplateObject(): IWhatsappSelectTemplate {
    return {
      id: this.generateSmallGUID(),
      name: '',
      languageCode: '',
      components: {
        id: '',
        header: {
          id: '',
          text: '',
          type: ''
        },
        body: {
          id: null,
          text: null
        },
        footer: {
          id: '',
          text: ''
        },
        buttons: null,
        isDynamicButtonUrlVisible: null,
      },
      category: null,
      templateId: null
    };
  }

  generateSmallGUID() {
    return 'xxxxxxxx'.replace(/[x]/g, function () {
      return (Math.random() * 16 | 0).toString(16);
    });
  }

  checkCommonArray(type: string, commonArr: string[]) {
    if (commonArr.includes(type)) {
      return true;
    }

    return false;
  }

  showMedia(element) {
    try {
      let whatsappTemplate: IWhatsappSelectTemplate = this.createWhatsappTemplateObject();

      if (this.checkCommonArray(element.messageType, ['Text'])) {
        whatsappTemplate.components.body.text = element.messageContent;
      }

      if (this.checkCommonArray(element.messageType, ['Image', 'Video', 'Sticker', 'Audio'])) {
        whatsappTemplate.components.header.type = element.messageType;
        whatsappTemplate.components.header.example = element.messageContent;
      }

      if (this.checkCommonArray(element.messageType, ['Location', 'Document'])) {
        whatsappTemplate.components.header.type = element.messageType;
      }

      if (this.checkCommonArray(element.messageType, ['Contacts'])) {
        whatsappTemplate.components.header.type = element.messageType;
        let jsonString: string = element.messageContent;
        whatsappTemplate.whatsappMessageContactsContent = JSON.parse(jsonString);
        whatsappTemplate.unstructuredData = {
          useCase: "contacts-dateTime",
          date: element.createdOnUtc
        };

        console.log(whatsappTemplate.whatsappMessageContactsContent);
      }

      if (this.checkCommonArray(element.messageType, ['Interactive'])) {
        whatsappTemplate.components.header.type = element.messageType;
        let jsonString: string = element.messageContent;
        let content = JSON.parse(jsonString);

        //for the moment those cannot be opened
        //if (content.type == WhatsappMessageInteractiveType.Product || content.type == WhatsappMessageInteractiveType.ProductList) {
        //  this.openSnackBar(`Unsupported type!`, "Ok", 3000);
        //  return;
        //}

        whatsappTemplate.whatsappMessageInteractiveContent = content;
        console.log(whatsappTemplate.whatsappMessageInteractiveContent);
      }

      let data = {
        id: element.id,
        templateName: null,
        messageType: element.messageType,
        template: whatsappTemplate
      };

      this.openDialog.emit(data);
    }
    catch (err) {
      console.log(err);
    }
  }

  openSideDialog(element) {
    if (element.templateId != null && element.templateId != '') {
      var request = this.formService.createActivityRequest(this.page, this.pageSize, this.requestCustomerCode);
      this.whatsappStatisticsService.getWhatsappTemplate(request, element.templateId).subscribe(response => {
        if (response == null) {
          this.openSnackBar(`Templete ${element.templateId} was not found!`, "Ok", 3000);
          return;
        }

        let data = {
          id: element.id,
          templateName: element.templateId,
          messageType: element.messageType,
          template: response
        };

        this.openDialog.emit(data);
      });

      return;
    }

    this.showMedia(element);
  }

  loadActivityHistory() {
    this.loading = true;
    this.showMatCard = false;
    this.noItems = false;
    var today = new Date();
    var request = this.formService.createActivityRequest(this.page, this.pageSize, this.requestCustomerCode);

    if (this.isAdmin && this.lastFilterDate != null) {
      request.startDate = this.lastFilterDate;
      request.endDate = this.lastFilterDate;
    }

    if (this.adminRequest != null) {
      request.guid = this.adminRequest.guid;
      request.isProcessed = this.adminRequest.isProcessed;
      request.startDate = this.adminRequest.startDate;
      request.endDate = this.adminRequest.endDate;
      this.lastFilterDate = this.adminRequest.startDate;

      if (request.isProcessed) {
        this.status.setValue('Processed');
      }
      else {
        this.status.setValue('Pending');
      }

      this.adminRequest = null;
    }

    request.startDate.setHours(today.getUTCHours());
    request.endDate.setHours(today.getUTCHours());

    request.filters = this.filterArray;
    request.sortField = this.sortField;
    request.sortDirection = this.sortDirection;

    this.getStatistics(request, this.type.value == CallType.Inbound);
  }

  getStatistics(request: IActivityRequest, isInbound: boolean) {
    this.whatsappStatisticsService.getStatisticsActivity(request, isInbound).subscribe({
      next: data => {
        if (isInbound) {
          this.activityInbound.next(data.data);
        }
        else {
          this.activityOutbound.next(data.data);
        }

        this.loading = false;
        this.paginator.pageIndex = this.page - 1;

        if (data.data.length > 0) {
          this.showMatCard = true;
        }
        else {
          this.noItems = true;
          return;
        }

        if (this.page == 1) {
          this.totalItems = data.total;
        }

        if (request.guid != null) {
          this.totalItems = 1
        }
      }
    });
  }

  loadInformation(request: IActivityRequest, isInbound: boolean) {
    this.adminRequest = request;
    this.type.setValue(isInbound ? CallType.Inbound : CallType.Outbound);
    this.search();
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  setMinEndDate(event) {
    this.minEndDate = event;
  }

  startMonthHandler(event, datepicker) {
    //getMonth() is 0 indexed, in order to get the current month +1
    var date = new Date(`${event.getFullYear()}-${event.getMonth() + 1}-1`);
    this.formService.setDateValue(date, "startDate");
    this.setMinEndDate(date);
    //datepicker.close();
  }

  endMonthHandler(event: Date, datepicker) {
    //getMonth() is 0 indexed, in order to get the current month +1
    var today = new Date();
    var date = new Date(`${event.getFullYear()}-${event.getMonth() + 1}-${today.getDate()}`);

    if (event.getMonth() + 1 != today.getMonth() + 1) {
      date = new Date(event.getFullYear(), event.getMonth() + 1, 0);
    }

    this.formService.setDateValue(date, "endDate");
    //datepicker.close();
  }

  showFilterArea() {
    this.narrowResultsSbj.next(!this.narrowResults);
  }

  formatDate(date) {
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    return formatDate(date, format, locale);
  }

  exportToExcel() {
    if (this.type.value == CallType.Outbound && this.status.value == 'Pending') {
      const format = 'yyyy-MM-dd';
      const locale = 'en-US';

      let fStartDate = formatDate(this.startDate.value, format, locale);
      let fEndDate = formatDate(this.endDate.value, format, locale);
      var url = `${ApiBaseUrl}whatsappstatistics/export-pending?userToken=${this.token}
              &startDate=${fStartDate}
              &endDate=${fEndDate}&processed=false&code=${this.requestCustomerCode}`;

      window.location.href = url;
      return;
    }

    this.loading = true;
    let formatedStartDate = this.formatDate(this.startDate.value);
    let formatedEndDate = this.formatDate(this.endDate.value);

    let request: IExportFileRequestParams = {
      startDate: formatedStartDate,
      endDate: formatedEndDate,
      userToken: this.token,
      processed: true
    };

    var okMessage = "<div> Your request is being procesed.</div> <div> You will receive an email when the export is ready.</div>";

    let userCode = this.requestCustomerCode != null ? this.requestCustomerCode : '';
    this.whatsappStatisticsService.exportDetailedActivity(request, this.type.value == CallType.Inbound, userCode).subscribe(response => {
      this.loading = false;
      if (response.entity) {
        this.openExportMessage(okMessage);
        return;
      }

      if (response.errorCode == null || response.errorCode == "") {
        response.errorCode = "An error occurred and your request could not be processed, please try again later!"
      }

      var message = `<div>${response.errorCode}</div>`;
      this.openExportMessage(message);
    });
  }

  openExportMessage(message: string) {
    const dialogRef = (this.dialog.open(RandomMessageComponent, {
      maxWidth: "55rem",
      minWidth: '30rem',
      maxHeight: "40rem",
      data: message
    }));
  }

  copyRequestIdToClipboard(element: IRequestHistory) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = element.id;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  addFilterComponent(event: FilterGridData) {
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      var existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);
      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);
    }

    this.page = 1;
    this.loadActivityHistory();
  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    if (this.sortField.toLowerCase() == "status") {
      this.sortField = "FinalStatus";
    }
    this.page = 1;

    //call again the load method;
    this.loadActivityHistory();
  }

  resetAllData() {
    this.filterArray = [];
    this.sortDirection = null;
    this.sortField = null;
    this.totalItems = 0;

    this.showMatCard = false;

    this.activityInbound.next([]);
    this.activityOutbound.next([]);
  }

}
