<div mat-dialog-title class="mt-4">
  <b>There is already a payment method that is default!</b>
</div>

<div mat-dialog-content class="mt-3">
  Are you sure you want to set this payment method as default?
</div>

<div mat-dialog-actions class="mt-4 mb-4">
  <button mat-raised-button (click)="cancel()">No, keep the old one</button>
  <button mat-raised-button cdkFocusInitial color="primary" (click)="setDefault()">
    Yes, mark it as default
  </button>
</div>
