<div *ngIf="bot!=null" class="bot-header mat-elevation-z5">
  <div class="d-flex justify-content-between bot-header-container">
    <div class="d-flex mt-1">
      <h2 class="align-self-center">{{bot.name}}</h2>&nbsp;
      <span class="align-self-center mb-1">(v {{bot.currentVersion}})</span>
    </div>
    <div class='align-self-center'>
      <div class="float-right">
        <span class="mr-3 pt-2" *ngIf="customerEnableChatbot==true">
          <mat-slide-toggle [formControl]="enableChatBot">Enable chatbot</mat-slide-toggle>
        </span>
        <span [matTooltip]='trainBtnTooltip'>
          <button mat-raised-button class="ml-3" (click)="trainBot()" color="primary" [disabled]="bot.providerCode=='DialogFlow' || bot.needsTraining==false || checkTraining || bot.trainingStatus!='NeedsTraining'">
            <span *ngIf="bot.trainingStatus=='NeedsTraining'">Train</span>
            <span *ngIf="bot.trainingStatus=='Completed'">Trained</span>
            <div *ngIf="bot.trainingStatus=='InProgress'" class="lds-facebook"><div></div><div></div><div></div></div>
            <span *ngIf="bot.trainingStatus=='InProgress'">Training</span>

          </button>

        </span>
        <span [matTooltip]="bot.providerCode!='DialogFlow' && bot.needsTraining ? 'This bot needs training first' : bot.status=='Published'?'Already published, make other changes':'Publish bot'" class="ml-4">
          <button mat-raised-button (click)="openPublishDialog()" color="accent" [disabled]="bot.status=='Published' || (bot.providerCode!='DialogFlow' && (bot.needsTraining || checkTraining==true || bot.status!='Completed'))"><span>Publish</span></button>
        </span>

      </div>
    </div>
  </div>
</div>
