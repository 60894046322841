import { FilterOperator } from "../interfaces/filter/filter-operator";

export const FilterOperatorType = {
  Number: 'Number',
  String: 'String',
  Boolean: 'Boolean',
  Date: 'Date',
  Timestamp:'Timestamp'
};

export const FilterGridOperatorList: { [key: string]: string[] } = {
  [FilterOperatorType.Number]: ['Equals', 'Greater Than', 'Greater Than Or Equals', 'Less Than', 'Less Than Or Equals', 'Not Equal'],
  [FilterOperatorType.String] : ['Starts With', 'End With', 'Contains', 'Equals', 'Not Equal'] ,
  [FilterOperatorType.Boolean] : ['Equals'] ,
  [FilterOperatorType.Date]: ['Equals', 'Greater Than Or Equals', 'Greater Than', 'Less Than', 'Less Than Or Equals', 'Not Equal'],
  [FilterOperatorType.Timestamp]: ['Equals', 'Greater Than Or Equals', 'Greater Than', 'Less Than', 'Less Than Or Equals', 'Not Equal'] 
}
