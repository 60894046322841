<div class="mt-4" [hidden]="!loading">
  <div class="progress-spinner-container">
    <div class="progress-spinner-content">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="75"></mat-progress-spinner>
    </div>
  </div>
</div>

<mat-card>
  <mat-card-content>
    <div class="container-fluid">
      <div class="row full-width">
        <div class="col-sm-5">
          <mat-form-field class="search-field">
            <mat-label>Country Name or Dial code</mat-label>
            <input matInput
                   type="text"
                   placeholder=""
                   [formControl]="search"
                   autocomplete="off" />
            <button *ngIf="searchFilter.length" (click)="search.setValue('')" matSuffix mat-icon-button aria-label="Clear">
              <b class="lni-close small"></b>
            </button>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="custom-mat-select">
            <mat-label>Billing Type</mat-label>
            <mat-select [formControl]="billingType">
              <mat-option [disabled]="type.isDisabled" *ngFor="let type of billingTypesList" [value]="type.billingType">
                {{type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>

    <div *ngIf="noItems==false && (search.value=='' || billingType.value==null)" class="undefined-list mt-4">
      Please type country name or a dial code and choose a billing type!
    </div>
    <div *ngIf="noItems && search.value!=''" class="undefined-list mt-4">
      Please type a different country name or a dial code or choose another billing type!
    </div>

  </mat-card-content>
</mat-card>

<mat-card class="mt-4 mat-card-items" [hidden]="!showMatCard || billingType.value!='PayPerUse'">

  <mat-card-content>
    <div class="items-table-container" [style.max-height.px]="height">
      <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="itemsDataSource" class="full-width">
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Name</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Name'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
            <span *ngIf="tabletMode">Name</span>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="Prefix">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Prefix</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Prefix'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
            <span *ngIf="tabletMode">Prefix</span>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.prefix}} </td>
        </ng-container>

        <ng-container matColumnDef="SetupFee">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Setup Fee</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'SetupFee'"
                             [propertyType]="'Number'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
            <span *ngIf="tabletMode">Setup Fee</span>
            <!--<span *ngIf="billingType.value=='FlatRate' || billingType.value=='FlatRateExtraChannels'">/ {{defaultChannels}} channels</span>-->
          </th>
          <td mat-cell *matCellDef="let element"> {{element.setupFee | number : '1.3-5'}} € </td>
        </ng-container>

        <ng-container matColumnDef="MonthlyFee">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Monthly Fee</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'MonthlyFee'"
                             [propertyType]="'Number'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
            <span *ngIf="tabletMode">Monthly Fee</span>
            <!--<span *ngIf="billingType.value=='FlatRate' || billingType.value=='FlatRateExtraChannels'">/ {{defaultChannels}} channels</span>-->
          </th>
          <td mat-cell *matCellDef="let element"> {{element.monthlyFee | number : '1.3-5'}} € </td>
        </ng-container>

        <ng-container matColumnDef="PricePerInterval">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Price / interval</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Price'"
                             [propertyType]="'Number'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
            <span *ngIf="tabletMode">Price / interval</span>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.pricePerInterval | number : '1.3-5'}} € </td>
        </ng-container>

        <ng-container matColumnDef="Interval">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Interval (sec.)</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Interval'"
                             [propertyType]="'Number'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.interval}} </td>
        </ng-container>

        <!--<ng-container matColumnDef="extraSetupFee" *ngIf="billingType.value=='FlatRate' && this.userRole!='SuperAdmin'">
      <th mat-header-cell *matHeaderCellDef>Extra Setup Fee/1 channel</th>
      <td mat-cell *matCellDef="let element"> {{element.aditionalSetupFee | number : '1.3-5'}} €</td>
    </ng-container>

    <ng-container matColumnDef="extraMonthlyFee" *ngIf="billingType.value=='FlatRate' && this.userRole!='SuperAdmin'">
      <th mat-header-cell *matHeaderCellDef>Extra Monthly Fee/1 channel</th>
      <td mat-cell *matCellDef="let element"> {{element.aditionalMonthlyFee | number : '1.3-5'}} €</td>
    </ng-container>-->

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>


  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator [length]="totalItems"
                   [pageSize]="pageSize"
                   (page)="handlePage($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>

<mat-tab-group mat-stretch-tabs class="flat-rate-tabs"
               *ngIf="billingType.value=='FlatRate' || billingType.value=='FlatRateExtraChannels'"
               #tabGroup (selectedTabChange)="tabChanged($event)"
               [(selectedIndex)]="selectedTab">

  <mat-tab label="Default Channels">
    <mat-card class="mt-4 mat-card-items" [hidden]="!showMatCard">

      <mat-card-content>

        <div class="items-table-container" [style.max-height.px]="height">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="itemsDataSource" class="full-width">
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Name</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Name'"
                                 [propertyType]="'String'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Name</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="Prefix">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Prefix</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Prefix'"
                                 [propertyType]="'String'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Prefix</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.prefix}} </td>
            </ng-container>

            <ng-container matColumnDef="SetupFee">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Setup Fee /{{defaultChannels}} channels</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'SetupFee'"
                                 [propertyType]="'Number'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Setup Fee / <br />{{defaultChannels}} channels</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.setupFee | number : '1.3-5'}} €</td>
            </ng-container>

            <ng-container matColumnDef="MonthlyFee">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Monthly Fee / {{defaultChannels}} channels</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'MonthlyFee'"
                                 [propertyType]="'Number'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Monthly Fee /<br /> {{defaultChannels}} channels</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.monthlyFee | number : '1.3-5'}} €</td>
            </ng-container>

            <!--<ng-container matColumnDef="extraSetupFee" *ngIf="billingType.value=='FlatRate' && this.userRole!='SuperAdmin'">
              <th mat-header-cell *matHeaderCellDef>Extra Setup Fee/1 channel</th>
              <td mat-cell *matCellDef="let element"> {{element.aditionalSetupFee | number : '1.3-5'}} </td>
            </ng-container>

            <ng-container matColumnDef="extraMonthlyFee" *ngIf="billingType.value=='FlatRate' && this.userRole!='SuperAdmin'">
              <th mat-header-cell *matHeaderCellDef>Extra Monthly Fee/1 channel</th>
              <td mat-cell *matCellDef="let element"> {{element.aditionalMonthlyFee | number : '1.3-5'}} </td>
            </ng-container>-->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>


      </mat-card-content>
      <mat-card-footer>
        <mat-paginator #paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       (page)="handlePage($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-tab>
  <mat-tab label="Aditional Channels">
    <mat-card class="mt-4 mat-card-items" [hidden]="!showMatCard">

      <mat-card-content>

        <small class="mt-1"> * Note: this prices apply only from channels > {{defaultChannels}}</small><br />
        <small>
          e.g: if you purchase an inbound number with 5 channels you will pay for the first 2 the prices from first tab and for each channel until 5 you will pay the prices set in the table bellow:
        </small>
        <div class="items-table-container" [style.max-height.px]="height">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="itemsDataSource" class="full-width">
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Name</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Name'"
                                 [propertyType]="'String'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Name</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="Prefix">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Prefix</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Prefix'"
                                 [propertyType]="'String'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Prefix</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.prefix}} </td>
            </ng-container>

            <ng-container matColumnDef="MinNumberOfChannels">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">From Channel</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'MinNumberOfChannels'"
                                 [propertyType]="'Number'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">From Channel</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.minNumberOfChannels}} </td>
            </ng-container>

            <ng-container matColumnDef="MaxNumberOfChannels">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">To Channel</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'MaxNumberOfChannels'"
                                 [propertyType]="'Number'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">To Channel</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.maxNumberOfChannels}} </td>
            </ng-container>

            <ng-container matColumnDef="SetupFee">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Setup Fee /<br /> channel</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'SetupFee'"
                                 [propertyType]="'Number'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Setup Fee / channel</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.setupFee | number : '1.3-5'}} € </td>
            </ng-container>

            <ng-container matColumnDef="MonthlyFee">
              <th mat-header-cell *matHeaderCellDef>
                <span mat-sort-header *ngIf="!tabletMode">Monthly Fee /<br /> channel</span>
                <app-filter-grid *ngIf="!tabletMode" [propertyName]="'MonthlyFee'"
                                 [propertyType]="'Number'"
                                 (filter)="addFilterComponent($event)">
                </app-filter-grid>
                <span *ngIf="tabletMode">Monthly Fee / channel</span>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.monthlyFee | number : '1.3-5'}} €</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsExtra; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsExtra;"></tr>
          </table>
        </div>


      </mat-card-content>
      <mat-card-footer>
        <mat-paginator #paginator [length]="totalItems"
                       [pageSize]="pageSize"
                       (page)="handlePage($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-tab>
</mat-tab-group>
