import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ICustomerAutoRechargeInfo } from '../../../core/interfaces/customer/customer-auto-recharge-info';

@Injectable()
export class RechargeEditFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = fb.group({
      primaryMethodId: [null, Validators.required],
      secondaryMethodId: [null],
      enableAutoRecharge: [false],
      amount: [null, [Validators.required]],
      autoRechargeCreditLimit: [null, Validators.required]
    });
  }

  clearValidators(controlNames:string[]) {
    controlNames.map(x => {
      var control = this.form.get(x) as UntypedFormControl;
      control.setValidators(null);
      control.updateValueAndValidity();
    });
  }

  setValidatorsRequired(controlNames: string[]) {
    controlNames.map(x => {
      var control = this.form.get(x) as UntypedFormControl;
      control.setValidators(Validators.required);
      control.updateValueAndValidity();
    });
  }

  setMinAmount(value:number) {
    var control = this.form.get("amount") as UntypedFormControl;

    control.setValidators([Validators.required, Validators.min(value)]);
    control.updateValueAndValidity();
  }

  getControlName(controleName: string):UntypedFormControl {
    return this.form.get(controleName) as UntypedFormControl;
  }

  createCustomerAutoRechargeInfo(customerAutoRecharge:ICustomerAutoRechargeInfo):ICustomerAutoRechargeInfo {
    const data = this.form.value;

    const autoRechargeInfo: ICustomerAutoRechargeInfo = {
      customerCode: customerAutoRecharge.customerCode,
      customerStripeId: customerAutoRecharge.customerStripeId,
      primaryPaymentMethodId: data.primaryMethodId,
      secondaryPaymentMethodId: data.secondaryMethodId,
      isAutoRechargeEnable: data.enableAutoRecharge,
      rechargeAmount: data.amount,
      autoRechargeCreditLimit: data.autoRechargeCreditLimit,
      primaryPaymentMethod: customerAutoRecharge.primaryPaymentMethod,
      secondaryPaymentMethod: customerAutoRecharge.secondaryPaymentMethod,
      currentCredits:customerAutoRecharge.currentCredits
    }

    return autoRechargeInfo;
  }
}
