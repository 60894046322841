import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'app-preview-inbound-channel-behavior',
  templateUrl: './preview-inbound-channel-behavior.component.html',
  styleUrls: ['./preview-inbound-channel-behavior.component.scss']
})
export class PreviewInboundChannelBehaviorComponent implements OnInit {

  public channelBehavior: any;

  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent) jsonTextEditor: JsonEditorComponent;

  constructor(private dialogRef: MatDialogRef<PreviewInboundChannelBehaviorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree'];
    this.editorOptions.mode = 'code';

    if (data == null) {
      return;
    }
   // console.log(data);
    this.channelBehavior = JSON.parse(data.channelBehavior);

  }

  ngOnInit() {
  }

}
