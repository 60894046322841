export interface IWhatsappTemplate {
  templateId: string;
  id: any;
  category: string;
  status?: string;
  categoryDisplayName?: string;
  customerCode: string;
  allLocalizationsAreApproved?: boolean;
  languages?: string;
  languagesStatus?: any;
  updatedOnUtc?: Date;
  localizations: IWhatsappTemplateLocalization[];
  createdOnUtc: Date;
  whatsappBusinessAccountId: string;
  inboundActiveNumber: string;
  canBeSubmited?: boolean;
}

export interface IWhatsappTemplateLocalization {
  id: any;
  status: string;
  submitErrorCode?: string;
  rejectionReason?: string;
  languageCode: string;
  languageName: string;
  components: IWhatsappTemplateComponents;
}

export interface IWhatsappTemplateComponents {
  id: any;
  header: IWhatsappTemplateHeader;
  body: IWhatsappTemplateBody;
  footer: IWhatsappTemplateFooter;
  buttons: IWhatsappTemplateButton[];
  isDynamicButtonUrlVisible?: boolean;
}

export interface ICampaignWhatsappContent {
  header: string;
  headerCaptation: string;
  headerName: string;
  latitude: number;
  longitude: number;
  address: string;
  body: string[];
  button: string;
}

export interface IWhatsappTemplateHeader {
  id: any;
  text: string;
  type: string;
  example?: string;
}

export interface IWhatsappTemplateBody {
  id: any;
  text: string;
  examples?: string[];
  addSecurityRecommendation?: boolean;
}

export interface IWhatsappTemplateFooter {
  id: any;
  text: string;
  codeExpirationMinutes?: number;
}

export interface IWhatsappTemplateButton {
  id: any;
  text: string;
  type: string;
  subtype: string;
  url: string;
  customerUrl?: string;
  example?: string;
  autofillText?: string;
  packageName?: string;
  signatureHash?: string;
}

export const WhatsappTemplateStatusName = {
  PendingApproval: "PendingApproval",
  Approved: "Approved",
  Draft: "Draft",
  UnderReview: "UnderReview",
  Rejected: "Rejected",
  Deleted: "Deleted",
}

export const WhatsappTemplateStatusList = [
  { label: "All", value: "" },
  { label: WhatsappTemplateStatusName.Approved, value: WhatsappTemplateStatusName.Approved },
  { label: WhatsappTemplateStatusName.Draft, value: WhatsappTemplateStatusName.Draft },
  { label: WhatsappTemplateStatusName.Rejected, value: WhatsappTemplateStatusName.Rejected },
  { label: "Under review", value: WhatsappTemplateStatusName.UnderReview }
]

export const WhatsappTemplateStatusKeyList: { [key: string]: string } = {
  [WhatsappTemplateStatusName.Approved]: WhatsappTemplateStatusName.Approved,
  [WhatsappTemplateStatusName.Draft]: WhatsappTemplateStatusName.Draft,
  [WhatsappTemplateStatusName.Rejected]: WhatsappTemplateStatusName.Rejected,
  [WhatsappTemplateStatusName.Deleted]: WhatsappTemplateStatusName.Deleted,
  [WhatsappTemplateStatusName.UnderReview]: "Under review",
  [WhatsappTemplateStatusName.PendingApproval]: "Submited request for approval",
}



export const WhatsappTemplateHeaderType = {
  None: "None",
  Text: "Text",
  Image: "Image",
  Video: "Video",
  Document: "Document",
  Location: "Location"
}

export const WhatsappTemplateHeaderTypeList = [
  { label: WhatsappTemplateHeaderType.None, value: WhatsappTemplateHeaderType.None, icon: "cancel" },
  { label: WhatsappTemplateHeaderType.Text, value: WhatsappTemplateHeaderType.Text, icon: " text_fields" },
  { label: WhatsappTemplateHeaderType.Image, value: WhatsappTemplateHeaderType.Image, icon: "insert_photo" },
  { label: WhatsappTemplateHeaderType.Video, value: WhatsappTemplateHeaderType.Video, icon: "videocam" },
  { label: WhatsappTemplateHeaderType.Document, value: WhatsappTemplateHeaderType.Document, icon: "attach_file" },
  { label: WhatsappTemplateHeaderType.Location, value: WhatsappTemplateHeaderType.Location, icon: "location_on" },
]

export const WhatsappTemplateButtonType = {
  None: "None",
  QuickReply: "quick_reply",
  CallToAction: "call_to_action",
  Otp: "otp"
}

export const WhatsappTemplateButtonTypeList = [
  { label: WhatsappTemplateButtonType.None, value: WhatsappTemplateButtonType.None, icon: "cancel" },
  { label: "Quick reply", value: WhatsappTemplateButtonType.QuickReply, icon: "touch_app" },
  { label: "Call to action", value: WhatsappTemplateButtonType.CallToAction, icon: "open_in_new" },
  //{ label: "OTP", value: WhatsappTemplateButtonType.Otp, icon: "touch_app" }
]

export const WhatsappTemplateButtonSubtype = {
  Link: "url",
  Phone: "phone"
}

export const WhatsappTemplateOtpButtonSubtype = {
  ONE_TAP: "ONE_TAP",
  COPY_CODE: "COPY_CODE"
}

export const WhatsappTemplateButtonSubtypeList = [
  { label: "Phone number", value: WhatsappTemplateButtonSubtype.Phone },
  { label: "Link", value: WhatsappTemplateButtonSubtype.Link }
]

export const WhatsappTemplateOtpButtonSubtypeList = [
  { label: "Copy code", value: WhatsappTemplateOtpButtonSubtype.COPY_CODE },
  { label: "One-tap autofill", value: WhatsappTemplateOtpButtonSubtype.ONE_TAP }
]


