<div mat-dialog-content>
  <div class="float-left"> Request a custom offer</div>
  <div class="float-right">
    <div class="lni-close" (click)="cancel()"></div>
  </div>
  <form [formGroup]="form" (ngSubmit)="submit()" id="ngForm" class="mb-2 pr-3">
    <mat-form-field class="full-width">
      <input type="text" matInput placeholder="Name" formControlName="name" />
      <mat-error *ngIf="name.invalid && name.touched">
        Name is required.
      </mat-error>
    </mat-form-field>

    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="Phone number" formControlName="number" />
          <mat-error *ngIf="number.invalid && number.touched">
            Phone number is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="Email address" formControlName="email" />
          <mat-error *ngIf="email.invalid && email.touched">
            Email is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-form-field class="full-width">
          <mat-label> No. phone agents (monthly)</mat-label>
          <input type="number" matInput formControlName="agentsPhoneMonthly" class="mt-2" />
          <mat-error *ngIf="agentsPhoneMonthly.invalid && agentsPhoneMonthly.touched">
            No. of agents required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-form-field class="full-width">
          <mat-label> No. phone agents(annually)</mat-label>
          <input type="number" matInput formControlName="agentsPhoneAnnually" class="mt-2" />
          <mat-error *ngIf="agentsPhoneAnnually.invalid && agentsPhoneAnnually.touched">
            No. of agents required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-form-field class="full-width">
          <mat-label> No. of agents Conference </mat-label>
          <input type="number" matInput formControlName="agentsConference" class="mt-2" />
          <mat-error *ngIf="agentsConference.invalid && agentsConference.touched">
            No. of agents required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width">
          <mat-label> No. of live agents (payed monthly)</mat-label>
          <input type="number" matInput formControlName="agentsLiveMonthly" class="mt-2" />
          <mat-error *ngIf="agentsLiveMonthly.invalid && agentsLiveMonthly.touched">
            No. of agents required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width">
          <mat-label> No. of live agents (payed annually)</mat-label>
          <input type="number" matInput formControlName="agentsLiveAnnually" class="mt-2" />
          <mat-error *ngIf="agentsLiveAnnually.invalid && agentsLiveAnnually.touched">
            No. of agents required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width">
          <mat-label>No. of remote agents (payed monthly)</mat-label>
          <input type="number" matInput formControlName="agentsRemoteMonthly" class="mt-2" />
          <mat-error *ngIf="agentsRemoteMonthly.invalid && agentsRemoteMonthly.touched">
            No. of agents required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width">
          <mat-label>No. of remote agents (payed annually)</mat-label>
          <input type="number" matInput formControlName="agentsRemoteAnnually" class="mt-2" />
          <mat-error *ngIf="agentsRemoteAnnually.invalid && agentsRemoteAnnually.touched">
            No. of agents required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Your message</mat-label>
          <textarea type="number" matInput cdkTextareaAutosize formControlName="message" rows="10" cols="40" class="p-4"></textarea>
          <mat-error *ngIf="message.invalid && message.touched">
            Message is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div *ngIf="error"> <mat-error>Something went wrong! Refresh and try again.</mat-error></div>
  <div *ngIf="invalid"> <mat-error>Please add at least 1 agent type.</mat-error></div>
  <button mat-raised-button cdkFocusInitial form="ngForm" color="accent" [disabled]="form.invalid">
    <span>Send request</span>
  </button>
</div>

<!--<div mat-dialog-actions class="action-buttons">
  <button mat-raised-button cdkFocusInitial form="ngForm" color="accent" [disabled]="form.invalid">
    <span>Send request</span>
  </button>

</div>-->
