import { BehaviorSubject, Observable } from "rxjs";
import { DataSource } from "@angular/cdk/table";
import { IInboundVoiceCallHistory } from "../interfaces/inbound/inbound-voice-call-history.interface";
import { IMenuDetectedIntent } from "../interfaces/voice/menu-detected-intent.interface";

export class InboundVoiceCallDataSource extends DataSource<any> {
  constructor(private subject: BehaviorSubject<IInboundVoiceCallHistory[]>) {
    super();
  }

  connect(): Observable<IInboundVoiceCallHistory[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}

export class MenuDetectedIntentDataSource extends DataSource<any> {
  constructor(private subject: BehaviorSubject<IMenuDetectedIntent[]>) {
    super();
  }

  connect(): Observable<IMenuDetectedIntent[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}
