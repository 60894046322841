import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { ISurvey } from '../../core/interfaces/survey/survey';
import { SurveyService } from '../../core/services/survey/survey.service';
import { SurveyComponent } from '../survey/survey.component';

@Component({
  selector: 'pending-survey',
  templateUrl: './pending-survey.component.html',
  styleUrls: ['./pending-survey.component.scss']
})
export class PendingSurveyComponent implements OnInit {

  public isSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public surveys: ISurvey[];

  public inProgress: boolean = false;

  @Input() toggleStateSubject!: BehaviorSubject<boolean>;
  @Input() isSingle!: boolean;

  constructor(private surveyService: SurveyService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  loadPendingSurveys() {
    this.surveyService.getPendingSurveys().subscribe(data => {
      //console.log("Pending surveys", data);
      this.surveys = data;
    });
  }

  get hasSurveys(): boolean {
    return this.surveys && this.surveys.length > 0;
  }

  toggleSurveys() {
    this.toggleStateSubject.next(!this.toggleStateSubject.value);
  }

  loadActiveSurvey(surveyId: string) {
    this.inProgress = true;

    this.surveyService.getSurvey(surveyId).subscribe(data => {
      this.inProgress = false;
      this.toggleSurveys();

      if (data == null) {
        this.openSnackBar('The survey is no longer available!', "Ok", 2000);
        return;
      }

      this.openSurveyDialog(data);
    });
  }

  openSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

  private openSurveyDialog(survey) {
    const isMobile = this.breakpointObserver.isMatched(Breakpoints.Handset);

    const dialogRef = this.dialog.open(SurveyComponent, {
      autoFocus: false,
      data: { survey: survey, canClose: true },
      disableClose: true,
      minWidth: isMobile ? '100vw' : '65vw',
      maxHeight: isMobile ? '100vh' : '80vh',
      height: isMobile ? '100vh' : 'auto',
      panelClass: isMobile ? 'full-screen-dialog' : '',
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value != true) {
        return;
      }

      this.isSaved.next(true);
    });
  }
}
