import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizeService } from '../api-authorization/authorize.service';
import { LocalStorageService } from './management/core/services/common/local-storage.service';
import { ManageAccountService } from './management/core/services/common/manage-account.service';
import { forkJoin, concat } from 'rxjs';
import { zip } from 'rxjs/operators';
import { UserRoles } from '../api-authorization/api-authorization.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [LocalStorageService, ManageAccountService]
})
export class AppComponent implements OnInit {
  title = 'app';
 
  constructor(private manageAccountService: ManageAccountService,
    private localStorageService: LocalStorageService,
    private authorizeService: AuthorizeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,) {
    
    const item = this.localStorageService.getWithExpiry("BetaModules");

    if (item != null) {
      return;
    }

    this.manageAccountService.getAppSettings().subscribe(value => {
      if (value == null) {
        return;
      }

      this.localStorageService.setWithExpiry("BetaModules", value, 1);

    })
    
  }

  ngOnInit() {
    forkJoin(
      this.authorizeService.getUserRole(),
      this.authorizeService.isAccessTokenExpired()
    ).subscribe(([role, expired]) => {
      if (role == UserRoles.LiveAgent || expired == false) {
        return;
      }

      this.router.navigate(["/authentication/logout"], { state: { local: true } });
    });

    //this.authorizeService.isAccessTokenExpired().subscribe(expired => {
    //  console.log('isTokenExpired', expired);
    //  if (expired === true) {
    //    this.router.navigate(["/authentication/logout"], { state: { local: true } });
    //  }
    //});
  }
}

