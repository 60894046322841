import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBotIntent } from '../../../../core/interfaces/ai/bot-intent.interface';
import { IBot } from '../../../../core/interfaces/ai/bot.interface';
import { AiManagementService } from '../../../../core/services/ai/ai-management.service';

@Component({
  selector: 'app-bot-intent-list',
  templateUrl: './bot-intent-list.component.html',
  styleUrls: ['./bot-intent-list.component.scss'],
  providers: [AiManagementService]
})
export class BotIntentListComponent implements OnInit {

  public botIntents: IBotIntent[] = [];
  public noSelectedBot: boolean = false;
  public bot: IBot = null;
  public isLoaded = false;

  constructor(private dialogRef: MatDialogRef<BotIntentListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IBot | null,
    private botService: AiManagementService) {

    if (data == null) {
      this.noSelectedBot = true;
      return;
    }
    this.bot = data;
    this.loadBotIntents(data.id);
  }

  ngOnInit() {
   
  }

  loadBotIntents(botId) {
    this.botService.getAllBotIntents(botId).subscribe(value => {
      this.botIntents = value;
      this.isLoaded = true;
    });
  }

  copyToClipboard(botIntent: IBotIntent) {
    this.dialogRef.close(botIntent);
  }

}
