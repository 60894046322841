import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IActivityRequest } from '../../../../core/interfaces/common/activity-request.interface';

@Injectable()
export class GeneralActivityFormService {

  public form: UntypedFormGroup;
  public today = new Date();
  public defaultStartDate: Date = new Date(`${this.today.getFullYear()}-${this.today.getMonth() + 1}-1`);
  public defaultEndDate: Date = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      startDate: [this.defaultStartDate, [
        Validators.required]],
      endDate: [this.today, []],
      number: [null, [Validators.required]]
    })

  }

  getFormControl(property: string): UntypedFormControl {
    return this.form.get(property) as UntypedFormControl;
  }

  clearValidators(controlNames: string[]) {
    controlNames.map(x => {
      var control = this.form.get(x) as UntypedFormControl;
      control.setValidators(null);
      control.updateValueAndValidity();
    });
  }

  setDateValue(value: Date, controlName: string) {
    var date = this.form.get(controlName) as UntypedFormControl;
    date.setValue(value);
  }

  createActivityRequest(): IActivityRequest {
    var data = this.form.value;
    const request: IActivityRequest = {
      page: 1,
      startDate: data.startDate,
      endDate: new Date(),
      pageSize: 1,
      guid: data.number,
      filters: [],
      sortDirection: "",
      sortField: ""
    }

    return request;
  }
}
