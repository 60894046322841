import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ITopicItemStatus } from '../../../../core/interfaces/voice/voice-topic-item-status';

@Component({
  selector: 'app-send-action-message',
  templateUrl: './send-action-message.component.html',
  styleUrls: ['./send-action-message.component.scss']
})
export class SendActionMessageComponent implements OnInit {

  public sendAction: ITopicItemStatus;

  constructor(public dialogRef: MatDialogRef<SendActionMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITopicItemStatus | null, private titleService: Title) {

    this.titleService.setTitle("Dillo |Sms activity");

    if (this.data == null) {
      return;
    }
    this.sendAction = data;
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }
}
