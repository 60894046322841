import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CallType } from '../../../../core/constants/call-type';
import { IActivityRequest } from '../../../../core/interfaces/common/activity-request.interface';

@Injectable()
export class DetailedActivityFormService {

  public form: UntypedFormGroup;
  public today = new Date();
  public defaultStartDate: Date = new Date(`${this.today.getFullYear()}-${this.today.getMonth() + 1}-1`);
  public defaultEndDate: Date = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      startDate: [this.defaultStartDate, [Validators.required]],
      endDate: [this.today, [Validators.required]],
      guid: [null],
      type: [CallType.Outbound, [Validators.required]],
      status: ['Processed', [Validators.required]]
    });
  }

  getFormControl(property: string): UntypedFormControl {
    return this.form.get(property) as UntypedFormControl;
  }

  clearValidators(controlNames: string[]) {
    controlNames.map(x => {
      var control = this.form.get(x) as UntypedFormControl;
      control.setValidators(null);
      control.updateValueAndValidity();
    });
  }

  setDateValue(value: Date, controlName: string) {
    var date = this.form.get(controlName) as UntypedFormControl;
    date.setValue(value);
  }

  formatDate(date) {
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    return formatDate(date, format, locale);
  }

  createActivityRequest(page: number, pageSize: number, requestCustomerCode): IActivityRequest {
    var data = this.form.value;
    let formatedStartDate = this.formatDate(data.startDate);
    let formatedEndDate = this.formatDate(data.endDate);

    const request: IActivityRequest = {
      page: page,
      startDate: new Date(formatedStartDate),
      endDate: new Date(formatedEndDate),
      pageSize: pageSize,
      guid: data.guid,
      customerCode: requestCustomerCode,
      isProcessed: data.status == 'Processed' ? 'true' : 'false'
    }

    return request;
  }

  setGuidValidators(controlName: string) {
    var control = this.form.get(controlName) as UntypedFormControl;
    control.setValidators([
      Validators.required,
      Validators.pattern("^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$")
    ]);

    control.updateValueAndValidity();
  }
}
