import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sms-voice-setting-dialog',
  templateUrl: './sms-voice-setting-dialog.component.html',
  styleUrls: ['./sms-voice-setting-dialog.component.scss']
})
export class SmsVoiceSettingDialogComponent implements OnInit {

  public title: string;
  public content: string;

  constructor(public dialogRef: MatDialogRef<SmsVoiceSettingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (this.data == null) {
      return;
    }

    this.title = data.title;
    this.content = data.content;
  }

  ngOnInit() {
   
  }

  close() {
    this.dialogRef.close(false);
  }

}
