import { DataSource } from '@angular/cdk/table';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { IVoiceCallRecording } from '../../../../../core/interfaces/voice/voice-call-recording.interface';


@Component({
  selector: 'app-recording-list',
  templateUrl: './recording-list.component.html',
  styleUrls: ['./recording-list.component.scss']
})
export class RecordingListComponent implements OnInit {
  public dataSource: VoiceRecordingDataSource | null;
  public recordingsSbj: BehaviorSubject<IVoiceCallRecording[]> = new BehaviorSubject<IVoiceCallRecording[]>([]);

  public displayedColumns = ['duration', 'price', 'storageCode', 'transcription', 'play'];

  private recordingList: IVoiceCallRecording[]=[];

  constructor(private dialogRef: MatDialogRef<RecordingListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {
    if (data == null || data.recordings.length==0) {
      return;
    }

    data.recordings.map(item => {
      if (item.url != null && item.url != '') {
        item.storageLabel = this.getStoragePeriodLabel(item.recordingStoragePeriodCode);
        
      }
      this.recordingList.push(item);
    });

    this.recordingsSbj.next(this.recordingList);
    
  }

  ngOnInit() {
    this.dataSource = new VoiceRecordingDataSource(this.recordingsSbj);
  }

  playAudio(path) {
    let audio = new Audio();
    audio.src = path;
    audio.load();
    audio.play();
  }

  getStoragePeriodLabel(recordingStoragePeriodCode: string): string {
    let label = '';
    switch (recordingStoragePeriodCode) {

      case '3-M': {
        label = '3 Months';
        break
      }
      case '6-M': {
        label = '6 Months';
        break;
      }
      case '1-Y': {
        label = '1 Year';
        break;
      }
      case '2-Y': {
        label = '2 Years';
        break;
      }
      case '3-Y': {
        label = '3 Years';
        break
      }
    }

    return label;
  }

}

export class VoiceRecordingDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IVoiceCallRecording[]>) {
    super()
  }
  connect(): Observable<IVoiceCallRecording[]> {
    return this.subject.asObservable();
  }
  disconnect() { };
}

