import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { AsteriskCallDirect } from '../../../../core/constants/asterisk-call-direct';
import { IInboundVoiceCallHistory } from '../../../../core/interfaces/inbound/inbound-voice-call-history.interface';
import { IStorageItem } from '../../../../core/interfaces/voice/voice-request-history';

@Component({
  selector: 'app-storage-details',
  templateUrl: './storage-details.component.html',
  styleUrls: ['./storage-details.component.scss']
})
export class StorageDetailsComponent implements OnInit {
  public dataSource: StorageItemDataSource | null;
  public storageItemsSbj: BehaviorSubject<IStorageItem[]> = new BehaviorSubject<IStorageItem[]>([]);

  public displayedColumns = ['createdOnUtc', 'key', 'value'];
  public timeZoneId = "";
  public telephoneNumber: string;
  public id: any;
  public did: string;
  public callDirection: string;
  public request: any;

  constructor(private dialogRef: MatDialogRef<StorageDetailsComponent>,
    private datePipe:DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {
    if (data == null || data.request==null) {
      return;
    }

    if (data.callDirection == AsteriskCallDirect.Inbound) {
      var inboundRequest = data.request as IInboundVoiceCallHistory;
      this.did = inboundRequest.inboundActiveNumber;
    }
    else
      if (data.callDirection == "Autopilot") {
        this.displayedColumns = ['key', 'value'];
        this.request = data.request;
      }

    this.telephoneNumber = data.request.telephoneNumber;
    this.id = data.request.id;
    this.callDirection = data.callDirection;

   
  }


  ngOnInit() {
    this.dataSource = new StorageItemDataSource(this.storageItemsSbj);

    this.timeZoneId = localStorage.getItem("timeZoneId");

    this.checkIfValueIsDate();
  }

  checkIfValueIsDate() {
    if (this.callDirection == "Autopilot") {
      this.storageItemsSbj.next(this.request.collectedData);
      return;
    }

    var storage: IStorageItem[] = this.data.request.storage;

    storage.map(item => {
      if (new Date(item.value).toString() !== 'Invalid Date' && isNaN(+item.value)) {
        let regexp = new RegExp('^[a-zA-Z0-9 ]+$');
        let isNotString = regexp.test(item.value);

        if (!isNotString) {
          item.value = this.datePipe.transform(item.value, 'dd/MM/yyyy HH:MM:ss');
        }
      }
    });

    this.storageItemsSbj.next(storage);
  }

}

export class StorageItemDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IStorageItem[]>) {
    super()
  }
  connect(): Observable<IStorageItem[]> {
    return this.subject.asObservable();
  }
  disconnect() { };
}
