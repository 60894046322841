import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IActivityRequest } from '../../interfaces/common/activity-request.interface';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { IChecklistGroup } from '../../interfaces/survey/checklist-group';
import { ICreationSurveyStatistics } from '../../interfaces/survey/creation-survey-statistics';
import { IStatisticsRequest } from '../../interfaces/survey/statistics-request';
import { ISurvey } from '../../interfaces/survey/survey';
import { ISurveyStatistics } from '../../interfaces/survey/survey-statistics';
import { ISurveySettings } from '../../interfaces/survey/survey-settings';

@Injectable()
export class SurveyService {

  private _baseUrl: string = `${ApiBaseUrl}surveys`;

  constructor(private httpClient: HttpClient) { }

  getSurvey(surveryId: any): Observable<ISurvey> {
    return this.httpClient.get<ISurvey>(`${this._baseUrl}/overview/${surveryId}`);
  }

  createSurvey(survey: ISurvey): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/create`, survey);
  }

  updateSurvey(survey: ISurvey): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/update`, survey);
  }

  deleteSurvey(survey: ISurvey): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, survey);
  }

  getPendingSurveys(): Observable<ISurvey[]> {
    return this.httpClient.get<ISurvey[]>(`${this._baseUrl}/pending`);
  }


  saveSurveyStatistics(surveyStats: ICreationSurveyStatistics): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/statistics/save`, surveyStats);
  }

  updateSurveyStatistics(surveyStats: ICreationSurveyStatistics): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/statistics/update`, surveyStats);
  }

  skipSurveyStatisticsAsync(surveyStats: ICreationSurveyStatistics): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/statistics/skip`, surveyStats);
  }

  rejectSurveyStatistics(surveyStats: ICreationSurveyStatistics): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/statistics/reject`, surveyStats);
  }

  getChecklist(): Observable<IChecklistGroup[]> {
    return this.httpClient.get<IChecklistGroup[]>(`${this._baseUrl}/checklist`);
  }

  completeTaskAsync(surveyId: string, taskId: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/checklist/${surveyId}/${taskId}/complete`, '');
  }

  getSurveyStatistics(activityRequest: IActivityRequest): Observable<DataSourceResponse<ISurveyStatistics>> {
    return this.httpClient.post<DataSourceResponse<ISurveyStatistics>>(`${this._baseUrl}/statistics`, activityRequest);
  }

  getSurveyStatisticsDetails(surveyId: any, customerCode: any): Observable<ISurvey> {
    return this.httpClient.get<ISurvey>(`${this._baseUrl}/statistics/${surveyId}/${customerCode}`);
  }

  getSurveyRequests(): Observable<IStatisticsRequest[]> {
    return this.httpClient.get<IStatisticsRequest[]>(`${this._baseUrl}/statistics/requests`);
  }

  getSettings(): Observable<ISurveySettings> {
    return this.httpClient.get<ISurveySettings>(`${this._baseUrl}/settings`);
  }
}
