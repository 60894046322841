import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-set-default-sender',
  templateUrl: './set-default-sender.component.html',
  styleUrls: ['./set-default-sender.component.scss']
})
export class SetDefaultSenderComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SetDefaultSenderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {
    if (data == null) {
      return;
    }
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close({default: false})
  }

  setDefault() {
    this.dialogRef.close({default:true})
  }
}
