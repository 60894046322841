import { IInboundVoicePricelistResponse } from "../interfaces/inbound/inbound-voice-pricelist-response.interface"
import { IInboundSmsPricelistResponse } from "../interfaces/inbound/inbound-sms-pricelist-response.interface"

export const InboundBillingType = {
  PayPerUse: "PayPerUse",
  FlatRate: "FlatRate",
  FlatRateExtraChannels:"FlatRateExtraChannels"
}

export const InboundBillingTypeList: IInboundVoicePricelistResponse[] = [
  {
    billingType: "PayPerUse",
    label: "Pay Per Minute",
    totalItems: 0,
    isDisabled:false
  },
  {
    billingType: "FlatRate",
    label: "Flat Rate",
    totalItems: 0,
    isDisabled:false
  },
  {
    billingType: "FlatRateExtraChannels",
    label: "Flat Rate Extra Channels",
    totalItems: 0,
    isDisabled:false
  }
]

export const InboundSmsBillingTypeList: IInboundSmsPricelistResponse[] = [
  {
    billingType: "PayPerUse",
    label: "Pay Per SMS",
    totalItems: 0,
    isDisabled: false
  },
  {
    billingType: "FlatRate",
    label: "Flat Rate",
    totalItems: 0,
    isDisabled: false
  }
]
