import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, take } from 'rxjs';
import { ICreationSurveyStatistics } from '../../core/interfaces/survey/creation-survey-statistics';
import { ISurvey } from '../../core/interfaces/survey/survey';
import { ISurveyCustomerQuestion } from '../../core/interfaces/survey/survey-customer-question';
import { ISurveyQuestion } from '../../core/interfaces/survey/survey-question';
import { ISurveyQuestionAnswer } from '../../core/interfaces/survey/survey-question-answer';
import { ISurveySettings } from '../../core/interfaces/survey/survey-settings';
import { LocalStorageService } from '../../core/services/common/local-storage.service';
import { ManageAccountService } from '../../core/services/common/manage-account.service';
import { SurveyService } from '../../core/services/survey/survey.service';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  providers: [LocalStorageService, ManageAccountService, SurveyService]
})
export class SurveyComponent implements OnInit {

  @Output() surveySaved = new EventEmitter<void>();
  public isSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public survey: ISurvey | null = null;
  public loading: boolean = false;
  public submitted: boolean = false;

  public canClose: boolean = false;
  public isStatistics: boolean = false;
  public customerEmail: string;
  public customerCode: string;
  public surveyName: string;

  public isFormValid: boolean = false;

  private settings: ISurveySettings = null;
  public surveyStatistics: ICreationSurveyStatistics = null;

  public showMessage: boolean = false;

  constructor(private surveyService: SurveyService,
    private localStorageService: LocalStorageService,
    private authorizeService: AuthorizeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SurveyComponent>) { }

  ngOnInit(): void {
    if (this.data) {
      if (this.data.survey) {
        this.survey = this.data.survey;
      }

      if (this.data.isStatistics) {
        this.isStatistics = this.data.isStatistics;
        this.customerEmail = this.data.customerEmail;
        this.surveyName = this.data.surveyName;
        this.customerCode = this.data.customerCode;
      }

      if (this.data.canClose) {
        this.canClose = this.data.canClose;
      }
    }

    this.validateRequiredQuestions();

    this.surveyService.getSettings().subscribe(response => {
      this.settings = response;
    });
  }

  validateRequiredQuestions(): void {
    if (this.survey == null) {
      return;
    }

    this.isFormValid = this.survey.questionGroupList.every(group => group.surveyQuestionList.every(question => !question.isRequired || this.isQuestionAnswered(question))
    );
  }

  isQuestionAnswered(question: ISurveyQuestion): boolean {
    switch (question.typology) {
      case 'Boolean':
      case 'SingleSelection':
        return !!question.answerValue;
      case 'MultiSelection':
        return question.answerValues && question.answerValues.length > 0;
      case 'TextArea':
        return question.listOptions.every(option => !!option.value);
      default:
        return false;
    }
  }

  setSurveyInterval() {
    if (this.settings == null) {
      return;
    }

    const now = new Date();
    now.setMinutes(now.getMinutes() + this.settings.intervalMinutes);

    if (this.customerCode == undefined || this.customerCode == null || this.customerCode == "") {
      this.authorizeService.getUser().pipe(take(1)).subscribe(x => {
        this.customerCode = x.CustomerCode;
        this.localStorageService.set(`lastSurveyDate_${this.customerCode}`, now.toISOString());
      })
    }
    else {
      this.localStorageService.set(`lastSurveyDate_${this.customerCode}`, now.toISOString());
    }
    
  }

  saveSurvey(): void {
    if (this.survey) {
      this.showMessage = true;

      this.loading = true;
      this.surveyStatistics = this.mapSurveyToStatistics(this.survey);

      if (this.survey.isUpdate) {
        this.surveyService.updateSurveyStatistics(this.surveyStatistics).subscribe(
          () => {
            this.submitted = true;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.error('Error update survey statistics:', error);
          }
        );
      }
      else {
        this.surveyService.saveSurveyStatistics(this.surveyStatistics).subscribe(
          () => {
            this.submitted = true;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.error('Error saving survey statistics:', error);
          }
        );
      }
    }

    this.setSurveyInterval();
  }

  skipSurvey(): void {
    if (this.survey) {
      this.loading = true;
      this.surveyStatistics = this.mapSkipSurveyToStatistics(this.survey);

      this.surveyService.skipSurveyStatisticsAsync(this.surveyStatistics).subscribe(
        () => {
          this.loading = false;
          this.submitted = true;

          this.surveySaved.emit();
          this.dialogRef.close(true);
        },
        (error) => {
          this.loading = false;
          console.error('Error skip survey statistics:', error);
        }
      );
    }

    this.setSurveyInterval();
  }

  rejectSurvey(): void {
    if (this.survey) {
      this.loading = true;
      this.surveyStatistics = this.mapSkipSurveyToStatistics(this.survey);

      this.surveyService.rejectSurveyStatistics(this.surveyStatistics).subscribe(
        () => {
          this.loading = false;
          this.submitted = true;

          this.surveySaved.emit();
          this.dialogRef.close(true);
        },
        (error) => {
          this.loading = false;
          console.error('Error skip survey statistics:', error);
        }
      );
    }

    this.setSurveyInterval();
  }

  hasTasks(surveyStatistics: ICreationSurveyStatistics): boolean {
    return surveyStatistics.surveyCustomerQuestionList.some(question => question.answers.some(answer => answer.toDoList != null && answer.toDoList.length > 0));
  }

  onCheckboxChange(event: Event, value: string, answerValues: string[]): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!answerValues.includes(value)) {
        answerValues.push(value);
      }
    } else {
      const index = answerValues.indexOf(value);
      if (index > -1) {
        answerValues.splice(index, 1);
      }
    }

    answerValues = answerValues.filter(val => val !== '');
  }

  private mapSkipSurveyToStatistics(survey: ISurvey): ICreationSurveyStatistics {
    return {
      surveyId: survey.id,
      startedOnUtc: new Date(),
      isRepetable: survey.isRepetable,
      maxNumberOfRetries: survey.maxNumberOfRetries,
      numberOfRetries: 0,
      repeatAfterInHours: survey.repeatAfterInHours,
      isOnboarding: survey.isOnboarding,
      surveyCustomerQuestionList: []
    };
  }

  private mapSurveyToStatistics(survey: ISurvey): ICreationSurveyStatistics {
    return {
      surveyId: survey.id,
      startedOnUtc: new Date(),
      isRepetable: survey.isRepetable,
      maxNumberOfRetries: survey.maxNumberOfRetries,
      numberOfRetries: 0,
      repeatAfterInHours: survey.repeatAfterInHours,
      isOnboarding: survey.isOnboarding,
      surveyCustomerQuestionList: survey.questionGroupList.flatMap(group => group.surveyQuestionList.map(question => this.mapSurveyQuestionCustomer(survey, question))
      )
    };
  }

  private mapSurveyQuestionCustomer(survey: ISurvey, question: ISurveyQuestion): ISurveyCustomerQuestion {
    return {
      repeatAfterInHours: question.repeatAfterInHours,
      maxNumberOfRetries: question.maxNumberOfRetries,
      questionId: question.id,
      groupId: question.groupId,
      answers: this.getQuestionAnswers(question),
      isRequired: question.isRequired,
    };
  }

  private getQuestionAnswers(question: ISurveyQuestion): ISurveyQuestionAnswer[] {
    let answers: ISurveyQuestionAnswer[] = [];

    switch (question.typology) {
      case 'MultiSelection':
        answers = question.answerValues?.filter(val => val !== '').map(value => {
          const option = question.listOptions.find(o => o.value === value);
          return {
            value,
            isFinal: option.isFinal,
            toDoList: option?.toDoList || null
          };
        }) || [];
        break;
      case 'TextArea':
        question.listOptions.forEach(x => {
          answers.push({
            value: x.value,
            isFinal: x.isFinal,
            toDoList: x.toDoList || null
          });
        });
        break;
      default:
        const option = question.listOptions.find(o => o.value === question.answerValue);
        if (option) {
          answers = [{
            value: question.answerValue,
            isFinal: option.isFinal,
            toDoList: option.toDoList || null
          }];
        }
        break;
    }

    // If no answers exist, add a default null object
    if (answers.length === 0) {
      answers.push({
        value: null,
        isFinal: false,
        toDoList: null
      });
    }

    return answers;
  }

  onTextareaInput(event: Event, option: ISurveyQuestionAnswer): void {
    const textareaValue = (event.target as HTMLTextAreaElement).value;
    option.value = textareaValue;
  }

  closeMessage() {
    this.surveySaved.emit();
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(null);
  }

}
