import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IRequestStatusCallbackLog } from '../../interfaces/common/request-status-callback-log.interface';
import { IRequestTrace } from '../../interfaces/logging/request-trace.interface';

@Injectable()
export class RequestTraceService {

  private _baseUrl: string = `${ApiBaseUrl}requesttrace`;

  constructor(private httpClient: HttpClient) { }

  readRequestTrace(requestId: any, channel: string, entityType: string): Observable<IRequestTrace[]> {
    return this.httpClient.get<IRequestTrace[]>(`${this._baseUrl}/${requestId}/${channel}/${entityType}`);
  }

  readStatusCallbackRequests(requestId: any, channel: string, status: string = "", customerCode: string = ''): Observable<IRequestStatusCallbackLog[]> {
    return this.httpClient.get<IRequestStatusCallbackLog[]>(`${this._baseUrl}/statuscallback/${requestId}/${channel}/${customerCode}/${status}`);
  }
}
