import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { ICreditUsageRequest } from '../../interfaces/common/credit-usage-request.interface';
import { IAccountInfo } from '../../interfaces/customer/account-info';
import { ICreditConsumption } from '../../interfaces/customer/credit-consumption';
import { ICreditUpdateInfo } from '../../interfaces/customer/credit-update-info';
import { ICustomerCreditLogHistory } from '../../interfaces/customer/customer-credit-log-history';
import { ICustomerUsage } from '../../interfaces/customer/customer-usage';
import { ICustomerCredit } from '../../interfaces/customer/customercredit.interface';
import { ICustomerTransaction } from '../../interfaces/customer/customertransaction.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { ISelectListItem } from '../../interfaces/data/select-list-item';
import { IFilterAndSortRequest } from '../../interfaces/filter/filter-and-sort-request';
import { IPaymentHistory } from '../../interfaces/payment/payment-history.interface';
import { ICustomerVoiceSuccessRate } from '../../interfaces/voice/customer-voice-success-rate.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomerCreditService {
  private _baseUrl: string = `${ApiBaseUrl}customercredit`;
  private _baseUrl2: string = `${ApiBaseUrl}getcustomercredit`;

  constructor(private httpClient: HttpClient) { }

  getCustomersCredit(request: IFilterAndSortRequest, isInitialized: boolean): Observable<DataSourceResponse<ICustomerCredit>> {
    return this.httpClient.post<DataSourceResponse<ICustomerCredit>>(`${this._baseUrl}/credits/${isInitialized}`, request);
  }

  getCustomerCredit(customerCode): Observable<ICustomerCredit> {
    return this.httpClient.get<ICustomerCredit>(`${this._baseUrl2}/${customerCode}`);
  }

  getCustomerCredits(all?:string): Observable<ICustomerCredit> {
    return this.httpClient.get<ICustomerCredit>(`${this._baseUrl}/getcredits/${all}`);
  }

  getCustomerTransaction(customerCode: string, request: ICreditUsageRequest): Observable<DataSourceResponse<ICustomerTransaction>> {
    //const params = new HttpParams().set("pageSize", pageSize.toString());
    
    return this.httpClient.post<DataSourceResponse<ICustomerTransaction>>(`${this._baseUrl}/${customerCode}`,request);
  }


  getCustomerTransactions(request:ICreditUsageRequest): Observable<DataSourceResponse<ICustomerTransaction>> {
    //const params = new HttpParams().set("page", page.toString()).set("pageSize", pageSize.toString());
    return this.httpClient.post<DataSourceResponse<ICustomerTransaction>>(`${this._baseUrl}/transaction`, request)
  }

  getAccountInfo(): Observable<IAccountInfo<ICustomerCredit>>{
    return this.httpClient.get<IAccountInfo<ICustomerCredit>>(`${this._baseUrl}/accountinformation`);
  }

  createTransaction(transaction: ICustomerTransaction): Observable<ICreditUpdateInfo<boolean>> {
    return this.httpClient.post<ICreditUpdateInfo<boolean>>(`${this._baseUrl}/create`, transaction);
  }

  updateCreditLimit(customerCredit: ICustomerCredit): Observable<ICreditUpdateInfo<boolean>> {
    return this.httpClient.post<ICreditUpdateInfo<boolean>>(`${this._baseUrl}/update`, customerCredit);
  }

  getMonthInterval(customerCode=''): Observable<ISelectListItem[]> {
    return this.httpClient.get<ISelectListItem[]>(`${this._baseUrl}/usageinterval/${customerCode}`);
  }

  getCustomerCreditConsumption(year = 0, month = 0): Observable<ICreditConsumption> {
    return this.httpClient.get<ICreditConsumption>(`${this._baseUrl}/consumption/${year}/${month}`);
  }

  getCustomerCreditHistory(usageRequest:ICreditUsageRequest):
    Observable<DataSourceResponse<ICustomerCreditLogHistory>> {
    return this.httpClient.post<DataSourceResponse<ICustomerCreditLogHistory>>(`${this._baseUrl}/history`, usageRequest);
  }

  updateCreditAlertsInfo(customerCredit: ICustomerCredit): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/updateCreditAlert`, customerCredit);
  }

  getCustomerUsage(year, month, customerCode:string): Observable<ICustomerUsage[]> {
    return this.httpClient.get<ICustomerUsage[]>(`${this._baseUrl}/creditUsage/${year}/${month}/${customerCode}`);
  }

  getLeadTransactionsByMonth(usageRequest:ICreditUsageRequest): Observable<DataSourceResponse<ICustomerTransaction>> {
    return this.httpClient.post<DataSourceResponse<ICustomerTransaction>>(`${this._baseUrl}/transactionsByMonth`, usageRequest);
  }

  updateTransaction(creditTransaction: ICustomerTransaction): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/updateTransaction`, creditTransaction);
  }

  getChildrenTransactionsByMonth(usageRequest: ICreditUsageRequest): Observable<DataSourceResponse<ICustomerTransaction>> {
    return this.httpClient.post<DataSourceResponse<ICustomerTransaction>>(`${this._baseUrl}/getAllTransactions`, usageRequest);
  }

  getCustomerVoiceSuccessRate(): Observable<ICustomerVoiceSuccessRate[]> {
    return this.httpClient.get<ICustomerVoiceSuccessRate[]>(`${this._baseUrl}/voiceSuccessRate`);
  }

  getCreditTransactionsByMonth(usageRequest: ICreditUsageRequest, isPending: boolean): Observable<DataSourceResponse<ICustomerTransaction>> {
    return this.httpClient.post<DataSourceResponse<ICustomerTransaction>>(`${this._baseUrl}/transactionMonitor/${isPending}`, usageRequest);
  }

  getCustomerPaymentHistoryByMonth(usageRequest: ICreditUsageRequest, isPending: boolean): Observable<DataSourceResponse<IPaymentHistory>> {
    return this.httpClient.post<DataSourceResponse<IPaymentHistory>>(`${this._baseUrl}/transactionMonitor/${isPending}`, usageRequest);
  }
}
