import { Component, OnInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ICustomerAutoRechargeInfo } from '../../../../core/interfaces/customer/customer-auto-recharge-info';
import { IPaymentMethod } from '../../../../core/interfaces/payment/payment-method';
import { PaymentsService } from '../../../../core/services/common/payments.service';
import { BillingInfoService } from '../../../../core/services/customers/billing-info.service';
import { CustomerCreditService } from '../../../../core/services/customers/customercredit.service';
import { MakePaymentComponent } from '../../../../shared-payment/make-payment/make-payment.component';
import { EditAutoRechargeInfoComponent } from '../edit-auto-recharge-info/edit-auto-recharge-info.component';
import { RechargeEditFormService } from '../recharge-edit-form.service';
import { RechargeEditLoaderService } from '../recharge-edit-loader.service';


@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss'],
  providers: [RechargeEditLoaderService,
  RechargeEditFormService, PaymentsService, BillingInfoService]
})
export class RechargeComponent implements OnInit {

  public currentCredit: string;
  public customerCode: string;
  public isAutoRechargeEnable: boolean = false;
  public paymentMethods: IPaymentMethod[] = [];
 
  public autoRechargeInfo: ICustomerAutoRechargeInfo = null;
  public noPaymentMethod: boolean = false;

  public primaryPaymentMethod: IPaymentMethod;
  public secondaryPaymentMethod: IPaymentMethod;

  public success: boolean = false;
  public loading: boolean = false;
  public disableBtn: boolean = false;
  public showDropDown: boolean = false;
  public showAutorechargeInfo: boolean = false;
  public hasBillingInfo: boolean = false;
  public stripeCustomerId: string = null;
  private minAmount: number = 1;

  private autoRechargeInfoSbj: BehaviorSubject<ICustomerAutoRechargeInfo> = new BehaviorSubject<ICustomerAutoRechargeInfo>(null);
  private primaryMethods: IPaymentMethod[] = [];
  public secondaryMethods: IPaymentMethod[] = [];

  constructor(private customerCreditService: CustomerCreditService,
    private dialog: MatDialog,
    private paymentService: PaymentsService,
    private formService: RechargeEditFormService,
    private formLoader: RechargeEditLoaderService,
    private snackBar: MatSnackBar,
    private billingService: BillingInfoService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title) {

    this.titleService.setTitle("Recharge | Dillo");

  }

  ngOnInit() {
    this.paymentService.getAutoRechargeInfo().subscribe(response => {
      this.customerCode = response.customerCode;
      this.currentCredit = response.currentCredits.toFixed(5).toString();

      this.autoRechargeInfoSbj.subscribe(value => {
        if (value == null) {
          return;
        }

        this.autoRechargeInfo = value;
        this.primaryPaymentMethod = value.primaryPaymentMethod;
        this.secondaryPaymentMethod = value.secondaryPaymentMethod;
      });
      this.loadAutoRechargeInfo(response);

      this.form.valueChanges.subscribe(result => {
        this.disableBtn = this.form.invalid;
        if (this.secondaryMethodId.value == this.primaryMethodId.value) {
          this.disableBtn = true;
        }
      });
    });

    this.paymentService.getPaymentSettings().subscribe(response => {
      this.formService.setMinAmount(response.minRechargeValue);

      this.minAmount = response.minRechargeValue;
    })

    this.primaryMethodId.valueChanges.subscribe(result => {
      this.secondaryMethods = this.paymentMethods.filter(item => item.id != result);
    })

    this.secondaryMethodId.valueChanges.subscribe(result => {
      this.primaryMethods=this.paymentMethods.filter(item=>item.id!=result)
    })

  }

  openPayment() {
    this.billingService.getBillingInfo().subscribe(response => {
      if (response.country != null) {
        this.hasBillingInfo = true;
        const dialogRef = this.dialog.open(MakePaymentComponent, {
          minWidth: "40rem",
          maxWidth:"40rem",
          minHeight: "7rem",
          data: response,
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response.success) {
            this.customerCreditService.getAccountInfo().subscribe(x => {
              this.currentCredit = x.entity.currentCredits.toFixed(5).toString();
            });

            if (this.autoRechargeInfo.customerStripeId == null) {
              this.paymentService.getAutoRechargeInfo().subscribe(data => {
                this.autoRechargeInfo.customerStripeId = data.customerStripeId;
              })
              
            }
          }

          if (response.cardSaved) {
            this.loadPaymentMethods()
          }
            

        });
      }
      else {
        this.openBillingMissingSnackBar(`Ops, there is missing data! Complete the billing info to continue`, "Complete Data", 7000);
      }
      })
  }

  loadAutoRechargeInfo(response) {
    this.autoRechargeInfoSbj.next(response);

    if (response == null) {
      return;
    }

    this.isAutoRechargeEnable = response.isAutoRechargeEnable;

    this.formLoader.loadAutoRechargeInfo(response)
    this.showAutorechargeInfo = response.isAutoRechargeEnable;

    if (!this.showAutorechargeInfo) {
      this.formService.clearValidators(["autoRechargeCreditLimit", "primaryMethodId", "amount"]);
    }

    this.loadPaymentMethods();
  }

  loadPaymentMethods() {
    this.paymentService.getPaymentMethods(this.autoRechargeInfo.customerStripeId).subscribe(x => {
      if (x == null) {
        this.noPaymentMethod = true;
        return;
      }
      this.paymentMethods = x;
      this.secondaryMethods = x;
      this.primaryMethods = x;
      //this.openEditComponent();
      this.showDropDown = x.length > 1;
    });
  }

  openEditComponent() {
    //if (this.paymentMethods.length == 0) {
    //  this.noPaymentMethod = true;
    //  return;
    //}
    const dialogRef = this.dialog.open(EditAutoRechargeInfoComponent, {
      minWidth: "25rem",
      maxWidth: "30rem",
      minHeight: "7rem",
      data: {
        autoRechargeInfo: this.autoRechargeInfo,
        paymentMethodsList: this.paymentMethods,
        hasMorePaymentMethods: this.paymentMethods.length > 1
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (!response.saved) {
        return;
      }
      //console.log("response=", response);
      this.autoRechargeInfo = response.autoRechargeInfo;
    })
  }

  //edit auto recharge info:
  get form() {
    return this.formService.form;
  }

  get autoRechargeCreditLimit() {
    return this.formService.getControlName("autoRechargeCreditLimit");
  }

  get enableAutoRecharge() {
    return this.formService.getControlName("enableAutoRecharge");
  }

  get primaryMethodId() {
    return this.formService.getControlName("primaryMethodId");
  }

  get secondaryMethodId() {
    return this.formService.getControlName("secondaryMethodId");
  }

  get amount() {
    return this.formService.getControlName("amount");
  }

  onChangeEnableAutoRecharge(event) {
    this.showAutorechargeInfo = event.checked;

    if (event.checked == false) {
      this.formService.clearValidators(["autoRechargeCreditLimit", "primaryMethodId", "amount"]);
    }
    else {
      this.formService.setValidatorsRequired(["autoRechargeCreditLimit", "primaryMethodId", "amount"]);
      this.formService.setMinAmount(this.minAmount);
    }
  }

  submit() {
    this.loading = true;
    const autoRecharge = this.formService.createCustomerAutoRechargeInfo(this.autoRechargeInfo);
    //console.log("autoRechargeObj=", autoRecharge);
    this.paymentService.updateAutoRechargeInfo(autoRecharge).subscribe(x => {
      this.success = x;
      this.loading = false;
      
      if (x) {
        this.openSnackBar(`Auto Recharge Information was successfully saved`, "Ok, got it!", 2000);
        this.disableBtn = true;
      }
      else {
        this.openSnackBar(`An unknown error occurred while updating.Please refresh and try again.`, 'Ok, got it!', 2000);
      }
     
    })
  }

  openSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

  openBillingMissingSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
    this.snackBar._openedSnackBarRef.onAction().subscribe(x => {
      this.navigateToBilling()
    })
  }
  navigateToBilling() {
    this.router.navigate([`./billing-info`], { relativeTo: this.route })
  }

}
