import { Component, OnInit, Inject } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { IInboundPriceDetails, IAIDetails } from '../../../core/interfaces/common/inbound-price-details';
import { IInboundEmailReceived } from '../../../core/interfaces/email/inbound-email-received';
import { IInboundSmsReceived } from '../../../core/interfaces/sms/inbound-sms-received.interface';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inbound-price-details',
  templateUrl: './inbound-price-details.component.html',
  styleUrls: ['./inbound-price-details.component.scss']
})
export class InboundPriceDetailsComponent implements OnInit {

  public serviceDataSource: ServiceDataSource | null;
  public aiDataSource: AIDataSource | null;
 
  public services: IInboundPriceDetails[] = [];
  public aiList: IAIDetails[] = [];
  public listBehaviorSbj: BehaviorSubject<IInboundPriceDetails[]> = new BehaviorSubject<IInboundPriceDetails[]>([]);
  public aiListBehaviorSbj: BehaviorSubject<IAIDetails[]> = new BehaviorSubject<IAIDetails[]>([]);

  public serviceDisplayedColumns = ['serviceName', 'length', 'pricePerInterval', 'numberOfIntervals', 'totalPrice'];
  public aiDisplayedColumns = ['ai', 'interval', 'pricePerIntervalAI', 'priceDetails'];

  public emailRequest: IInboundEmailReceived = null;
  public smsRequest: IInboundSmsReceived = null;
  public request: any = null;

  public isEmail: boolean = false;

  constructor(public dialogRef: MatDialogRef<InboundPriceDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (this.data == null) {
      return;
    }

    this.request = data.request;
    this.isEmail = data.isEmail;

    if (data.isEmail == true) {
      this.emailRequest = data.request;
    }
    else {
      this.smsRequest = data.request;
    }
  }

  ngOnInit() {
    this.serviceDataSource = new ServiceDataSource(this.listBehaviorSbj);
    this.aiDataSource = new AIDataSource(this.aiListBehaviorSbj);

    this.aiListBehaviorSbj.subscribe(value => this.aiList = value);

    this.getPriceDetails();

  }

  getPriceDetails() {
    if (this.isEmail) {
      this.services = [{
        text: "",
        interval: this.emailRequest.interval,
        length: this.emailRequest.size,
        numberOfIntervals: this.emailRequest.numberOfIntervals,
        pricePerInterval: this.emailRequest.pricePerInterval,
        totalPrice: this.emailRequest.numberOfIntervals * this.emailRequest.pricePerInterval,
        serviceName: "Inbound email"
      }]
    }
    else {
      this.services = [{
        text: "",
        interval: this.smsRequest.maxMessageLength,
        length: this.smsRequest.message.length,
        numberOfIntervals: this.smsRequest.numberOfSmsParts,
        pricePerInterval: this.smsRequest.pricePerSms,
        totalPrice: this.smsRequest.numberOfSmsParts * this.smsRequest.pricePerSms,
        serviceName: "Inbound SMS"
      }]
    }

    this.listBehaviorSbj.next(this.services);

    this.aiListBehaviorSbj.next(this.getAIPrices());
    
  }

  getAIPrices(): IAIDetails[] {
    if (this.request.botEndpointHitTextsDeserialized == null || this.request.botEndpointHitTextsDeserialized.length == 0) {
      return [];
    }
    
    var prices: IInboundPriceDetails[] = this.request.botEndpointHitTextsDeserialized.map(item => {
      var price: IInboundPriceDetails = {
        text: item,
        interval: this.request.botEndpointHitInterval,
        length: item.length,
        numberOfIntervals: Math.ceil(item.length / this.request.botEndpointHitInterval),
        pricePerInterval: this.request.pricePerBotEndpointHit,
        totalPrice: Math.ceil(item.length / this.request.botEndpointHitInterval) * this.request.pricePerBotEndpointHit
      };

      return price;
    });

    var ai: IAIDetails = {
      name: "AI",
      interval: this.request.botEndpointHitInterval,
      pricePerInterval: this.request.pricePerBotEndpointHit,
      services: prices,
      totalPrice: this.request.totalPricePerEndpointHit
    }

    return [ai];
  }

}

export class ServiceDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IInboundPriceDetails[]>) {
    super();
  }

  connect(): Observable<IInboundPriceDetails[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}

export class AIDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IAIDetails[]>) {
    super();
  }

  connect(): Observable<IAIDetails[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}
