<h3>Price details</h3>

<span *ngIf="request!=null"><b>RequestId: </b>{{request.id}}</span>

<div class="container mt-3" *ngIf='services.length > 0'>
  <table mat-table [dataSource]="serviceDataSource" class="full-width">

    <ng-container matColumnDef="serviceName">
      <th mat-header-cell *matHeaderCellDef>Service Name</th>
      <td mat-cell *matCellDef="let element"><b>{{element.serviceName}}</b></td>
    </ng-container>

    <ng-container matColumnDef="length">
      <th mat-header-cell *matHeaderCellDef>
        <span *ngIf="isEmail">Size</span>
        <span *ngIf="isEmail==false">Length</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.length}}
        <span *ngIf="isEmail">Kb</span>
        <span *ngIf="isEmail==false">chars</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="pricePerInterval">
      <th mat-header-cell *matHeaderCellDef>Price / interval</th>
      <td mat-cell *matCellDef="let element">
        {{element.pricePerInterval}}
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfIntervals">
      <th mat-header-cell *matHeaderCellDef>No. intervals</th>
      <td mat-cell *matCellDef="let element">
        {{element.numberOfIntervals}}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalPrice">
      <th mat-header-cell *matHeaderCellDef>Total price</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf='element.totalPrice!=null'>{{element.totalPrice | number : '1.3-5' }} €</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="serviceDisplayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: serviceDisplayedColumns;"></tr>

  </table>
</div>

<div class="container mt-3" *ngIf="aiList.length > 0">
  <table mat-table [dataSource]="aiDataSource" class="full-width">

    <ng-container matColumnDef="ai">
      <th mat-header-cell *matHeaderCellDef class="pr-3">Service<br /> name</th>
      <td mat-cell *matCellDef="let element"><b>{{element.name}}</b></td>
    </ng-container>

    <ng-container matColumnDef="interval">
      <th mat-header-cell *matHeaderCellDef class="pr-3">Interval</th>
      <td mat-cell *matCellDef="let element">{{element.interval}}</td>
    </ng-container>

    <ng-container matColumnDef="pricePerIntervalAI">
      <th mat-header-cell *matHeaderCellDef class="pr-3">Price / <br />Interval</th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.pricePerInterval | number : '1.3-5'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="priceDetails">
      <th mat-header-cell *matHeaderCellDef>Price Details</th>
      <td mat-cell *matCellDef="let element">

        <div class="secondary-underline">
          <div class="row secondary-underline text-header">
            <div class="col-sm-6">
              Text
            </div>

            <div class="col-sm-2">
              Length
            </div>

            <div class="col-sm-2">
              No. intervals
            </div>

            <div class="col-sm-2">
              Total price
            </div>
          </div>

          <div class="row  text-header" *ngFor="let service of element.services">
            <div class="col-sm-6">
              <small>{{service.text}}</small>
            </div>

            <div class="col-sm-2">
              {{service.length}} chars
             
            </div>

            <div class="col-sm-2">
              {{service.numberOfIntervals}}
            </div>

            <div class="col-sm-2">
              {{service.totalPrice | number : '1.3-5' }} €
            </div>
          </div>

        </div>
        <div class="w-100 text-right"><b>Total price for {{element.name}}: {{element.totalPrice | number : '1.3-5'}} € </b></div>


      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="aiDisplayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: aiDisplayedColumns;"></tr>

  </table>
</div>


<div class="w-100 text-right primary-color mt-2"><b class="mr-3">TOTAL PRICE:  {{request.price | number : '1.3-5'}} € </b></div>
