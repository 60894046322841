<table mat-table [dataSource]="dataSource" class="full-width">

  <ng-container matColumnDef="channel">
    <th mat-header-cell *matHeaderCellDef>
     Channel
    </th>
    <td mat-cell *matCellDef="let element">
     {{element.channel}}
    </td>
  </ng-container>

  <ng-container matColumnDef="requestId">
    <th mat-header-cell *matHeaderCellDef>
      Request Id
    </th>
    <td mat-cell *matCellDef="let element">{{element.requestId}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
