import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewInboundActiveNumberSettingsFormService } from './view-inbound-active-number-settings-form.service';
import { ViewInboundActiveNumberSettingsLoaderService } from './view-inbound-active-number-settings-loader.service';

@Component({
  selector: 'app-view-inbound-active-number-settings',
  templateUrl: './view-inbound-active-number-settings.component.html',
  styleUrl: './view-inbound-active-number-settings.component.scss',
  providers: [ViewInboundActiveNumberSettingsFormService, ViewInboundActiveNumberSettingsLoaderService]
})
export class ViewInboundActiveNumberSettingsComponent {

  constructor(public dialogRef: MatDialogRef<ViewInboundActiveNumberSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | null,
    private formService: ViewInboundActiveNumberSettingsFormService,
    private loaderService: ViewInboundActiveNumberSettingsLoaderService) {

    if (data) {
      const connectionSettings = JSON.parse(data);
      this.loaderService.loadData(connectionSettings);
    }
  }

  get form() {
    return this.formService.form;
  }

  get user() {
    return this.formService.getControlName("user");
  }

  get authUser() {
    return this.formService.getControlName("authUser");
  }

  get secret() {
    return this.formService.getControlName("secret");
  }

  get host() {
    return this.formService.getControlName("host");
  }

  get port() {
    return this.formService.getControlName("port");
  }

  get extension() {
    return this.formService.getControlName("extension");
  }

  get telephoneNumber() {
    return this.formService.getControlName("telephoneNumber");
  }

  get additionalInfo() {
    return this.formService.getControlName("additionalInfo");
  }

  get stasisApp() {
    return this.formService.getControlName("stasisApp");
  }

  save(): void {
    let settings = this.formService.createSerializedConnectionSettings();
    this.dialogRef.close({ connectionSettings: settings });
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
