<div class="progress-spinner-container" [hidden]="!loading">
  <div class="progress-spinner-content">
    <mat-progress-spinner mode="indeterminate" color="primary" diameter="75"></mat-progress-spinner>
  </div>
</div>

<mat-card>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="search()">
      <mat-grid-list cols="3" rowHeight="4.5rem" gutterSize="2rem" layout-fill>
        <mat-grid-tile colspan="1">
          <mat-form-field class="full-width">
            <mat-label>Month and year</mat-label>
            <input matInput [matDatepicker]="dp"
                   placeholder=""
                   formControlName="startDate"
                   [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (monthSelected)="startMonthHandler($event, dp)"
                            (yearSelected)="startYearHandler($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
            <mat-error *ngIf="startDate.invalid && startDate.touched">
              The date is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1">
          <mat-form-field class="full-width custom-mat-select">
            <mat-label>WhatsApp Number</mat-label>
            <mat-select [formControl]="number">
              <mat-option *ngFor="let item of whatsappNumbers" [value]="item.telephoneNumber">
                {{item.telephoneNumber}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="number.invalid && number.touched">
              The number is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <button mat-raised-button class="align-right-btn" color="accent" [disabled]="form.invalid" type="submit">
            <span class="lni-search"></span> &nbsp;&nbsp;Search
          </button>

        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>
  <!--<mat-card-footer>
    <mat-paginator [length]="totalItems"
                   [pageSize]="pageSize"
                   (page)="handlePage($event)">
    </mat-paginator>
  </mat-card-footer>-->
</mat-card>

<mat-card class="mt-3" *ngIf="metricsSbj.getValue().length > 0">
  <mat-card-header>
    <mat-card-title>Conversations</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="items-table-container">
      <table mat-table matSort [dataSource]="metricsDataSource" class="full-width">

        <ng-container matColumnDef="conversationCategory">
          <th mat-header-cell *matHeaderCellDef>
            Conversation Category
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="email-width">{{element.conversationCategory}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="averagePricePerConversation">
          <th mat-header-cell *matHeaderCellDef>
            Avg. cost per conversation
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="email-width">&euro; {{element.averagePricePerConversation | number : '1.3-5'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef>
            Total no. of conversation
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="email-width">{{element.total}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalPrice">
          <th mat-header-cell *matHeaderCellDef>
            Total cost
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="email-width">&euro; {{element.totalPrice | number : '1.3-5'}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="metricsDisplayColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: metricsDisplayColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mt-3" *ngIf="activitySbj.getValue().length > 0">
  <mat-card-header>
    <mat-card-title>Messages</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="items-table-container">
      <table mat-table matSort [dataSource]="dataSource" class="full-width">

        <ng-container matColumnDef="channel">
          <th mat-header-cell *matHeaderCellDef>
            Direction
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="email-width">{{element.channel}}</span>

          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>
            Total requests
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="email-width">{{element.value}}</span>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
