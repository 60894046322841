import { DataSource } from '@angular/cdk/table';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScreenSize } from '../../../../core/constants/screen-size.constants';
import { IActivityRequest } from '../../../../core/interfaces/common/activity-request.interface';
import { FilterGridData } from '../../../../core/interfaces/filter/filter-operator';
import { IPaymentHistory } from '../../../../core/interfaces/payment/payment-history.interface';
import { IPaymentInstrunctions } from '../../../../core/interfaces/payment/payment-intent-request';
import { PaymentsService } from '../../../../core/services/common/payments.service';
import { RandomMessageComponent } from '../../random-message/random-message.component';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  providers:[PaymentsService]
})
export class PaymentHistoryComponent implements OnInit {

  public height: Number = 0; //the height of the grid

  public page: number = 1;
  public pageSize: number = 20;
  public totalItems: number = 0;
  private pageSubject: BehaviorSubject<number> = new BehaviorSubject(this.page);
  public maxDate: Date = new Date();
  public minEndDate: Date = new Date();
  public showMatCard: boolean = false;
  public loading: boolean = false;
  public noItems: boolean = false;

  public startDate: UntypedFormControl = new UntypedFormControl();
  public endDate: UntypedFormControl = new UntypedFormControl();

  public displayedColumns = ["Timestamp", "Amount", "AmountWithVat", "PaymentMethod", "Status", "actions"];
  public paymentHistory: BehaviorSubject<IPaymentHistory[]> = new BehaviorSubject<IPaymentHistory[]>([]);
  public dataSource: PaymentHistoryDataSource | null;

  public innerWidth: any;
  public tabletMode: boolean = false;

  public filterArray: FilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private paymentService: PaymentsService,
    private titleService: Title, private dialog:MatDialog) {
    this.titleService.setTitle("Payment History | Dillo");
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = event.target.innerWidth;


    if (this.innerWidth < ScreenSize.BreakpointLaptop) {
      this.tabletMode = true;
      return;
    }

    this.tabletMode = false;
  }

  ngOnInit() {

    const observer = new ResizeObserver(entries => {
      //important! adjust the value to be subtracted for a good fit of the table on the page 
      this.height = entries[0].contentRect.height - 450;
    });

    observer.observe(document.querySelector('body'));

    window.innerWidth < ScreenSize.BreakpointLaptop ? this.tabletMode = true : this.tabletMode = false;

    this.endDate.setValue(new Date());
    this.startDate.setValue(new Date());

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p > 1) {
        this.loadPayments();
      }

    });
    this.dataSource = new PaymentHistoryDataSource(this.paymentHistory);
  }

  startDateHandler(event, datepicker) {
    //getMonth() is 0 indexed, in order to get the current month +1
    //var date = new Date(`${event.getFullYear()}-${event.getMonth() + 1}-1`);
    this.startDate.setValue(event);
    this.minEndDate = event;

    //close datepicker
    datepicker.close();
  }

  endDateHandler(event, datepicker) {
    this.endDate.setValue(event);

    datepicker.close();
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  loadPayments() {
    this.loading = true;
    this.showMatCard = false;
    this.noItems = false;
    const activityRequest: IActivityRequest = {
      startDate: this.startDate.value,
      endDate: this.endDate.value,
      page: this.page,
      pageSize: this.pageSize,
      guid: "",
      filters: this.filterArray,
      sortDirection: this.sortDirection,
      sortField:this.sortField
    };

    this.paymentService.getPaymentHistory(activityRequest).subscribe(response => {
      this.loading = false;

      if (response === null) {
        return;
      }

      this.paymentHistory.next(response.data);

      if (activityRequest.page == 1) {
        this.totalItems = response.total;
      }

      if (this.totalItems > 0) {
        this.showMatCard = true;
      }
      else {
        this.noItems = true;
      }
    });
  }

  addFilterComponent(event: FilterGridData) {
    //console.log('filter', event);
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      var existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);

    }
    this.paginator.firstPage();
    this.page = 1;
    this.loadPayments();
  }

  sortData(event) {

    this.sortDirection = event.direction;
    this.sortField = event.active;

    //call again the load method;
    this.paginator.firstPage();
    this.page = 1;
    this.loadPayments();
  }

  viewTransferInfo(element: IPaymentHistory) {
    if (element.bankTransferInformation == undefined || element.bankTransferInformation == null) {
      return;
    }

    let paymentInstructions = JSON.parse(element.bankTransferInformation);

    if (paymentInstructions != null) {
      let message = `<h4>Bank information</h4>
       <p>Transfer funds using the following bank information:</p>

       <div class="d-flex justify-content-between">
         <div>
           <div class="gray-color">IBAN </div>
           <div class="gray-color">BIC</div>
           <div class="gray-color">Account holder name: </div>
           <div class="gray-color">Reference: </div>
         </div>
         <div>
           <div>${paymentInstructions.Iban}</div>
           <div>${paymentInstructions.Bic}</div>
           <div>${paymentInstructions.CompanyName}</div>
           <div>${paymentInstructions.Reference}</div>
         </div>
       </div>
       <p class="mt-3">If you can, please include the reference mentioned above when you send your bank transfer.</p>`;

      const dialogRef = this.dialog.open(RandomMessageComponent, {
        minWidth: "25rem",
        minHeight: "7rem",
        panelClass: "logs-dialog-container",
        data: message
      });

    }
  }

}

export class PaymentHistoryDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IPaymentHistory[]>) {
    super();
  }

  connect(): Observable<IPaymentHistory[]> {
    return this.subject.asObservable();
  }

  disconnect() {}
}
