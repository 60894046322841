<h1 mat-dialog-title>Bot Publish</h1>
<div mat-dialog-content>
  <p><b>Version to be published:</b> {{bot.currentVersion}}</p>

  <p>Select publish environment</p>
  <mat-radio-group [formControl]="botEvironment" class="radio-btn-grp" aria-labelledby="radio-group-label">
    <mat-radio-button class="radio-btn" *ngFor="let environment of botEnviromentList" [value]="environment">{{environment.environmentName}}</mat-radio-button>
  </mat-radio-group>
  <br />

  <div class="full-width text-left mt-2">
    <mat-checkbox [formControl]="enableSentimentAnalysis" color="accent">Enable sentiment analysis</mat-checkbox>
  </div>

  <mat-form-field class="full-width mt-2">
    <mat-label>Notes</mat-label>
    <textarea [formControl]="description" matInput placeholder=""></textarea>
    <mat-error *ngIf="description.invalid && description.touched">
      The maximum number of characters is 100.
    </mat-error>
  </mat-form-field>

  <div class="mt-3 text-danger" *ngIf="error!=null">{{error}}</div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Cancel</button>

  <button mat-raised-button cdkFocusInitial (click)="publish()"  color="accent" [disabled]="botEvironment.value==null || !description.valid || disablePublishBtn==true">Publish</button>

</div>
