import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { ICustomerRequest } from '../../interfaces/common/customer-request.interface';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { IChildrenPricelistExceptionUses } from '../../interfaces/customer/children-pricelist-exception-uses.interface';
import { ICustomer } from '../../interfaces/customer/customer';
import { ICustomerSenderAndProvider } from '../../interfaces/customer/customer-sender-and-provider';
import { ICustomerSms } from '../../interfaces/customer/customer-sms';
import { ICustomerVoice } from '../../interfaces/customer/customer-voice';
import { CustomerTradePolicy } from '../../interfaces/customer/default-price-list';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';

@Injectable()
export class CustomerService {
  private _baseUrl: string = `${ApiBaseUrl}customer`;

  constructor(private httpClient: HttpClient) { }

  getCustomers(customerRequest: ICustomerRequest): Observable<DataSourceResponse<ICustomer>> {
    return this.httpClient.post<DataSourceResponse<ICustomer>>(`${this._baseUrl}/customers/`, customerRequest);
  }

  getallCustomerForAdmin(): Observable<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>(`${this._baseUrl}/allCustomersForAdmin`);
  }

  getParentsCustomers(customerType: string): Observable<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>(`${this._baseUrl}/allParents/${customerType}`);
  }

  getCustomersByType(customerType: string): Observable<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>(`${this._baseUrl}/all/${customerType}`);
  }

  createCustomer(customer: ICustomer): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/create`, customer);
  }

  createAdmin(customer: ICustomer): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/create-admin`, customer);
  }

  updateAdmin(customer: ICustomer): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/update-admin`, customer);
  }

  deleteAdmin(customer: ICustomer): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete-admin`, customer);
  }

  getCustomer(customerCode: string): Observable<ICustomer> {
    return this.httpClient.get<ICustomer>(`${this._baseUrl}/${customerCode}`);
  }

  getCustomerFromCache(): Observable<ICustomer> {
    return this.httpClient.get<ICustomer>(`${this._baseUrl}/cached`);
  }

  getCustomerByCustomerCodeFromCache(customerCode: string): Observable<ICustomer> {
    return this.httpClient.get<ICustomer>(`${this._baseUrl}/cached-with-customerCode/${customerCode}`);
  }

  updateCustomer(customer: ICustomer): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/update`, customer);
  }

  getChildren(customerCode: string): Observable<number> {
    return this.httpClient.get<number>(`${this._baseUrl}/childs/${customerCode}`);
  }

  getCustomerChildren(customerCode: string): Observable<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>(`${this._baseUrl}/children/${customerCode}`);
  }

  getUserChildren(): Observable<number> {
    return this.httpClient.get<number>(`${this._baseUrl}/children`);
  }

  getUserChildrenList(page: number, pageSize: number): Observable<DataSourceResponse<ICustomer>> {
    const params = new HttpParams().set("page", page.toString()).set("pageSize", pageSize.toString());
    return this.httpClient.get<DataSourceResponse<ICustomer>>(`${this._baseUrl}/childrenList`,
      {
        params: params
      });
  }

  deleteCustomer(customer: ICustomer): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, customer);
  }

  getCustomersForAffiliate(): Observable<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>(`${this._baseUrl}/affiliateCustomers`);
  }

  getAffiliates(): Observable<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>(`${this._baseUrl}/affiliates`);
  }

  checkIfIsAffiliate(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/isAffiliate`);
  }

  getUserLeads(customerCode: string): Observable<number> {
    return this.httpClient.get<number>(`${this._baseUrl}/leadCustomers/${customerCode}`);
  }

  getCustomerTradePolicy(customerCode: string): Observable<CustomerTradePolicy> {
    return this.httpClient.get<CustomerTradePolicy>(`${this._baseUrl}/customerTrade/${customerCode}`);
  }

  saveCustomerTradePolicy(customerTrade: CustomerTradePolicy): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/customerTrade`, customerTrade);
  }

  verifyPriceList(customerCode: string, priceListId: string, channel: string): Observable<IChildrenPricelistExceptionUses> {
    const params = new HttpParams().set("customerCode", customerCode).set("pricelistId", priceListId);

    return this.httpClient.get<IChildrenPricelistExceptionUses>(`${this._baseUrl}/verifypricelist/${channel}`,
      {
        params: params
      });
  }

  getCustomerSettings(): Observable<ICustomer> {
    return this.httpClient.get<ICustomer>(`${this._baseUrl}/apiRequestChargeType`);
  }

  //sms
  getCustomerSms(customerCode: string): Observable<ICustomerSms> {
    return this.httpClient.get<ICustomerSms>(`${this._baseUrl}/sms/${customerCode}`);
  }

  createCustomerSms(customerSms: ICustomerSms): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/sms/create`, customerSms);
  }

  updateCustomerSms(customerSms: ICustomerSms): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/sms/update`, customerSms);
  }


  //voice
  getCustomerVoice(customerCode: string): Observable<ICustomerVoice> {
    return this.httpClient.get<ICustomerVoice>(`${this._baseUrl}/voice/${customerCode}`);
  }

  createCustomerVoice(customerVoice: ICustomerVoice): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/voice/create`, customerVoice);
  }

  updateCustomerVoice(customerVoice: ICustomerVoice): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/voice/update`, customerVoice);
  }

  //senders and providers

  getSendersAndProviders(customerCode: string): Observable<ICustomerSenderAndProvider> {
    return this.httpClient.get<ICustomerSenderAndProvider>(`${this._baseUrl}/sendersproviders/${customerCode}`)
  }

  createSenderAndProvidersSettings(senderAndProvidersSettings: ICustomerSenderAndProvider): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/sendersproviders/create`, senderAndProvidersSettings)
  }

  updateSenderAndProvidersSettings(senderAndProvidersSettings: ICustomerSenderAndProvider): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/sendersproviders/update`, senderAndProvidersSettings)
  }
}
