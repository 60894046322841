import { Injectable } from '@angular/core';
import { IPaymentMethod } from '../../../core/interfaces/payment/payment-method';
import { EditPaymentMethodFormService } from './edit-payment-method-form.service';

@Injectable()
export class EditPaymentMethodLoaderService {

  constructor(private formService: EditPaymentMethodFormService) { }

  loadPaymentMethod(paymentMethod: IPaymentMethod) {
    this.formService.form.patchValue({
      cardNumber: "**** **** **** " + paymentMethod.last4 + " "+ paymentMethod.brand,
      expMonth:  paymentMethod.expirationMonth,
      expYear: paymentMethod.expirationYear,
      isDefault: paymentMethod.isDefault,
      cardholderName: paymentMethod.cardholderName,
      country: paymentMethod.country,
      address: paymentMethod.address,
      city: paymentMethod.city,
      state: paymentMethod.state,
      postalCode: paymentMethod.postalCode
    });
  }
}
