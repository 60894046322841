<!--<label id="radio-group-label">Choose payment method type</label>
<mat-radio-group aria-labelledby="radio-group-label"
                 class="radio-group"
                 [(ngModel)]="paymentMethodSelected">
  <mat-radio-button value="1">Card</mat-radio-button>
  <mat-radio-button value="2">Other</mat-radio-button>
</mat-radio-group>-->
<h1 mat-dialog-title>
  <span *ngIf="isNew">Add a new payment method</span>
  <span *ngIf="isNew==false">Update payment method</span>
</h1>

<div>
  <form id="setup-form" [formGroup]="form" [hidden]="success" (ngSubmit)="submit()">
    <mat-form-field class="search-field full-width">
      <input type="text" formControlName="cardholderName" matInput placeholder="Cardholder name" autocomplete="off" />
      <mat-error *ngIf="cardholderName.invalid && cardholderName.touched">
        Cardholder name is required
      </mat-error>
    </mat-form-field>

    <!--<mat-form-field class="full-width">
  <input type="text" placeholder="Country" matInput formControlName="country" [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let country of filteredOptions | async" [value]="country.name">-->
    <!-- for localhost and publish without subdomain-->
    <!--<img src="../assets/img/countries/{{country.languageCode}}.png" class="img-flag" alt="img" /> &nbsp;{{country.name}}-->
    <!-- for publish on an subdomain-->
    <!--<img src="/{{root}}/assets/img/countries/{{country.languageCode}}.png" class="img-flag" alt="img" /> &nbsp;{{country.name}}-->
    <!--</mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="country.invalid && country.touched && country.hasError('forbiddenNames')">
      The country is invalid.
    </mat-error>
  </mat-form-field>-->
    <!--<div class="form-row" [hidden]="isNew==false">
    <label for="card-element">Enter card info:</label><br />
    <div id="card-element"></div>
    <div id="card-errors" role="alert"></div>
  </div>-->
    <!--stripe package-->
    <div [hidden]="isNew==false">
      <label for="card-element">Enter card info:</label><br />
      <!--<ngx-stripe-card [options]="cardOptions"
                     [elementsOptions]="elementsOptions">

    </ngx-stripe-card>-->

      <ngx-stripe-card [options]="cardOptions">

      </ngx-stripe-card>

    </div>

    <mat-grid-list cols="6" rowHeight="4.5rem" gutterSize="1rem" layout-fill>
      <div *ngIf="!isNew">
        <mat-grid-tile colspan="6">
          <mat-form-field class="search-field full-width">
            <input type="text" matInput placeholder="Card number"
                   autocomplete="off"
                   [readonly]="true"
                   formControlName="cardNumber" />

          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
          <mat-form-field class="search-field full-width">
            <input type="text" matInput
                   placeholder="Expiration month"
                   autocomplete="off"
                   formControlName="expMonth" />
            <mat-error *ngIf="expMonth.invalid && expMonth.touched">
              The expiration mounth is required
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>


        <mat-grid-tile colspan="2">
          <mat-form-field class="search-field full-width">
            <input type="text" matInput
                   placeholder="Expiration year"
                   autocomplete="off"
                   formControlName="expYear" />
            <mat-error *ngIf="expYear.invalid && expYear.touched">
              The expiration year is required
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

      </div>
      <mat-grid-tile colspan="2">
        <div class="full-width text-left">
          <mat-checkbox formControlName="isDefault" color="accent" (change)="onClickIsDefault($event)">Is Default</mat-checkbox>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <div class="full-width text-left" *ngIf="showMessage">
      <mat-error> First you have to set another default payment method!</mat-error>
    </div>

    <div class="full-width text-left" *ngIf="noDefault">
      <mat-error> At least one payment method must be default!</mat-error>
    </div>

    <mat-grid-list cols="6" rowHeight="4.5rem" gutterSize="1rem" layout-fill>
      <mat-grid-tile colspan="6">
        <mat-form-field class="search-field full-width">
          <input type="text" matInput formControlName="address"
                 placeholder="Card Holder Address"
                 autocomplete="off" />
          <mat-error *ngIf="address.invalid && address.touched">
            The address is required
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-form-field class="full-width">
          <input type="text" placeholder="Country" matInput formControlName="country" [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let country of filteredOptions | async" [value]="country.name">
              <!-- for localhost and publish without subdomain-->
              <img src="../assets/img/countries/{{country.languageCode}}.png" class="img-flag" alt="img" /> &nbsp;{{country.name}}

              <!-- for publish on an subdomain-->
              <!--<img src="/{{root}}/assets/img/countries/{{country.languageCode}}.png" class="img-flag" alt="img" /> &nbsp;{{country.name}}-->
            </mat-option>

          </mat-autocomplete>
          <mat-error *ngIf="(country.invalid  || country.hasError('forbiddenNames')) && country.touched">
            The country is invalid.
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="Postal Code" formControlName="postalCode" />
          <mat-error *ngIf="postalCode.invalid && postalCode.touched">
            The postal code is required.
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-form-field class="search-field full-width">
          <input type="text" matInput formControlName="state"
                 placeholder="State / Region"
                 autocomplete="off" />
          <mat-error *ngIf="state.invalid && state.touched">
            The state is required
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-form-field class="search-field full-width">
          <input type="text" matInput formControlName="city"
                 placeholder="City"
                 autocomplete="off" />
          <mat-error *ngIf="city.invalid && city.touched">
            The city is required
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>


    <p class="mb-4 mt-2 small"> *** Dillo doesn't store your card informations, instead we rely on Stripe to process your payment securely.</p>

    <div class="mg-top1rem">
      <button mat-raised-button
              (click)="cancel()"
              [disabled]="loading" type="button">
        Cancel
      </button>
      &nbsp;&nbsp;
      <button mat-raised-button
              cdkFocusInitial
              color="accent"
              [disabled]="loading || form.invalid">
        Save
      </button>
    </div>
  </form>
</div>

<div class="mt-4" [hidden]="!loading">
  <div class="progress-spinner-container">
    <div class="progress-spinner-content">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="75"></mat-progress-spinner>
    </div>
  </div>
</div>

<div class="mg-top1rem" *ngIf="confirmationMsg!=null">
  <span *ngIf="success" class="confirmation-msg"> {{confirmationMsg}} </span>
  <span *ngIf="!success" mat-error> {{confirmationMsg}} </span>
  <!--<div class="mg-top1rem">
    <button mat-raised-button
            (click)="cancel()"
            [disabled]="loading" type="button">
      Close
    </button>
  </div>-->
</div>

