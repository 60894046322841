<h4 mat-dialog-title>
  Dial numbers
</h4>
<div class="items-table-container">
  <table mat-table [dataSource]="dataSource" class="full-width">

    <ng-container matColumnDef="telephoneNumber">
      <th mat-header-cell *matHeaderCellDef>Telephone Number</th>
      <td mat-cell *matCellDef="let element">{{element.telephoneNumber}}</td>
    </ng-container>


    <ng-container matColumnDef="sender">
      <th mat-header-cell *matHeaderCellDef>Sender</th>
      <td mat-cell *matCellDef="let element">{{element.sender}}</td>
    </ng-container>


    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>Call Duration</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.callDuration !=null">{{element.callDuration | number : '1.3-3'}} sec </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let element">
        {{element.price | number : '1.3-5'}}

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>
