import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBotEnvironment } from '../../../../core/interfaces/ai/bot-environment.interface';
import { IBot } from '../../../../core/interfaces/ai/bot.interface';
import { AiManagementService } from '../../../../core/services/ai/ai-management.service';

@Component({
  selector: 'app-publish-bot-dialog',
  templateUrl: './publish-bot-dialog.component.html',
  styleUrls: ['./publish-bot-dialog.component.scss'],
  providers: [AiManagementService]
})
export class PublishBotDialogComponent implements OnInit {

  public botEvironment: UntypedFormControl = new UntypedFormControl();
  public enableSentimentAnalysis: UntypedFormControl = new UntypedFormControl(false);
  public description: UntypedFormControl = new UntypedFormControl(null, Validators.maxLength(100));
  public botEnviromentList: IBotEnvironment[] = [];
  public loading: boolean = false;
  public bot: IBot = null;
  public error: string = null;
  public disablePublishBtn: boolean = false;

  constructor(private dialogRef: MatDialogRef<PublishBotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IBot | null,
    private botService: AiManagementService)
  {
    if (data == null) {
      return;
    }
    this.bot = data;
    this.loadEnvironments();
  }

  ngOnInit() {
    
  }

  loadEnvironments() {
    this.loading = true;
    this.botService.getBotEnvironments(this.bot.id).subscribe(x => {
      this.loading = false;
      this.botEnviromentList = x;
    });
  }

  publish() {
    if (this.botEvironment.value == null) {
      return;
    }
    this.bot.botEnvironmentName = this.botEvironment.value.environmentName;
    this.bot.enableSentimentAnalysis = this.enableSentimentAnalysis.value;
    this.bot.botEnvironmentId = this.botEvironment.value.id
    this.bot.publishDescription = this.description.value;
    this.disablePublishBtn = true;
    this.botService.publishBot(this.bot).subscribe(x => {
      if (x == false) {
        this.disablePublishBtn = false;
        this.error = 'An error occured during publish. Please try again.'
        return;
      }

      this.dialogRef.close({ saved: true, bot: this.bot })
    })
  }

  cancel() {
    this.dialogRef.close({ saved: false })
  }

}
