<!--<h4 class="mb-5 text-center">
  History
</h4>-->
<div class="items-table-container">
  <table mat-table [dataSource]="dataSource" class="full-width" *ngIf="dataAvailable">
    <ng-container matColumnDef="createdOnUtc">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        {{timeZoneId=='UTC'? (element.createdOnUtc | date:'medium':"+0000"):(element.createdOnUtc | date:'medium') }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{element.status}}</td>
    </ng-container>

    <ng-container matColumnDef="errorCode">
      <th mat-header-cell *matHeaderCellDef>Error code</th>
      <td mat-cell *matCellDef="let element">{{element.errorCode}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="dataAvailable==false">
    <p class="no-info text-center">No information available!</p>
  </div>
  </div>

