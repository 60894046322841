import { DataSource } from '@angular/cdk/table';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { IVoiceDialAction } from '../../../../../core/interfaces/voice/voice-dial-action';
import { VoiceActivityLogsComponent } from '../voice-activity-logs/voice-activity-logs.component';

@Component({
  selector: 'app-dial-action-info',
  templateUrl: './dial-action-info.component.html',
  styleUrls: ['./dial-action-info.component.scss']
})
export class DialActionInfoComponent implements OnInit {

  public dataSource: DialNumberDataSource | null;
  public displayedColumns = ['telephoneNumber', 'sender', 'duration', 'price'];

  public dialNumbers : BehaviorSubject<IVoiceDialAction[]> = new BehaviorSubject<IVoiceDialAction[]>([]);

  constructor(public dialogRef: MatDialogRef<VoiceActivityLogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IVoiceDialAction[] | null, private titleService: Title) {

    this.titleService.setTitle("Dillo | ActivityLogs");
    if (this.data == null || this.data.length == 0) {
      return;
    }
    this.dialNumbers.next(data);
    this.dataSource = new DialNumberDataSource(this.dialNumbers);
  }

  ngOnInit() {

  }


}

export class DialNumberDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IVoiceDialAction[]>) {
    super();
  }

  connect(): Observable<IVoiceDialAction[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}
