import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-samples',
  templateUrl: './code-samples.component.html',
  styleUrls: ['./code-samples.component.scss']
})
export class CodeSamplesComponent implements OnInit {

  @Input() code: string;
  @Input() language: string;
  @Input() description: string;

  public editorOptions:any;

  public loading: boolean = true;
  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {

    this.editorOptions= { theme: 'vs-blue', language: this.language, readOnly: true, automaticLayout: true }
  }

  onEditorInit() {

    this.loading = false;
    this.cdRef.detectChanges();

  }

  saveToClipboard(val = this.code) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
