<div class="float-right" (click)="close()"><i class='lni lni-close'></i></div>
<div class="text-center credit-pop-up">
  <h2><i class='lni lni-emoji-sad'></i></h2>
  <h4> ALERT</h4>
  <h4> YOU ARE OUT OF CREDIT</h4>

  <p class="mg-top2rem">You have used up your credit:</p>
  <p>
    <span *ngIf="isReseller">Top up immediately or activate the self-recharge function, otherwise go to account settings to set an alert threshold!</span>
    <span *ngIf="!isReseller">Go to account settings to set an alert threshold! </span>

  </p>

  <div *ngIf="isReseller" class="mg-top1rem">
    <button mat-raised-button
            cdkFocusInitial
            color="accent"
            (click)="goToRecharge()">
      RECHARGE
    </button>
  </div>

  <!--<div *ngIf="!isReseller" class="mg-top1rem">
    <button mat-raised-button
            cdkFocusInitial
            color="accent"
            (click)="close()">
      OK, I UNDERSTAND
    </button>
  </div>-->

  <div class="mg-top1rem">
    <button mat-raised-button
            cdkFocusInitial
            color="accent"
            (click)="goToAllertSettings()">
      SET THRESHOLD
    </button>
  </div>
</div>

