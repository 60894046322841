import { DataSource } from '@angular/cdk/table';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChannelBehaviorActionType } from '../../../../../core/constants/channel-behavior-action-type';
import { ICallAI, ICallRecording, ICallService } from '../../../../../core/interfaces/voice/voice-call-price-details';
import { IVoiceRequestHistory } from '../../../../../core/interfaces/voice/voice-request-history';

@Component({
  selector: 'app-call-price-details',
  templateUrl: './call-price-details.component.html',
  styleUrls: ['./call-price-details.component.scss']
})
export class CallPriceDetailsComponent implements OnInit {

  public callServiceDataSource: CallServiceDataSource | null;
  public callAIDataSource: CallAIDataSource | null;
  public callTTSDataSource: CallTTSDataSource | null;
  public callRecordingsDataSource: CallRecordingsDataSource | null;
  public callAIRephrasingDataSource: CallTTSDataSource | null;
  public callAIAskAssistantDataSource: CallAIDataSource | null;

  public request: IVoiceRequestHistory = null;
  public callServices: ICallService[] = [];
  public callRecordings: ICallRecording[] = [];
  public callTTSList: ICallService[] = [];
  public callAIList: ICallAI[] = [];

  public callServicesBehaviorSbj: BehaviorSubject<ICallService[]> = new BehaviorSubject<ICallService[]>([]);
  public callRecordingsBehaviorSbj: BehaviorSubject<ICallRecording[]> = new BehaviorSubject<ICallRecording[]>([]);
  public callTTSListBehaviorSbj: BehaviorSubject<ICallService[]> = new BehaviorSubject<ICallService[]>([]);
  public callAIListBehaviorSbj: BehaviorSubject<ICallAI[]> = new BehaviorSubject<ICallAI[]>([]);
  public callAIFRephraseListBehaviorSbj: BehaviorSubject<ICallService[]> = new BehaviorSubject<ICallService[]>([]);
  public callAIAskAssistantBehaviorSbj: BehaviorSubject<ICallAI[]> = new BehaviorSubject<ICallAI[]>([]);

  public callServiceDisplayedColumns = ['serviceName', 'duration', 'pricePerInterval', 'numberOfIntervals', 'totalPrice'];
  public callRecordingsDisplayedColumns = ['recordings', 'nodeKey', 'recDuration', 'priceDetails'];
  public callTTSDisplayedColumns = ['tts', 'pricePerCharacters', 'numberOfCharacters', 'totalPrice'];
  public callAIDisplayedColumns = ['ai', 'interval', 'pricePerIntervalAI', 'priceDetails'];
  public callAssistantDisplayedColumns = ['model', 'priceDetails'];

  constructor(public dialogRef: MatDialogRef<CallPriceDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IVoiceRequestHistory | null) {

    if (this.data == null) {
      return;
    }
    this.request = data;
  }

  ngOnInit() {
    this.callServiceDataSource = new CallServiceDataSource(this.callServicesBehaviorSbj);
    this.callRecordingsDataSource = new CallRecordingsDataSource(this.callRecordingsBehaviorSbj);
    this.callTTSDataSource = new CallTTSDataSource(this.callTTSListBehaviorSbj);
    this.callAIDataSource = new CallAIDataSource(this.callAIListBehaviorSbj);
    this.callAIRephrasingDataSource = new CallTTSDataSource(this.callAIFRephraseListBehaviorSbj);
    this.callAIAskAssistantDataSource = new CallAIDataSource(this.callAIAskAssistantBehaviorSbj);

    this.callAIListBehaviorSbj.subscribe(value => this.callAIList = value)

    this.getCallPriceDetails();
  }

  getCallPriceDetails() {
    var dialActions: ICallService[] = [];
    var isInbound = this.request.inboundActiveNumber != null;

    var channelBehavior = JSON.parse(this.request.channelBehavior);

    Object.entries(channelBehavior.ChannelBehaviorNodes).forEach(function ([key, value]) {

      var channelBehaviorNode: any = value;
      if (channelBehaviorNode.ChannelBehaviorAction.CallDuration == undefined) {
        return;
      }
      if (channelBehaviorNode.ActionType == ChannelBehaviorActionType.Dial) {
        dialActions.push({
          serviceName: `Dial (${channelBehaviorNode.ChannelBehaviorAction.TelephoneNumber})`,
          duration: channelBehaviorNode.ChannelBehaviorAction.CallDuration,
          interval: channelBehaviorNode.ChannelBehaviorAction.Interval,
          numberOfIntervals: Math.ceil(channelBehaviorNode.ChannelBehaviorAction.CallDuration / channelBehaviorNode.ChannelBehaviorAction.Interval),
          pricePerInterval: channelBehaviorNode.ChannelBehaviorAction.PricePerInterval,
          totalPrice: channelBehaviorNode.ChannelBehaviorAction.Price
        });

      }

      if (channelBehaviorNode.ActionType == ChannelBehaviorActionType.Conference) {

        dialActions.push({
          serviceName: `Conference (${channelBehaviorNode.ChannelBehaviorAction.RoomName})`,
          duration: channelBehaviorNode.ChannelBehaviorAction.CallDuration,
          interval: channelBehaviorNode.ChannelBehaviorAction.Interval,
          numberOfIntervals: Math.ceil(channelBehaviorNode.ChannelBehaviorAction.CallDuration / channelBehaviorNode.ChannelBehaviorAction.Interval),
          pricePerInterval: channelBehaviorNode.ChannelBehaviorAction.PricePerInterval,
          totalPrice: channelBehaviorNode.ChannelBehaviorAction.Price
        });
      }
    });



    if (this.request.numberOfIntervals == 0 || this.request.numberOfIntervals == undefined) {
      this.callServices = dialActions;
    }
    else {
      this.callServices = [{
        serviceName: isInbound ? 'Inbound call' : 'Outbound call',
        duration: this.request.callDuration,
        numberOfIntervals: this.request.numberOfIntervals,
        interval: this.request.interval,
        pricePerInterval: this.request.pricePerInterval,
        totalPrice: this.request.pricePerInterval * this.request.numberOfIntervals
      }, ...dialActions];
    }

    if (this.request.apiRequestPrice != null) {
      this.callServices.push({
        serviceName: 'API Request',
        totalPrice: this.request.apiRequestPrice != null ? this.request.apiRequestPrice : 0
      })
    }

    if (this.request.computingPrice != null) {
      this.callServices.push({
        serviceName: 'Computing',
        pricePerInterval: this.request.computingPricePerInterval,
        numberOfIntervals: this.request.numberOfExecutedNodes,
        totalPrice: this.request.computingPrice
      })
    }

    this.getRecordingsInfo();
    this.getTTSInfo();


    this.callServicesBehaviorSbj.next(this.callServices);
    this.callRecordingsBehaviorSbj.next(this.callRecordings);
    this.callTTSListBehaviorSbj.next(this.callTTSList);

    this.callAIListBehaviorSbj.next(this.getAIPrices());
    this.callAIFRephraseListBehaviorSbj.next(this.getRephrasingPrices());
    this.callAIAskAssistantBehaviorSbj.next(this.getAskAssistantPrices());
    //this.callAIAskAssistantOutputBehaviorSbj.next(this.getAskAssistantOutputPrices());
  }

  getRecordingsInfo() {
    this.request.recordings.forEach((recording, index) => {
      var nodeKey = recording.fileName.split("_")[2] != undefined ? recording.fileName.split("_")[2] : '-';
      var isAsr = recording.fileName.startsWith('menu_') ? true : false;
      var recordingsService: ICallService[] = [];
      var storageName = this.getStorageName(recording.recordingStoragePeriodCode);
      if (recording.url != null) {

        recordingsService.push({
          serviceName: `Storage for ${storageName}`,
          duration: 0,
          interval: recording.interval,
          numberOfIntervals: recording.numberOfIntervals,
          pricePerInterval: recording.price / recording.numberOfIntervals,
          totalPrice: recording.price
        });
      }
      if (recording.hasTranscription) {
        recordingsService.push({
          serviceName: isAsr ? 'Automatic Speech Recognition' : 'Transcription',
          duration: 0,
          interval: recording.transcriptionInterval,
          numberOfIntervals: recording.transcriptionNumberOfIntervals,
          pricePerInterval: recording.transcriptionPrice / recording.transcriptionNumberOfIntervals,
          totalPrice: recording.transcriptionPrice
        });
      }

      this.callRecordings.push({
        name: `Recording ${index + 1}`,
        duration: recording.duration,
        nodeKey: nodeKey,
        services: recordingsService,
        totalPrice: (recording.price != null ? recording.price : 0) + (recording.transcriptionPrice != null ? recording.transcriptionPrice : 0)
      });

    });

  }

  getTTSInfo() {
    if (this.request.textToSpeechPremiumTotalPrice != null) {
      this.callTTSList.push({
        serviceName: 'Premium',
        interval: this.request.textToSpeechPremiumInterval,
        numberOfIntervals: this.request.numberOfCharactersPremium,
        pricePerInterval: this.request.textToSpeechPremiumPricePerInterval,
        totalPrice: this.request.textToSpeechPremiumTotalPrice
      })
    }

    if (this.request.textToSpeechStandardTotalPrice != null) {
      this.callTTSList.push({
        serviceName: 'Standard',
        interval: this.request.textToSpeechStandardInterval,
        numberOfIntervals: this.request.numberOfCharactersStandard,
        pricePerInterval: this.request.textToSpeechStandardPricePerInterval,
        totalPrice: this.request.textToSpeechStandardTotalPrice
      })
    }


  }

  getAIPrices() {
    let response: ICallAI[] = [];
  
    if (this.request.rephrasings != null && this.request.rephrasingPrice > 0) {
      let prices: ICallService[] = this.request.rephrasings.map(item => {
        var name = item.rephrasedText;
        if (this.request.isAnonymized == true) {
          name = "[anonymized]";
        }
        var price: ICallService = {
          serviceName: name,
          interval: this.request.rephrasingTokensInterval,
          duration: item.tokens,
          pricePerInterval: this.request.rephrasingPricePerInterval,
          numberOfIntervals: item.tokens / this.request.rephrasingTokensInterval,
          totalPrice: this.request.rephrasingPricePerInterval * (item.tokens / this.request.rephrasingTokensInterval)
        };

        return price;
      });

      let ai: ICallAI = {
        name: "Rephrasing",
        interval: this.request.rephrasingTokensInterval,
        pricePerInterval: this.request.rephrasingPricePerInterval,
        services: prices,
        totalPrice: this.request.rephrasingPrice
      }

      response.push(ai);
    }

    if (this.request.botEndpointHitTextsDeserialized != null && this.request.botEndpointHitTextsDeserialized.length > 0) {
      let prices: ICallService[] = this.request.botEndpointHitTextsDeserialized.map(item => {
        let length = item.length;
        let name = item;
        if (this.request.isAnonymized == true) {
          //The anonymized text looks like this "b57c95a13338ff0610064060209b134e - Text length: 19"
          length = + item.substring(item.indexOf("length") + 8);
          name = "[anonymized]";
        }
        var price: ICallService = {
          serviceName: name,
          interval: this.request.botEndpointHitInterval,
          duration: length,
          numberOfIntervals: Math.ceil(length / this.request.botEndpointHitInterval),
          pricePerInterval: this.request.pricePerBotEndpointHit,
          totalPrice: Math.ceil(length / this.request.botEndpointHitInterval) * this.request.pricePerBotEndpointHit
        };

        return price;
      });

      let ai: ICallAI = {
        name: "Intent Detection",
        interval: this.request.botEndpointHitInterval,
        pricePerInterval: this.request.pricePerBotEndpointHit,
        services: prices,
        totalPrice: this.request.totalPricePerEndpointHit
      }

      response.push(ai);
    }

    return response;
  }

  getRephrasingPrices() {
    if (this.request.rephrasingPrice == null) {
      return [];
    }

    let price: ICallService = {
      serviceName: "AI Rephrasing",
      interval: this.request.rephrasingTotalTokens,
      pricePerInterval: this.request.rephrasingPricePerInterval,
      totalPrice: this.request.rephrasingPrice,
      numberOfIntervals: this.request.rephrasingNumberOfIntervals
    }

    return [price];
  }

  getAskAssistantPrices() {
    if (this.request.askAiAssistantPricings == null) {
      return [];
    }

    let modelPrice: ICallAI[] =[];

    if (this.request.askAiAssistantPricings != null && this.request.askAiAssistantPricings.length > 0) {
      
      this.request.askAiAssistantPricings.forEach(item => {
        var name = item.model;
        let prices: ICallService[] = [];
        var price: ICallService = {
          serviceName: "Input",
          //interval: item.askAiAssistantInputNumberOfIntervals,
          duration: item.askAiAssistantTotalInputTokens,
          pricePerInterval: item.askAiAssistantInputPricePerInterval,
          numberOfIntervals: item.askAiAssistantInputNumberOfIntervals,
          totalPrice: item.askAiAssistantInputPrice
        };

        let priceOutput: ICallService = {
          serviceName: "Output",
          //interval: item.askAiAssistantOutputNumberOfIntervals,
          duration: item.askAiAssistantTotalOutputTokens,
          pricePerInterval: item.askAiAssistantOutputPricePerInterval,
          numberOfIntervals: item.askAiAssistantOutputNumberOfIntervals,
          totalPrice: item.askAiAssistantOutputPrice
        };

        prices.push(price);
        prices.push(priceOutput);

        let type = "Creative";
        if (item.type != 'OpenAI') {
          type = "Seeker";
        }

        let ai: ICallAI = {
          name: `${type} ${item.model}`,
          interval: 1000000,
          pricePerInterval: 0,
          services: prices,
          totalPrice: priceOutput.totalPrice + price.totalPrice
        };

        modelPrice.push(ai);
      });
    }

    return modelPrice;
  }


  getStorageName(storagePeriodCode: string) {
    switch (storagePeriodCode) {

      case '3-M': return '3 months';
      case '6-M': return '6 months'
      case '1-Y': return '1 year'
      case '2-Y': return '2 years'
      case '3-Y': return '3 years'
    }
  }

}

export class CallServiceDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<ICallService[]>) {
    super();
  }

  connect(): Observable<ICallService[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}

export class CallRecordingsDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<ICallRecording[]>) {
    super();
  }

  connect(): Observable<ICallRecording[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}

export class CallTTSDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<ICallService[]>) {
    super();
  }

  connect(): Observable<ICallService[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}

export class CallAIDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<ICallAI[]>) {
    super();
  }

  connect(): Observable<ICallAI[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}




