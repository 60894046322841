import { DataSource } from '@angular/cdk/table';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { IVoiceRequestHistoryLogs } from '../../../../../core/interfaces/voice/voice-request-history-logs';

@Component({
  selector: 'app-voice-activity-logs',
  templateUrl: './voice-activity-logs.component.html',
  styleUrls: ['./voice-activity-logs.component.scss']
})
export class VoiceActivityLogsComponent implements OnInit {

  public dataSource: VoiceActivityLogsDataSource | null;

  public historyLogs: BehaviorSubject<IVoiceRequestHistoryLogs[]> = new BehaviorSubject<IVoiceRequestHistoryLogs[]>([]);

  public displayedColumns = ['createdOnUtc', 'status', 'errorCode'];

  public dataAvailable: boolean = true;
  public timeZoneId = "";

  constructor(public dialogRef: MatDialogRef<VoiceActivityLogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IVoiceRequestHistoryLogs[] | null, private titleService: Title) {

    this.titleService.setTitle("Dillo | ActivityLogs");
    if (this.data == null || this.data.length == 0) {
      this.dataAvailable = false;
      return;
    }

    this.historyLogs.next(data);
  }

  ngOnInit() {
    this.dataSource = new VoiceActivityLogsDataSource(this.historyLogs);

    this.timeZoneId = localStorage.getItem("timeZoneId");
  }

}

export class VoiceActivityLogsDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IVoiceRequestHistoryLogs[]>) {
    super();
  }

  connect(): Observable<IVoiceRequestHistoryLogs[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}
