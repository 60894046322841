import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DetailedActivityComponent } from './detailed-activity/detailed-activity.component';
import { IActivityRequest } from '../../../core/interfaces/common/activity-request.interface';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-whatsapp-activity',
  templateUrl: './whatsapp-activity.component.html',
  styleUrls: ['./whatsapp-activity.component.scss']
})
export class WhatsappActivityComponent implements OnInit {

  public data: any = {};
  public isDeviceVisible: boolean = false;

  @Input() isAdmin: boolean = false;
  @Input() customerCode: string = null;
  @Input() tabIndex: any = { value: 0 };

  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild(DetailedActivityComponent) detailedComponent: DetailedActivityComponent;

  ngOnInit(): void {
  }

  openSideDialog(element: any) {
    this.data = element;
    this.isDeviceVisible = !this.isDeviceVisible;
    this.drawer.toggle();
  }

  closeSideDialog() {
    this.drawer.toggle();
    this.data = {};
    this.isDeviceVisible = !this.isDeviceVisible;
  }

  loadInformation(request: IActivityRequest, isInbound: boolean) {
    this.detailedComponent.loadInformation(request, isInbound);
  }

  onTabChange(event: MatTabChangeEvent) {
    this.tabIndex.value = event.index;
  }

}


