import { Component, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserRoles } from '../../../../../../api-authorization/api-authorization.constants';
import { AuthorizeService } from '../../../../../../api-authorization/authorize.service';
import { CreditLimitReachedComponent } from '../credit-limit-reached/credit-limit-reached.component';

@Component({
  selector: 'app-no-credits-available',
  templateUrl: './no-credits-available.component.html',
  styleUrls: ['./no-credits-available.component.scss']
})
export class NoCreditsAvailableComponent implements OnInit {
  public isReseller: boolean = false;
  public userRole: string = "";

  constructor(private authorizeService: AuthorizeService,
    private dialogRef: MatDialogRef<CreditLimitReachedComponent>,
    private router: Router) { }

  ngOnInit() {
    this.authorizeService.getUserRole().subscribe(x => {
      //console.log("userRole=", x);
      if (x == null || x == "") {
        return;
      }

      if (x == UserRoles.Reseller) {
        this.isReseller = true;
      }

      this.userRole = x.toLowerCase();
    });

    localStorage.setItem('newLogin', "false");
  }

  goToAllertSettings() {
    this.close();
    this.router.navigate([`management/${this.userRole}/console/credit-alerts`]);
  }

  goToRecharge() {
    this.close();
    this.router.navigate([`management/${this.userRole}/billing`]);
  }

  close() {
    this.dialogRef.close();
  }

}
