<mat-card>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-3">
        <h4 class="mt-2">Microservices Monitor</h4>
      </div>
      <div class="col-sm-3">
        <mat-form-field class="full-width">
          <mat-label> Channel</mat-label>
          <mat-select [formControl]="selectedChannel">
            <mat-option *ngFor="let item of allMicroserviceChanels" [value]="item.value">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field class="full-width">
          <mat-label> Status</mat-label>
          <mat-select [formControl]="selectedStatus">
            <mat-option *ngFor="let item of allMicroserviceStatuses" [value]="item.value">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <button mat-raised-button color="accent" class="ml-2" (click)="readMicroservices()">
          <span class="lni-search"></span> &nbsp;&nbsp;Search
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<div class="mt-4" [hidden]="!loading">
  <div class="progress-spinner-container">
    <div class="progress-spinner-content">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="75"></mat-progress-spinner>
    </div>
  </div>
</div>
<div *ngIf="noItems && filterArray.length==0" class="undefined-list mt-4">
  No data available.
</div>

<mat-card class="mat-card-items" [ngClass]="tabletMode? 'mt-3':'mt-5'" [hidden]="!showMatCard && filterArray.length==0">

  <mat-card-content>
    <div class="items-table-container"  [style.max-height.px]="height">
      <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" class="full-width">
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Microservice Name</span>
            <span *ngIf="tabletMode">Microservice <br />Name</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Name'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="IpAddress">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode"> Ip Address</span>
            <span *ngIf="tabletMode">Ip Address</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'IpAddress'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.ipAddress}}
          </td>
        </ng-container>


        <ng-container matColumnDef="Channel">
          <th mat-header-cell *matHeaderCellDef>
            <span>Channel</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.channel }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <span>Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="{{element.status | lowercase }}-status"><span> {{ element.status }} <span *ngIf="element.hasLogDelay==true" matTooltip="This microservice may be running but has delayed log" > *</span></span></span>
           
          </td>
        </ng-container>

        <ng-container matColumnDef="lastActivityDate">
          <th mat-header-cell *matHeaderCellDef>
            <span>Last time action</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lastActivityDate | date:'HH:mm:ss' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element" class="actions">
            <div [matTooltip]="element.description==null?'Description is not available':'See description'">
              <button mat-icon-button [disabled]="element.description==null" (click)="openDescription(element)">
                <span class="lni-eye"></span>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true "></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator [length]="totalItems"
                   [pageSize]="pageSize"
                   (page)="handlePage($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
