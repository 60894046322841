import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserRoles } from '../../../../../../api-authorization/api-authorization.constants';
import { AuthorizeService } from '../../../../../../api-authorization/authorize.service';

@Component({
  selector: 'app-credit-limit-reached',
  templateUrl: './credit-limit-reached.component.html',
  styleUrls: ['./credit-limit-reached.component.scss']
})
export class CreditLimitReachedComponent implements OnInit {

  public currentCredit: number = 0;
  public isReseller: boolean = false;
  private userRole: string = "";

  constructor(private authorizeService: AuthorizeService,
    private dialogRef: MatDialogRef<CreditLimitReachedComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: number | null) {

    if (data == null) {
      return;
    }

    this.currentCredit = data;
  }

  ngOnInit() {

    this.authorizeService.getUserRole().subscribe(x => {
      //console.log("userRole=", x);
      if (x == null || x == "") {
        return;
      }

      if (x == UserRoles.Reseller) {
        this.isReseller = true;
      }

      this.userRole = x.toLowerCase();
    });

    localStorage.setItem('newLogin', "false");
  }

  goToAllertSettings() {
    this.close();
    this.router.navigate([`management/${this.userRole}/console/credit-alerts`]);
  }

  goToRecharge() {
    this.close();
    this.router.navigate([`management/${this.userRole}/billing`]);
  }

  close() {
    this.dialogRef.close();
  }

}
