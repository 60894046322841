<div class="filter-grid-container">
  <a mat-button class="filter-grid-btn"  [class.is-applied]="isApplied" (click)="toggle()">
    <i class="lni lni-funnel"></i>
  </a>
  <div class="filter-grid-container-form" [class.mat-elevation-z8]="isOpen" [class.visible]="isOpen" [class.is-first-column]="isFirstColumn">
    <form [formGroup]="form" (ngSubmit)="apply()">

      <div *ngIf="propertyType=='Custom'">
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="Key" formControlName="operatorName" />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="Value" formControlName="operatorValue" />
        </mat-form-field>
      </div>
      <div *ngIf="propertyType!='Custom'">
        <mat-form-field class="full-width">
          <mat-label>Select operator </mat-label>
          <mat-select formControlName="operatorName">
            <mat-option *ngFor="let operator of operators[propertyType]" [value]="operator"  class="filter-form-operator">
              {{operator}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-radio-group aria-label="Select an option" formControlName="operatorValue" *ngIf="propertyType == 'Boolean'">
          <mat-radio-button value="true">{{trueLabel}}</mat-radio-button>
          <mat-radio-button class="ml-2" value="false">{{falseLabel}}</mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="full-width" *ngIf="propertyType == 'String'">
          <input type="text" matInput placeholder="Value" formControlName="operatorValue" />
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="propertyType == 'Number'">
          <input type="number" matInput placeholder="Value" formControlName="operatorValue" />
        </mat-form-field>

        <mat-form-field class="full-width" *ngIf="propertyType == 'Date' || propertyType == 'Timestamp'">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="operatorValue">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
     

      <div class="mt-1">
        <button mat-raised-button cdkFocusInitial color="primary" type="submit" [disabled]="form.invalid"> Apply </button>
        <button type="button" mat-button color="accent" [disabled]="operatorValue.value==null && operatorName.value==null" (click)="clear()">Clear</button>
      </div>
    </form>
  </div>
</div>

