import { Component } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { ActivityWhatsappDataSource, WhatsappConversationMetricsDataSource } from '../../../../core/datasource/whatsapp/whatsapp-activity-datasource';
import { IInboundActiveNumber } from '../../../../core/interfaces/inbound/inbound-active-number.interface';
import { IWhatsappConversationMetric } from '../../../../core/interfaces/whatsapp/whatsapp-conversation-metric.interface';
import { WhatsappService } from '../../../../core/services/whatsapp/whatsapp.service';
import { GeneralActivityFormService } from './general-activity-form.service';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-general-activity',
  templateUrl: './general-activity.component.html',
  styleUrl: './general-activity.component.scss',
  providers: [GeneralActivityFormService, WhatsappService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class GeneralActivityComponent {

  public whatsappNumbers: IInboundActiveNumber[] = [];
  public totalOutbound: number = 0;
  public totalInbound: number = 0;

  public displayedColumns = ["channel", "value"];
  public dataSource: ActivityWhatsappDataSource;
  public activitySbj: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public metricsDisplayColumns = ['conversationCategory', "total", "averagePricePerConversation", "totalPrice"];
  public metricsDataSource: WhatsappConversationMetricsDataSource;
  public metricsSbj: BehaviorSubject<IWhatsappConversationMetric[]> = new BehaviorSubject<IWhatsappConversationMetric[]>([]);

  public loading: boolean = false;
  public maxDate: Date = new Date();
  private filterDate: Date = new Date();

  constructor(private formService: GeneralActivityFormService,
    private activityService: WhatsappService,
    private title: Title) {
    this.title.setTitle("WhatsApp Activity - Dillo");
  }

  ngOnInit() {
    this.startDate.valueChanges.subscribe(value => {
      this.filterDate = new Date(value);

      this.filterDate.setDate(20);
    });

    this.activityService.getNumbers().subscribe(value => {
      this.whatsappNumbers = value;

      if (this.whatsappNumbers.length > 0) {
        this.number.setValue(this.whatsappNumbers[0].telephoneNumber);
        this.search();
      }
    });

    this.dataSource = new ActivityWhatsappDataSource(this.activitySbj);
    this.metricsDataSource = new WhatsappConversationMetricsDataSource(this.metricsSbj);
  }

  get form() {
    return this.formService.form;
  }

  get startDate() {
    return this.formService.getFormControl("startDate");
  }

  get number() {
    return this.formService.getFormControl("number");
  }

  search() {
    this.loading = true;
    let request = this.formService.createActivityRequest();
    request.startDate = this.filterDate;

    this.activityService.getActivity(request).subscribe(response => {
      this.loading = false;

      if (response == null) {
        this.totalInbound = this.totalOutbound = 0;
      }
      else {
        this.totalOutbound = response.outbound;
        this.totalInbound = response.inbound;
      }

      this.activitySbj.next([{ channel: "Outbound", value: this.totalOutbound }, { channel: "Inbound", value: this.totalInbound }]);
      this.metricsSbj.next(response.metrics);
    });
  }

  startMonthHandler(event, datepicker) {
    //getMonth() is 0 indexed, in order to get the current month +1
    var date = new Date(`${this.startDate.value.getFullYear()}-${event.month() + 1}-1`);
    this.filterDate = date;

    this.startDate.patchValue(this.filterDate);

    //close datepicker, partionKey contains year and month and we will return result for one or more month, not by day
    datepicker.close();
  }

  startYearHandler(event, datepicker) {
    console.log("event", event);

    var date = new Date(`${event.year()}-${this.filterDate.getMonth() + 1}-1`);
    this.filterDate = date;

    this.startDate.patchValue(date);
  }

}
