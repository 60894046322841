import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IChecklistGroup } from '../../core/interfaces/survey/checklist-group';
import { SurveyService } from '../../core/services/survey/survey.service';
import { DeleteItemsConfirmationComponent } from '../../shared/components/delete-items-confirmation/delete-items-confirmation.component';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {

  public checklists: IChecklistGroup[];
  public showCompletedTasks: boolean = true;

  public hasChecklists: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public loading: boolean = false;

  @Input() toggleStateSubject!: BehaviorSubject<boolean>;
  @Input() userRole: string;

  constructor(private surveyService: SurveyService,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  loadChecklists() {
    this.loading = true;

    this.surveyService.getChecklist().subscribe(data => {
      this.checklists = data;
      this.hasChecklists.next(this.checklists && this.checklists.length > 0);

      this.loading = false;
    });
  }

  toggleChecklist() {
    if (this.toggleStateSubject.value == false) {
      this.loadChecklists();
    }

    //important note: is set to null and not false, in this way will not load twice unde some circumstances
    if (this.allTasksCompleted()) {
      this.toggleStateSubject.next(null);
      return;
    }

    this.toggleStateSubject.next(!this.toggleStateSubject.value);
  }

  getSurveyProgress(): number {
    const totalTasks = this.getTotalTasks();
    const completedTasks = this.getCompletedTasks();
    return totalTasks ? (completedTasks / totalTasks) * 100 : 0;
  }

  getCompletedTasks(): number {
    return this.checklists.reduce((sum, group) => sum + group.tasks.filter(t => t.isCompleted).length, 0);
  }

  getTotalTasks(): number {
    return this.checklists.reduce((sum, group) => sum + group.tasks.length, 0);
  }

  openTaskLink(task) {
    if (!task.isCompleted && task.websiteLink) {
      this.toggleChecklist();
      const url = `/management/${this.userRole.toLowerCase()}/${task.websiteLink}`;
      this.router.navigate([url]);
    }
  }

  openMarkAsCompletedConfirmation(task) {
    const dialogMessage = `<div class="font-weight-bold">Are you sure you want to mark task "${task.name}" as completed?</div>`;

    const dialogRef = this.dialog.open(DeleteItemsConfirmationComponent, {
      minWidth: "25rem",
      minHeight: "7rem",
      data: dialogMessage
    });

    dialogRef.afterClosed().subscribe(response => {
      if (!response) {
        return;
      }

      this.markAsCompleted(task);
    });
  }

  toggleShowCompletedTasks() {
    this.showCompletedTasks = !this.showCompletedTasks;
  }

  hasAllCompleted(tasks: any[]): boolean {
    return tasks.every(task => task.isCompleted);
  }

  allTasksCompleted(): boolean {
    return this.checklists.every(group => group.tasks.every(task => task.isCompleted));
  }

  private markAsCompleted(task) {
    this.surveyService.completeTaskAsync(task.surveyId, task.id).subscribe((success: boolean) => {
      if (success) {
        task.isCompleted = true;
      }
    });
  }
}
