import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InboundBillingType, InboundBillingTypeList } from '../../../../core/constants/inbound-billing-type';
import { ScreenSize } from '../../../../core/constants/screen-size.constants';
import { InboundVoicePricelistDataSource } from '../../../../core/datasource/inbound-voice-pricelist-data-source';
import { IVoiceInboundPriceListItemsRequest } from '../../../../core/interfaces/common/pricelist-items-request.interface';
import { FilterGridData } from '../../../../core/interfaces/filter/filter-operator';
import { IInboundVoicePricelistItem } from '../../../../core/interfaces/inbound/inbound-voice-pricelist-item.interface';
import { IInboundVoicePricelistResponse } from '../../../../core/interfaces/inbound/inbound-voice-pricelist-response.interface';
import { InboundVoicePricelistService } from '../../../../core/services/inbound/inbound-voice-pricelist.service';
import { FilterGridComponent } from '../../filter-grid/filter-grid.component';

@Component({
  selector: 'app-view-inbound-base-pricelist-items',
  templateUrl: './view-inbound-base-pricelist-items.component.html',
  styleUrls: ['./view-inbound-base-pricelist-items.component.scss']
})
export class ViewInboundBasePricelistItemsComponent implements OnInit {

  public height: Number = 0; //the height of the grid

  public itemsDataSource: InboundVoicePricelistDataSource | null;
  public itemsSubject: BehaviorSubject<IInboundVoicePricelistItem[]> = new BehaviorSubject<IInboundVoicePricelistItem[]>([]);

  public loading: boolean = false;
  public noItems: boolean = false;
  public showMatCard: boolean = false;
  private page: number = 1;
  public invalidForm: boolean;
  public pageSize: number = 20;
  private pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.page);
  public totalItems: number;
  public search = new UntypedFormControl();
  public billingType = new UntypedFormControl();
  public searchFilter: string = '';
  public defaultChannels: number = 0;
  public selectedTab: number = 0;
  public billingTypesList: IInboundVoicePricelistResponse[] = [];

  public displayedColumns: string[] = ["Name", "Prefix", "SetupFee", "MonthlyFee"];
  public displayedColumnsExtra: string[] = ["Name", "Prefix", "MinNumberOfChannels", "MaxNumberOfChannels", "SetupFee", "MonthlyFee"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('tabGroup') tabGroup;
  @ViewChildren(FilterGridComponent) childFilter: QueryList<FilterGridComponent>
  @ViewChild(MatSort) sort: MatSort;

  public innerWidth: any;
  public tabletMode: boolean = false;

  public filterArray: FilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;

  constructor(private priceListService: InboundVoicePricelistService,
    private titleService: Title) {
    this.titleService.setTitle("Inbound Voice Prices | Dillo");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = event.target.innerWidth;


    if (this.innerWidth < ScreenSize.BreakpointLaptop) {
      this.tabletMode = true;
      return;
    }

    this.tabletMode = false;
  }

  ngOnInit() {

    const observer = new ResizeObserver(entries => {
      //important! adjust the value to be subtracted for a good fit of the table on the page 
      this.height = entries[0].contentRect.height - 450;
    });

    observer.observe(document.querySelector('body'));

    window.innerWidth < ScreenSize.BreakpointLaptop ? this.tabletMode = true : this.tabletMode = false;

    this.pageSubject.subscribe(p => {
      this.page = p;
      this.submit(p);
    });

    this.itemsDataSource = new InboundVoicePricelistDataSource(this.itemsSubject);

    var adminBillingTypes = InboundBillingTypeList;
    this.billingTypesList = adminBillingTypes.filter(f => f.billingType != InboundBillingType.FlatRateExtraChannels);

    this.search.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(value => {
      //replace special chars
      this.searchFilter = value.replace(/[^a-zA-Z0-9]/g, "");
      //console.log("search=", this.searchFilter);
      this.invalidForm = this.validateSearchField();
      this.checkRouteBillingTypes(this.searchFilter);

      if (this.billingType.value == InboundBillingType.FlatRate) {
        this.selectedTab = 0;
      }
    });

    this.billingType.valueChanges.subscribe(value => {
      this.resetAllFiltersAndSorting();
      this.setColumns();
      this.pageSubject.next(1);
      this.paginator.firstPage();
    });

  }

  checkRouteBillingTypes(value: any) {
    if (value != "") {
      this.loading = true;
      this.priceListService.getRouteBillingTypes(value).subscribe(response => {
        this.loading = false;
        this.billingTypesList = response;
        var billingTypeValid = response.find(value => value.isDisabled == false);
        var selectedValue: IInboundVoicePricelistResponse = this.billingTypesList.find(value => value.billingType == this.billingType.value);

        if (selectedValue == null || (selectedValue != null && selectedValue.isDisabled)) {
          this.billingType.setValue(billingTypeValid != null ? billingTypeValid.billingType : null);
        }
        else {
          this.pageSubject.next(1);
          this.paginator.firstPage();
        }


        //this.pageSubject.next(1);
        //this.paginator.firstPage();
      });
    }
  }

  submit(page: number) {
    var selectedValue: IInboundVoicePricelistResponse = this.billingTypesList.find(value => value.billingType == this.billingType.value);
   
    if (this.billingType.value != null && this.searchFilter != "" && (selectedValue != null && selectedValue.isDisabled == false)) {
      this.loading = true;
      this.noItems = false;
      this.showMatCard = false;
      var request = this.createRequest();
      this.priceListService.getInboundBasePrices(request).subscribe(x => {
        if (request.page == 1) {
          this.totalItems = x.total;
        }
        this.showData(x)
      })
    }
  }

  showData(data) {
    //console.log("data=", data);
    this.loading = false;

    if (data == null) {
      this.noItems = true;
      return;
    }
    this.itemsSubject.next(data.data);

    if (this.totalItems > 0) {
      this.showMatCard = true;
      if (this.billingType.value != null && this.billingType.value != InboundBillingType.PayPerUse) {
        this.defaultChannels = data.data[0].defaultChannels;
      }
    }
    else {
      this.noItems = true;
    }

    console.log("response", this.billingType.value, this.showMatCard)
  }


  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  validateSearchField(): boolean {
    const regex = new RegExp('"^[a-zA-Z0-9]+$"');
    if (regex.test(this.searchFilter)) {
      return true;
    }
    return false;
  }

  setColumns() {
    if (this.billingType != null) {

      if (this.billingType.value == InboundBillingType.PayPerUse) {
        this.displayedColumns = ["Name", "Prefix", "SetupFee", "MonthlyFee", "PricePerInterval", "Interval"];
      }
      else {
        this.displayedColumns = ["Name", "Prefix", "SetupFee", "MonthlyFee"];
      }
    }

  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.resetAllFiltersAndSorting();
    if (tabChangeEvent.index == 1) {
      this.loading = true;
      this.selectedTab = 1;
      const request: IVoiceInboundPriceListItemsRequest = {
        billingType: InboundBillingType.FlatRateExtraChannels,
        page: 1,
        pageSize: this.pageSize,
        filter: this.searchFilter,
      }

      this.priceListService.getInboundBasePrices(request).subscribe(x => {
        if (request.page == 1) {
          this.totalItems = x.total;
        }
        this.showData(x)
      })
    }
    else {
      this.displayedColumns = ["Name", "Prefix", "SetupFee", "MonthlyFee"];
      this.billingType.setValue(InboundBillingType.FlatRate);
    }
  }

  addFilterComponent(event: FilterGridData) {
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      var existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);

    }

    this.pageSubject.next(1);
  }

  sortData(event) {

    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.pageSubject.next(1);

  }

  createRequest() {
    var billingType = this.billingType.value;

    if (billingType == InboundBillingType.FlatRate && this.selectedTab == 1) {
      billingType = InboundBillingType.FlatRateExtraChannels;
    }

    const request: IVoiceInboundPriceListItemsRequest = {
      billingType: billingType,
      page: this.page,
      pageSize: this.pageSize,
      filter: this.searchFilter,
      filters: this.filterArray,
      sortDirection: this.sortDirection,
      sortField: this.sortField
    }

    return request;
  }

  resetAllFiltersAndSorting() {
    this.sortDirection = null;
    this.sortField = null;
    this.page = 1;
    if (this.filterArray.length > 0) {
      this.childFilter.forEach(x => {
        x.resetFilter();
      });
    }
    this.sort.active = '';
    this.sort.direction = "desc";
    this.sort._stateChanges.next();

    this.filterArray = [];
    this.showMatCard = false;
  }


}
