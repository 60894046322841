<div class="items-table-container">
  <table mat-table [dataSource]="dataSource" class="full-width">
    <ng-container matColumnDef="intent">
      <th mat-header-cell *matHeaderCellDef>Intent</th>
      <td mat-cell *matCellDef="let element">{{element.nodekey}}</td>
    </ng-container>

    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef>Text</th>
      <td mat-cell *matCellDef="let element">{{element.text}}</td>
    </ng-container>

    <ng-container matColumnDef="entity">
      <th mat-header-cell *matHeaderCellDef>Entity</th>
      <td mat-cell *matCellDef="let element">{{element.entity}}</td>
    </ng-container>

    <ng-container matColumnDef="subtype">
      <th mat-header-cell *matHeaderCellDef>Subtype</th>
      <td mat-cell *matCellDef="let element">{{element.subtype}}</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element">{{element.value}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>
