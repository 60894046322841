import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IBillingInfo } from '../../../../../core/interfaces/common/billing-info.interface';
import { ICountry } from '../../../../../core/interfaces/common/country.interface';
import { IPaymentMethod } from '../../../../../core/interfaces/payment/payment-method';
import { PaymentsService } from '../../../../../core/services/common/payments.service';
import { BillingInfoService } from '../../../../../core/services/customers/billing-info.service';
import { EditPaymentMethodFormService } from '../../edit-payment-method-form.service';
import { EditPaymentMethodLoaderService } from '../../edit-payment-method-loader.service';
import { SetDefaultPaymentMethodComponent } from '../set-default-payment-method/set-default-payment-method.component';

declare var Stripe: any;


@Component({
  selector: 'app-edit-payment-method',
  templateUrl: './edit-payment-method.component.html',
  styleUrls: ['./edit-payment-method.component.scss'],
  providers: [
    EditPaymentMethodFormService,
    EditPaymentMethodLoaderService,
    PaymentsService, BillingInfoService
  ]
})
export class EditPaymentMethodComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Muli", sans-serif',
        lineHeight: '1.2em',
        fontSize: '1em',
      }
    },
    hidePostalCode: true
  };

  private intentClientSecret: string = null
  private stripeCustomerId: string = null
  private paymentMethod: IPaymentMethod = null
  //private stripe;
 //private card;

  public confirmationMsg: string = null;
  public loading: boolean = false;
  public success: boolean = false;
  public cardInfo:any=null
  public isNew: boolean = false;
  public existDefault: boolean = false;
  public showMessage: boolean = false;
  public noDefault: boolean = false;
  public hidebuttons: boolean = false;
  public countries: ICountry[] = [];
  public filteredOptions: Observable<ICountry[]>;
  public billingInfo: IBillingInfo = null;

  constructor(private dialogRef: MatDialogRef<EditPaymentMethodComponent>,
    private paymentService: PaymentsService,
    private dialog:MatDialog,
    private formService: EditPaymentMethodFormService,
    private loaderService: EditPaymentMethodLoaderService,
    private billingInfoService: BillingInfoService,
    private stripeService: StripeService,
    @Inject(MAT_DIALOG_DATA) public data: any | null)
  {
    if (data == null) {
      return;
    }

    this.intentClientSecret = data.intentClientSecret;
    this.stripeCustomerId = data.stripeCustomerId;
    this.cardInfo = data.paymentMethod;
    this.existDefault = data.exists;

    if (data.paymentMethod == null) {
      this.isNew = true;
    }
    else {
      //console.log("paymentMethod=", data.paymentMethod);
      this.loaderService.loadPaymentMethod(data.paymentMethod);
      this.paymentMethod=data.paymentMethod
    }
  }

  ngOnInit() {

    if (this.isNew) {
      this.formService.setValidator("cardholderName");

      this.formService.clearValidators("cardNumber");
      this.formService.clearValidators("expMonth");
      this.formService.clearValidators("expYear");
    }

   // this.getStripeKey();

    this.billingInfoService.getCountries().subscribe(x => {
      this.countries = x;
      this.country.setValidators(this.formService.forbiddenNamesValidator(this.countries));
      var loadedCountry = this.countries.find(x => x.twoLetterISOName == this.country.value);
      if (loadedCountry != undefined) {
        this.country.setValue(loadedCountry.name);
      }
    });

    this.billingInfoService.getBillingInfo().subscribe(x => {
      this.billingInfo = x;
    })

    this.filteredOptions = this.country.valueChanges.pipe(
      startWith(''),
      map(value =>
        this.filter(value))
    );
  }

  get form() {
    return this.formService.form;
  }

  get cardNumber() {
    return this.formService.getFormControl("cardNumber");
  }

  get expMonth() {
    return this.formService.getFormControl("expMonth");
  }

  get expYear() {
    return this.formService.getFormControl("expYear");
  }

  get isDefault() {
    return this.formService.getFormControl("isDefault");
  }

  get cardholderName() {
    return this.formService.getFormControl("cardholderName")
  }

  get country() {
    return this.formService.getFormControl("country");
  }


  get address() {
    return this.formService.getFormControl("address");
  }

  get city() {
    return this.formService.getFormControl("city");
  }

  get state() {
    return this.formService.getFormControl("state");
  }

  get postalCode() {
    return this.formService.getFormControl("postalCode");
  }

  //getStripeKey() {
  //  this.paymentService.getStripeKey().subscribe(x => {
  //    this.stripe = Stripe(x);
  //    const elements = this.stripe.elements();
  //    this.card = elements.create('card', { hidePostalCode: true });
  //    this.card.mount('#card-element');
  //    this.card.addEventListener('change', event => {
  //      const displayError = document.getElementById('card-errors');
  //      if (event.error) {
  //        displayError.textContent = event.error.message;
  //      } else {
  //        displayError.textContent = '';
  //      }
  //    });
  //  });
  //}

  submit() {
    this.noDefault = false;

    if (this.isNew) {
      if (this.isDefault.value && this.existDefault) {
        this.openDialog(this.paymentMethod);
      }
      else if (this.isDefault.value==false && this.existDefault==false) {
        this.noDefault = true;
      }
      else {
        this.saveCard(this.cardholderName.value);
      }
      
    }
    else {
      if (this.isDefault.value && this.existDefault && this.paymentMethod.isDefault == false) {
        this.openDialog(this.paymentMethod);
      }
      else {
        this.updateCard();
      }
      
    }
  }

  saveCard(cardholderName) {
    //var countryISO = "";
    //this.countries.map(value => {
    //  if (value.name === this.country.value) {
    //    countryISO = value.twoLetterISOName;
    //  }
    //});
    this.loading = true;
    let selectedCountryObj = this.countries.find(x => x.name == this.country.value); 
    this.stripeService.confirmCardSetup(
      this.intentClientSecret,
      {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: cardholderName,
            email: this.billingInfo.email,
            phone: this.billingInfo.telephoneNumber,
            address: {
              city: this.city.value,
              country: selectedCountryObj.twoLetterISOName,
              line1: this.address.value,
              postal_code: this.postalCode.value,
              state: this.state.value

            },
          },
          metadata: { "IsDefault": `${this.isDefault.value}` }
        },
      }
    ).subscribe(result=>{
      if (result.error) {
        //console.log("result.error=", result.error, result)
        this.confirmationMsg = "An error occurred while saving card info!";
        this.loading = false;
      } else {
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to server to save the
        // card to a Customer
        this.paymentService.saveCard(result.setupIntent.payment_method, this.stripeCustomerId, this.isDefault.value).subscribe(x => {
          this.success = x;
          if (x) {
            this.confirmationMsg = "Information successfuly saved!";
          }
          else {
            this.confirmationMsg = "An error occurred while saving card info!"
          }
          this.loading = false;

          this.dialogRef.close({ saved: x, paymentMethod: result.setupIntent.payment_method });
        })
       
      }
    });
  }

  updateCard() {
    this.loading = true;
    const card = this.formService.createCardInfo(this.cardInfo);

    if (this.paymentMethod.id ==null) {
      this.loading = false;
      return;
      
    }
    this.paymentService.updatePaymentMethod(this.stripeCustomerId, card).subscribe(x => {
      this.loading = false;
      if (x == null) {
        this.dialogRef.close({ saved: false, paymentMethod: null });
      }
      else {
        this.dialogRef.close({ saved: true, paymentMethod: x });
      }
    })

  }

  cancel() {
    this.dialogRef.close({ saved: false, paymentMethod: null });
  }

  openDialog(paymentMethod: IPaymentMethod) {
    this.hidebuttons = true;
    const defaultDialogRef = this.dialog.open(SetDefaultPaymentMethodComponent, {
      minHeight: '12rem',
      minWidth: '20rem'
    });

    defaultDialogRef.afterClosed().subscribe(response => {
      this.handleResponse(response, paymentMethod);
    })
  }

  handleResponse(response, paymentMethod: IPaymentMethod) {
    if (response == null) {
      return;
    }
    this.isDefault.setValue(response.default);
   
    if (this.isNew) {
      this.saveCard(this.cardholderName.value);
    }
    else {
      paymentMethod.isDefault = response.default;
      if (response.default) {
        this.updateCard();
      }
      else {
        this.dialogRef.close({ saved: false, data: paymentMethod })
      }
    }
  }

  onClickIsDefault(event) {
    if (event.checked == false && this.paymentMethod.id != null && this.paymentMethod.isDefault) {
      this.showMessage = true;
      this.isDefault.setValue(this.paymentMethod.isDefault);
    }
  }

  private filter(value: any): ICountry[] {
    if (value == null) {
      value = '';
    }
    const filterValue = value.toLocaleLowerCase();

    return this.countries.filter(x => x.name.toLocaleLowerCase().indexOf(filterValue) === 0);
  }
}
