<div *ngIf="hasChecklists.value">
  <button style="z-index: 999" class="fixed-button" (click)="toggleChecklist()">
    <mat-icon class="button-icon">task_alt</mat-icon> My task list
  </button>
</div>

<div class="side-popup" [class.open]="toggleStateSubject.value">
  <div class="mt-4" [hidden]="!loading">
    <div class="progress-spinner-container">
      <div class="progress-spinner-content">
        <mat-progress-spinner mode="indeterminate" color="primary" diameter="50"></mat-progress-spinner>
      </div>
    </div>
  </div>

  <div class="bkg-lighter w-100 d-flex flex-column checklist-header p-3">
    <div class="w-100 d-flex flex-row justify-content-between">
      <h3>My task list</h3>
      <button class="no-style" (click)="toggleChecklist()">&#x2715;</button>
    </div>
    <p>Let's make Dillo work for you!</p>
    <p>Follow these steps to get the most out of Dillo.</p>
    <div class="w-100 d-flex flex-row justify-content-between align-items-center mt-2">
      <div *ngIf="hasChecklists.value" class="w-100 d-flex flex-row align-items-center">
        <div class="progress-container">
          <div class="progress-bar" [style.width]="getSurveyProgress() + '%'"></div>
        </div>
        <p class="progress-text ml-2 my-0">{{ getCompletedTasks() }}/{{ getTotalTasks() }}</p>
      </div>
    </div>
  </div>

  <div class="checklist-content p-3">
    <ng-container *ngIf="!showCompletedTasks && allTasksCompleted()">
      <div class="undefined-list mt-4">
        No data available.
      </div>
    </ng-container>

    <div *ngFor="let group of checklists; let i = index">
      <ng-container *ngIf="showCompletedTasks || !hasAllCompleted(group.tasks)">
        <ng-container *ngIf="i > 0; else generalSteps">
          <h5>Get started with {{ group.title }}</h5>
        </ng-container>
        <ng-template #generalSteps>
          <h5>{{ group.title }}</h5>
        </ng-template>

        <div *ngFor="let task of group.tasks">
          <ng-container *ngIf="showCompletedTasks || !task.isCompleted">
            <div class="task mt-3" [ngClass]="{'completed-task': task.isCompleted}">
              <div class="task d-flex flex-row">
                <mat-icon class="task-icon" [ngClass]="{'completed': task.isCompleted, 'pending': !task.isCompleted}">
                  {{ task.isCompleted ? 'check_circle' : 'cancel' }}
                </mat-icon>
                <div class="d-flex flex-column align-items-start ml-2">
                  <span class="task-name" (click)="openTaskLink(task)">{{ task.name }}</span>
                  <span style="max-width: 480px" *ngIf="!task.isCompleted" class="task-description">
                    {{ task.description  | slice:0:136 }}
                    <span *ngIf="task.description.length > 136" matTooltip="{{task.description}}">...</span>
                  </span>
                  <div *ngIf="!task.isCompleted" class="task d-flex flex-row align-items-center">
                    <a class="task-mark-complete mr-3" *ngIf="task.websiteLink != ''" (click)="openTaskLink(task)">Go to task</a>
                    <a class="task-mark-completed" (click)="openMarkAsCompletedConfirmation(task)">Mark as completed</a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <hr class="group-separator" />
      </ng-container>
    </div>

    <div class="task-action bkg-lighter full-width p-1">
      <div class="d-flex flex-column align-items-start">
        <a class="ml-3 no-style text-action" (click)="toggleShowCompletedTasks()">
          {{ showCompletedTasks ? 'Hide Completed Tasks' : 'Show Completed Tasks' }}
        </a>
      </div>
    </div>
  </div>
</div>
