import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule, Routes } from '@angular/router';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ToastrModule } from 'ngx-toastr';
import { ManagementLayoutComponent } from './layout/management/management-layout/management-layout.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

const routes: Routes = [
  {
    path: 'management',
    component: ManagementLayoutComponent,
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    //canActivate: [AuthorizeGuard]
  },
  {
    path: 'docs',
    loadChildren:() => import('./docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./site-under-construction/site-under-construction.module').then(m => m.SiteUnderConstructionModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./site-under-construction/site-under-construction.module').then(m => m.SiteUnderConstructionModule)
  },
  {
    path: 'dillo-pricelist',
    loadChildren:() => import('./dillo-pricelist/dillo-pricelist.module').then(m => m.DilloPricelistModule)
  },
  {
    path: '',
    //component: ManagementLayoutComponent,
    //loadChildren: './management/management.module#ManagementModule',
    redirectTo: "management",
    pathMatch:"full"
      //component: PresentationLayoutComponent,
      //loadChildren: './presentation/home/home.module#HomeModule',
    //canActivate: [AuthorizeGuard]
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    MonacoEditorModule.forRoot(),
    ToastrModule.forRoot({ positionClass: 'toast-bottom-left', preventDuplicates: true }),
    TimepickerModule.forRoot()
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
