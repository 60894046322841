import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPaymentMethod } from '../../../../core/interfaces/payment/payment-method';
import { PaymentsService } from '../../../../core/services/common/payments.service';
import { RechargeEditFormService } from '../recharge-edit-form.service';
import { RechargeEditLoaderService } from '../recharge-edit-loader.service';

@Component({
  selector: 'app-edit-auto-recharge-info',
  templateUrl: './edit-auto-recharge-info.component.html',
  styleUrls: ['./edit-auto-recharge-info.component.scss'],
  providers: [
    RechargeEditFormService,
    RechargeEditLoaderService,
    PaymentsService
  ]
})
export class EditAutoRechargeInfoComponent implements OnInit {
  public success: boolean = false;
  public loading: boolean = false;
  public disableBtn: boolean = false;
  public showDropDown: boolean = false;
  public showAutorechargeInfo: boolean = false;
  public paymentMethodsList: IPaymentMethod[] = [];

  constructor(private formService: RechargeEditFormService,
    private formLoader: RechargeEditLoaderService,
    private dialogRef: MatDialogRef<EditAutoRechargeInfoComponent>,
    private paymentService:PaymentsService,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (this.data == null) {
      return;
    }

    //console.log("data matDialog=", data);
    this.formLoader.loadAutoRechargeInfo(data.autoRechargeInfo)
    this.showAutorechargeInfo = data.autoRechargeInfo.isAutoRechargeEnable;
    this.paymentMethodsList = data.paymentMethodsList;
    this.showDropDown = data.hasMorePaymentMethods;
    if (!this.showAutorechargeInfo) {
      this.formService.clearValidators(["autoRechargeCreditLimit", "primaryMethodId", "amount"]);
    }
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(result => {
      this.disableBtn = this.form.invalid;
      if (this.secondaryMethodId.value == this.primaryMethodId.value) {
        this.disableBtn = true;
      }
    });

  }

  get form() {
    return this.formService.form;
  }

  get autoRechargeCreditLimit() {
    return this.formService.getControlName("autoRechargeCreditLimit");
  }

  get enableAutoRecharge() {
    return this.formService.getControlName("enableAutoRecharge");
  }

  get primaryMethodId() {
    return this.formService.getControlName("primaryMethodId");
  }

  get secondaryMethodId() {
    return this.formService.getControlName("secondaryMethodId");
  }

  get amount() {
    return this.formService.getControlName("amount");
  }

  onChangeEnableAutoRecharge(event) {
    this.showAutorechargeInfo = event.checked;

    if (event.checked == false) {
      this.formService.clearValidators(["autoRechargeCreditLimit", "primaryMethodId", "amount"]);
    }
    else {
      this.formService.setValidatorsRequired(["autoRechargeCreditLimit", "primaryMethodId", "amount"]);
    }
  }

  close() {
    this.dialogRef.close({ saved: false, autoRechargeInfo: this.data.autoRechargeInfo})
  }

  submit() {
    this.loading = true;
    const autoRecharge = this.formService.createCustomerAutoRechargeInfo(this.data.autoRechargeInfo);
    //console.log("autoRechargeObj=", autoRecharge);
    this.paymentService.updateAutoRechargeInfo(autoRecharge).subscribe(x => {
      this.success = x;
      this.loading = false;
      this.dialogRef.close({ saved: x, autoRechargeInfo: autoRecharge });
      
    })
  }


}
