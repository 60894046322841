import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterGridOperatorList, FilterOperatorType } from '../../../core/constants/filter-operator-type';
import { FilterGridData } from '../../../core/interfaces/filter/filter-operator';
import { FilterGridFormService } from './filter-grid-form.service';


@Component({
  selector: 'app-filter-grid',
  templateUrl: './filter-grid.component.html',
  styleUrls: ['./filter-grid.component.scss'],
  providers: [FilterGridFormService]
})
export class FilterGridComponent implements OnInit {

  @Input() propertyName: string;
  @Input() propertyType: string;
  @Input() isExpression: boolean = false;
  @Input() isFirstColumn: boolean = false;


  @Output() filter = new EventEmitter<FilterGridData>();

  public operators = FilterGridOperatorList;
  public isOpen: Boolean = false;
  public isApplied: Boolean = false;

  public trueLabel: string = 'True';
  public falseLabel: string = 'False';

  constructor(private formService: FilterGridFormService,
    private datepipe: DatePipe) {

  }

  ngOnInit() {

    if (this.propertyType == FilterOperatorType.Boolean) {
      this.operatorName.setValue("Equals");

      if (this.propertyName == 'agentStatus') {
        this.trueLabel = 'Active';
        this.falseLabel = 'Exited';
      }
    }

  }

  get form() {
    return this.formService.form;
  }

  get operatorName() {
    return this.formService.getFormControl('operatorName');
  }

  get operatorValue() {
    return this.formService.getFormControl('operatorValue');
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  apply() {
    this.isApplied = true;

    var opValue = this.operatorValue.value;



    if (this.propertyType == FilterOperatorType.Timestamp || this.propertyType == FilterOperatorType.Date) {
      var today = new Date();
      var dateValue = new Date(this.operatorValue.value)

      if (dateValue != null) {
        opValue = new Date(dateValue.setHours(today.getUTCHours()));
      }
    }

    const filter: FilterGridData = {
      propertyType: this.propertyType,
      propertyName: this.getPropertyName(),
      operatorName: this.operatorName.value,
      value: opValue,
      isExpression: this.isExpression

    }

    this.filter.emit(filter);
    this.isOpen = false;
  }

  clear() {
    this.isApplied = false;

    //reset the form first
    this.form.reset();

    const filter: FilterGridData = {
      propertyType: this.propertyType,
      propertyName: this.getPropertyName(),
      operatorName: this.operatorName.value,
      value: null
    }

    this.filter.emit(filter);
    this.isOpen = false;
  }

  resetFilter() {
    this.isApplied = false;

    //reset the form first
    this.form.reset();

    const filter: FilterGridData = {
      propertyType: this.propertyType,
      propertyName: this.propertyName,
      operatorName: this.operatorName.value,
      value: null
    }
  }

  getPropertyName(): string {
    var propertyName = this.propertyName;

    if (this.isExpression == true && this.propertyName.toLowerCase() == 'status') {
      propertyName = " concat(ToString(c.FinalStatus.PrimaryStatus.Code), (IS_NULL(c.FinalStatus.SecondaryStatus) ? '' : concat('.', ToString(c.FinalStatus.SecondaryStatus.Code))))";
    }

    if (this.isExpression == true && this.propertyName == 'ParticipantDuration') {
      propertyName = " IS_NULL(c.Duration)=false and c.Duration";
    }

    if (this.isExpression == true && this.propertyName == 'ConferenceDuration') {
      propertyName = "IS_NULL(c.Duration)=false and c.Duration";
    }


    if (this.isExpression == true && this.propertyName == 'CallDuration') {
      propertyName = "IS_NULL(c.Duration)=false and c.Duration";
    }

    if (this.isExpression && this.propertyName == 'agentStatus') {
      propertyName = "IS_NULL(c.EndedOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'DeletedOnUtc') {
      propertyName = "IS_NULL(c.DeletedOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'ExpiresOnUtc') {
      propertyName = "IS_NULL(c.ExpiresOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'StartedOnUtc') {
      propertyName = "IS_NULL(c.StartedOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'EndedOnUtc') {
      propertyName = "IS_NULL(c.EndedOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'LastPublishedOnUtc') {
      propertyName = "IS_NULL(c.EndedOnUtc)";
    }

    return propertyName;
  }

}
