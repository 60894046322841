import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';
import { CallbackStatusList } from '../../../../core/constants/callback-status-constants';
import { RequestStatusCallbackLogDatasource } from '../../../../core/datasource/request-status-callback-log.datasource';
import { IRequestStatusCallbackLog } from '../../../../core/interfaces/common/request-status-callback-log.interface';
import { IRequestHistory } from '../../../../core/interfaces/voice/voice-request-history';
import { RequestTraceService } from '../../../../core/services/logging/request-trace.service';

@Component({
  selector: 'app-request-status-callback-requests',
  templateUrl: './request-status-callback-requests.component.html',
  styleUrls: ['./request-status-callback-requests.component.scss'],
  providers: [RequestTraceService]
})
export class RequestStatusCallbackRequestsComponent implements OnInit {
  public height: Number = 0; //the height of the grid

  public requests: BehaviorSubject<IRequestStatusCallbackLog[]> = new BehaviorSubject<IRequestStatusCallbackLog[]>([]);
  public allRequests: IRequestStatusCallbackLog[] = [];
  public dataSource: RequestStatusCallbackLogDatasource | null;

  private page: number = 1;
  public pageSize: number = 20;
  private pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public totalItems: number;
  public displayedColumns = ["date", "status", "error"];
  public callbackStatusList = CallbackStatusList;

  public loading: boolean = false;
  public noItems: boolean = false;
  public callbackStatus: any = "";
  public status = new UntypedFormControl("all");
  public channel: string;
  public request: IRequestHistory;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private logService: RequestTraceService,
    public dialogRef: MatDialogRef<RequestStatusCallbackRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (this.data == null) {
      return;
    }

    this.request = data.request;
    this.channel = data.channel;
  }

  ngOnInit() {
    this.dataSource = new RequestStatusCallbackLogDatasource(this.requests);

    this.status.valueChanges.subscribe(value => {
      this.callbackStatus = value;

      this.pageSubject.next(1);
      this.paginator.firstPage();
    });

    this.pageSubject.subscribe(value => {
      this.page = value;

      this.getRequests();
    });


    this.getRequests();
  }

  getRequests() {

    if (this.request == null) {
      return;
    }

    this.noItems = false;
    this.loading = true;

    if (this.page == null) {
      this.page = 1;
    }

    this.logService.readStatusCallbackRequests(this.request.id, this.channel, this.callbackStatus, this.request.customerCode).subscribe(x => {
      this.loading = false;

      if (x == null) {
        return;
      }

      if (this.page == 1) {
        this.totalItems = x.length;

        if (this.totalItems == 0) {
          this.noItems = true;
        }
      }

      this.allRequests = x;

      this.requests.next(x.slice(0, 20));
    });
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
    let page = e.pageIndex + 1;
    let start = this.pageSize * e.pageIndex;
    let end = this.pageSize * page;
    console.log("??, index=", e.pageIndex + 1, start, end)
    this.requests.next(this.allRequests.slice(start, end));
  }


  copyRequestIdToClipboard(element: IRequestStatusCallbackLog) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = element.requestId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
