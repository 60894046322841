import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IVoicePricelistExtraService } from '../../interfaces/voice/voice-pricelist-extra-service';
import { ApiBaseUrl } from '../../constants/common.constants'
import { IRequestOffer } from '../../interfaces/agent-conference/request-offer.interface';

@Injectable({
  providedIn: 'root'
})
export class ExtraServicesPricelistService {

  private _baseUrl: string = `${ApiBaseUrl}extraservices`;

  constructor(private httpClient: HttpClient) { }

  getExtraServicePricelist(priceListType: string): Observable<IVoicePricelistExtraService[]> {
    return this.httpClient.get<IVoicePricelistExtraService[]>(`${this._baseUrl}/${priceListType}`);
  }

  updateExtraServicePriceList(priceListDto: IVoicePricelistExtraService):Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}`, priceListDto);
  }

  getCustomerExtraServixePrices(priceListType: string): Observable < IVoicePricelistExtraService[] > {
    return this.httpClient.get<IVoicePricelistExtraService[]>(`${this._baseUrl}/userExtraService/${priceListType}`);
    
  }

  requestAgentOffer(request: IRequestOffer): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/agentOffer`, request);
  }
}
