import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';



@NgModule({
  declarations: [SafeHtmlPipe],
  exports: [SafeHtmlPipe],
  providers: [SafeHtmlPipe],
  imports: [
    CommonModule,
  ]
})
export class PipeModule {

  static forRoot(){
    return {
        ngModule: PipeModule,
        providers: [],
    };
  }
}
