import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable } from "rxjs";
import { IApiRequest } from "../../interfaces/sms/api-request.interface";

export class InboundApiRequestDataSource extends DataSource<any> {
  constructor(private subject: BehaviorSubject<IApiRequest[]>) {
    super();
  }

  connect(): Observable<IApiRequest[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}
