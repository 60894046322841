import { Component, OnInit, Inject } from '@angular/core';
import { MenuDetectedIntentDataSource } from '../../../../../core/datasource/inbound-voice-call-data-source';
import { IMenuDetectedIntent } from '../../../../../core/interfaces/voice/menu-detected-intent.interface';
import { BehaviorSubject } from 'rxjs';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IExtractedEntity } from '../../../../../core/interfaces/ai/prediction-result.interface';

@Component({
  selector: 'app-view-menu-detected-intents',
  templateUrl: './view-menu-detected-intents.component.html',
  styleUrls: ['./view-menu-detected-intents.component.scss']
})
export class ViewMenuDetectedIntentsComponent implements OnInit {

  public dataSource: MenuDetectedIntentDataSource | null;
  public displayedColumns = [/*'intent', */'text', "entity", "subtype", 'value'];

  private detectedIntentsSbj: BehaviorSubject<IMenuDetectedIntent[]> = new BehaviorSubject<IMenuDetectedIntent[]>([]);
  private detectedIntents: IMenuDetectedIntent[];
  private isAnonymized: boolean= false;

  constructor(private dialogRef: MatDialogRef<ViewMenuDetectedIntentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (data == null) {
      return;
    }

    this.detectedIntents = data.detectedIntents;
    this.isAnonymized = data.isAnonymized;
  }

  ngOnInit() {
    this.dataSource = new MenuDetectedIntentDataSource(this.detectedIntentsSbj);

    this.detectedIntentsSbj.subscribe(value => {
      if (value.find(x => x.subtype != null && x.subtype != "") == null) {
        //remove subtype column
        this.displayedColumns = this.displayedColumns.filter(x => x != "subtype");
      }
    });

    if (this.detectedIntents != null && this.detectedIntents.length >0) {
      var array: IMenuDetectedIntent[] = [];

      this.detectedIntents.map(item => {
        if (item.entities != null) {
          var entities = this.getEntities(item);
          entities.map(x => array.push(x));
        }
        else {
          const entity: IMenuDetectedIntent = {
            text: item.text,
            nodeKey: item.nodeKey,
            entities: item.entities,
            entity: "",
            value: "",
            subtype: ""
          };
          array.push(entity);
        }
      });

      this.detectedIntentsSbj.next(array);
    }

  }

  getEntities(element:IMenuDetectedIntent):IMenuDetectedIntent[] {
    var entities: IMenuDetectedIntent[] = [];
    Object.keys(element.entities).forEach(key => {
      var extractedEntity: IExtractedEntity[] = element.entities != null ? element.entities[key] : [];

      extractedEntity.map(item => {
        const entity: IMenuDetectedIntent = {
          text: this.isAnonymized == true ? "[anonymized]": element.text ,
          nodeKey: element.nodeKey,
          entities: element.entities,
          entity: key,
          value: this.isAnonymized == true ? "[anonymized]": item.value,
          subtype:item.subtype
        };
        entities.push(entity);
      })

    });

    return entities;

  }

}
