import { Injectable } from '@angular/core';

export const ActionQueueKeys = {
  surveyKey: 'surveyKey',
};

interface IActionConfig {
  action: () => void;
  key?: string;
  waitForKey?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ActionQueueService {

  private waitingActions: Array<IActionConfig> = [];
  private waitList = new Set<string>();

  private globalKeys = [
    ActionQueueKeys.surveyKey
  ];

  constructor() {
    this.globalKeys.forEach(key => {
      this.waitList.add(key);
    });
  }

  enqueue(action: () => void, key?: string, waitForKey?: string | null): void {
    let actionConfig: IActionConfig = { action, key, waitForKey };

    if (key != null) {
      this.waitList.add(key);
    }

    this.process(actionConfig);
  }

  private process(actionConfig: IActionConfig): void {
    if (actionConfig.waitForKey && this.waitList.has(actionConfig.waitForKey)) {
      this.waitingActions.push(actionConfig);
      return;
    }

    actionConfig.action();
  }

  completeWaitingAction(key: string, delay: number = 0): void {
    this.delayedAction(delay).then(() => {
      this.waitList.delete(key);

      const configIndex = this.waitingActions.findIndex(x => x.waitForKey === key);

      if (configIndex === -1) {
        return;
      }

      const config = this.waitingActions[configIndex];

      config.action();

      this.waitingActions.splice(configIndex, 1);
      //console.log('wait list:', this.waitList);
    });
  }

  private delayedAction(delay: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, delay));
  }
}
