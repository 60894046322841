<form [formGroup]="form" (ngSubmit)="submit()" [hidden]="success">
  <div>
    <h5 class="full-width">
      <span>Edit auto recharge info</span>
    </h5>

    <div class="full-width text-left mg-top1rem">
      <mat-checkbox formControlName="enableAutoRecharge" (change)="onChangeEnableAutoRecharge($event)" color="accent">Enable Auto Recharge</mat-checkbox>
    </div>

    <div *ngIf="showAutorechargeInfo">
      <mat-form-field class="full-width text-left">
        <input type="number" matInput placeholder="Recharge amount" formControlName="amount" />
        <mat-error *ngIf="amount.invalid && amount.touched">
          The amount must be higher than €20.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width text-left">
        <input type="number" matInput placeholder="Recharge when credit is lower than" formControlName="autoRechargeCreditLimit" />
        <mat-error *ngIf="autoRechargeCreditLimit.invalid && autoRechargeCreditLimit.touched">
          The auto recharge credit limit can not be empty.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label> Primary Payment Method</mat-label>
        <mat-select [formControl]="primaryMethodId">
          <mat-option *ngFor="let element of paymentMethodsList" [value]="element.id">
            ***{{element.last4}} {{element.brand | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" *ngIf="showDropDown">
        <mat-label> Secondary Payment Method </mat-label>
        <mat-select [formControl]="secondaryMethodId">
          <mat-option *ngFor="let element of paymentMethodsList" [value]="element.id">
            ***{{element.last4}} {{element.brand | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
  <div *ngIf="primaryMethodId.value==secondaryMethodId.value">
    <mat-error>
      Please select different payment methods!
    </mat-error>
  </div>
  <div class="row" cols="2" layout="row">
    <div class="text-center mt-4 float-right">
      <a mat-button class="mr-2" (click)="close()">
        <span class="lni-close small"></span>&nbsp;&nbsp; Cancel
      </a>
      <button mat-raised-button color="accent"
              [disabled]="disableBtn" type="submit">
        <span class="lni-save small"></span>&nbsp;&nbsp; Save
      </button>
    </div>
  </div>

</form>
