import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FilterGridFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      operatorName: [null, Validators.required,],
      operatorValue: [null, Validators.required,],
    });
  }

  getFormControl(property: string): UntypedFormControl {
    return this.form.get(property) as UntypedFormControl;
  }
}
