import { DataSource } from '@angular/cdk/table';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { IRequestTrace } from '../../../core/interfaces/logging/request-trace.interface';

@Component({
  selector: 'app-request-trace-list',
  templateUrl: './request-trace-list.component.html',
  styleUrls: ['./request-trace-list.component.scss']
})
export class RequestTraceListComponent implements OnInit {

  public dataSource: RequestTraceDataSource | null;

  public traceLogs: BehaviorSubject<IRequestTrace[]> = new BehaviorSubject<IRequestTrace[]>([]);

  public displayedColumns = ['createdOnUtc', 'applicationName', 'message'];

  public dataAvailable: boolean = true;

  constructor(public dialogRef: MatDialogRef<RequestTraceListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRequestTrace[] | null) {

    if (this.data == null || this.data.length == 0) {
      this.dataAvailable = false;
      return;
    }

    this.traceLogs.next(data);
  }

  ngOnInit() {
    this.dataSource = new RequestTraceDataSource(this.traceLogs);
  }

  close() {
    this.dialogRef.close();
  }

}

export class RequestTraceDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IRequestTrace[]>) {
    super();
  }

  connect(): Observable<IRequestTrace[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}
