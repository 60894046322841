
<div class="d-flex justify-content-between clipboard-btn">
  <div class="align-self-center">
    {{description}}
  </div>
  <div class="align-self-center">
    <div class="float-right">
      <a mat-raised-button (click)="saveToClipboard()" matTooltip="Copy to clipboard">
        <span class="lni-files mb-1"> </span> &nbsp; Copy
      </a>
    </div>
  </div>
</div>
<ngx-monaco-editor class="template-editor" [options]="editorOptions" [(ngModel)]="code" (onInit)="onEditorInit()"></ngx-monaco-editor>
