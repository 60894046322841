<div class="d-flex justify-content-between">
  <div><h4>Trace logs</h4></div>
  <div class="close-container">
    <div class="close-btn" (click)="close()"><i class="lni-close"></i></div>
  </div>
</div>
<div class="items-table-container">
  <table mat-table [dataSource]="dataSource" *ngIf="dataAvailable">
    <ng-container matColumnDef="createdOnUtc">
      <th mat-header-cell *matHeaderCellDef>UTC Date</th>
      <td mat-cell *matCellDef="let element">
        {{element.createdOnUtc | date:'MMM d, y, h:mm:ss a':"+0000" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="applicationName">
      <th mat-header-cell *matHeaderCellDef>ApplicationName</th>
      <td mat-cell *matCellDef="let element">
        <div class="application-name">{{element.applicationName}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef>Message</th>
      <td mat-cell *matCellDef="let element">
        <div class="trace-message">{{element.message}}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="dataAvailable==false">
    <p class="no-info text-center">No information available!</p>
  </div>
</div>
