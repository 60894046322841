import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
   //selected time 
  @Input() selectedTime: Time;
  @Input() minValue: Time = null;
  @Input() maxValue: Time = null;
  
 //time to be returned 
  @Output() editedTime = new EventEmitter<Time>();

  public timeCtrl: UntypedFormControl = new UntypedFormControl();

  constructor() {
    //console.log(this.selectedTime, this.minValue, this.maxValue)
  }

  ngOnInit() {
    //console.log('time', this.selectedTime);
    this.timeCtrl.setValue(this.selectedTime);
  }

  changeTime($event) {
    this.editedTime.emit($event);
  }
}
