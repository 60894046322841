<h1 mat-dialog-title>Connection settings - {{telephoneNumber.value}}</h1>

<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">

      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <mat-label>User</mat-label>
          <input matInput formControlName="user">
          <mat-error *ngIf="user.invalid && user.touched">
            User is required!
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <mat-label>Auth user</mat-label>
          <input matInput formControlName="authUser">
          <mat-error *ngIf="authUser.invalid && authUser.touched">
            Auth user is required!
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <mat-label>Secret</mat-label>
          <input matInput formControlName="secret">
          <mat-error *ngIf="secret.invalid && secret.touched">
            Secret is required!
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <mat-label>Host</mat-label>
          <input matInput formControlName="host">
          <mat-error *ngIf="host.invalid && host.touched">
            Host is required!
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <mat-label>Port</mat-label>
          <input matInput type="number" formControlName="port">
          <mat-error *ngIf="host.invalid && host.touched">
            Port is required!
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <mat-label>Extension</mat-label>
          <input matInput formControlName="extension">
          <mat-error *ngIf="extension.invalid && extension.touched">
            Extension is required!
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12">
        <mat-form-field class="w-100">
          <mat-label>Additional info</mat-label>
          <input matInput formControlName="additionalInfo">
          <mat-error *ngIf="additionalInfo.invalid && additionalInfo.touched">
            Additional info
          </mat-error>
        </mat-form-field>
      </div>

    </div>
  </form>
</div>

<div mat-dialog-actions>
  <a mat-raised-button (click)="close()">Cancel</a>
  <button mat-raised-button form="ngForm"
          color="accent"
          [disabled]="form.invalid"
          (click)="save()">Save</button>
</div>
