<mat-tab-group (selectedTabChange)="onTabChange($event)" mat-stretch-tabs>
  <mat-tab label="General">
    <ng-container *ngIf="isAdmin; else user">
      <ng-content></ng-content>
    </ng-container>
    <ng-template #user>
      <app-general-activity></app-general-activity>
    </ng-template>
  </mat-tab>

  <mat-tab label="Detailed">
    <app-detailed-activity [requestCustomerCode]="customerCode"
                           [isAdmin]="isAdmin"
                           (openDialog)="openSideDialog($event)">
    </app-detailed-activity>
  </mat-tab>
</mat-tab-group>

<mat-drawer-container class="sidenav-container" autosize>
  <mat-drawer class="p-4 drawer-container" #drawer mode="side" position="end">
    <div class="d-flex justify-content-between align-items-center">
      <ng-container *ngIf="data.templateName != null; else type">
        <div><b>Template name</b>: {{data.templateName}}</div>
      </ng-container>

      <ng-template #type>
        <div><b>Message type</b>: {{data.messageType}}</div>
      </ng-template>

      <div class="close-btn" (click)="closeSideDialog()"><i class='lni lni-close'></i></div>
    </div>
    <div><b>RequestId</b>: {{data.id}}</div>

    <div class="d-flex mt-4 justify-content-center align-items-center">
      <app-phone-viewer *ngIf="isDeviceVisible" [currentOption]="data.template"></app-phone-viewer>
    </div>
  </mat-drawer>
</mat-drawer-container>

