import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-items-confirmation',
  templateUrl: './delete-items-confirmation.component.html',
  styleUrls: ['./delete-items-confirmation.component.scss']
})
export class DeleteItemsConfirmationComponent implements OnInit {

  public dialogMessage: string;

  constructor(public dialogRef: MatDialogRef<DeleteItemsConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | null) {

    if (this.data == null) {
      return;
    }
    this.dialogMessage = data;
  }

  ngOnInit() {
  }

  delete() {

    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
