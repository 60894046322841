
<div *ngIf="id!=null">
  <h3>Storage details</h3>

  <div *ngIf="callDirection!='Autopilot'">
    <div><b>RequestId: </b>{{id}}</div>
    <div><b>Telephone Number: </b>{{telephoneNumber}}</div>
    <div *ngIf="callDirection=='Inbound' && did!=null"><b>DID: </b>{{did}}</div>
  </div>
  <div *ngIf="callDirection=='Autopilot' && request !=null">
    <div><b>Session id: </b>{{request.sessionId}}</div>
    <div><b>Bot name: </b>{{request.botName}}</div>
  </div>
</div>


<div class="items-table-container">
  <table mat-table [dataSource]="dataSource" class="full-width">

    <ng-container matColumnDef="createdOnUtc">
      <th mat-header-cell *matHeaderCellDef>Created Date</th>
      <td mat-cell *matCellDef="let element">
        {{element.createdOnUtc | date:'d/MM/yyyy' }} <br />
        {{timeZoneId=='UTC'? (element.createdOnUtc | date:'h:mm:ss a':"+0000"):(element.createdOnUtc | date:'h:mm:ss a') }}
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef>Key</th>
      <td mat-cell *matCellDef="let element">{{element.key}}</td>

    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.value!=null&& element.value.length>30">{{element.value | slice :0:30}} <span matTooltip="{{element.value}}">...</span></span>
        <span *ngIf="element.value==null|| element.value.length<=30">{{element.value}}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>
