import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IActivityRequest } from '../../interfaces/common/activity-request.interface';
import { CommonRequestParams } from '../../interfaces/common/common-request-params';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { ISelectListItem } from '../../interfaces/data/select-list-item';
import { IInboundActiveNumber } from '../../interfaces/inbound/inbound-active-number.interface';
import { IWhatsappSelectTemplate } from '../../interfaces/whatsapp/whatsapp-provider.interface';
import { IWhatsappTemplateCategory } from '../../interfaces/whatsapp/whatsapp-template-category.interface';
import { IWhatsappTemplate } from '../../interfaces/whatsapp/whatsapp-template.interface';

@Injectable()
export class WhatsappService {

  private _baseUrl: string = `${ApiBaseUrl}whatsapp`;

  constructor(private httpClient: HttpClient) { }

  getTemplates(request: CommonRequestParams, status, name, category): Observable<DataSourceResponse<IWhatsappTemplate>> {
    const params = new HttpParams().set("status", status).set("category", category).set("name", name);
    return this.httpClient.post<DataSourceResponse<IWhatsappTemplate>>(`${this._baseUrl}/templates`, request, {params:params});
  }

  getCategories(): Observable<IWhatsappTemplateCategory[]> {
    return this.httpClient.get<IWhatsappTemplateCategory[]>(`${this._baseUrl}/categories`);
  }

  getLanguages(): Observable<ISelectListItem[]> {
    return this.httpClient.get<ISelectListItem[]>(`${this._baseUrl}/languages`);
  }

  getNumbers(customerCode=""): Observable<IInboundActiveNumber[]> {
    return this.httpClient.get<IInboundActiveNumber[]>(`${this._baseUrl}/numbers/${customerCode}`);
  }

  getMaxVariablesNumber(): Observable<number> {
    return this.httpClient.get<number>(`${this._baseUrl}/settings-variables`);
  }

  saveTemplate(template: IWhatsappTemplate):Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/save`, template);
  }

  deleteTemplate(template: IWhatsappTemplate): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, template);
  }

  getActivity(request: IActivityRequest): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/activity-aggregate`, request);
  }

  getSharedNumberActivity(request: IActivityRequest, numberId:any): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/activity-aggregate/${numberId}`, request);
  }

  getWhatsappTemplates(phoneNumber): Observable<IWhatsappSelectTemplate[]> {
    return this.httpClient.get<IWhatsappSelectTemplate[]>(`${this._baseUrl}/campaignTemplates/${phoneNumber}`);
  }

  getWhatsappTemplatesByNumber(numberWBAId: string): Observable<DataSourceResponse<IWhatsappTemplate>> {
    return this.httpClient.get<any>(`${this._baseUrl}/numberTemplates/${numberWBAId}`);
  }

  getWhatsappTemplate(id?: any): Observable<DataSourceResponse<IWhatsappSelectTemplate>> {
    return this.httpClient.get<any>(`${this._baseUrl}/selectTemplates/${id}`);
  }

  //-----------upload------------------
  uploadFile(data: any) {
    return this.httpClient.post<any>(`${this._baseUrl}/file-upload`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;

          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }
}
