
<span class="width-100px"><b>Sender: </b></span>
<span>  {{sendAction.sender}}</span> <br />
<span class="width-100px"><b>Telephone:</b> </span>
<span>{{sendAction.telephoneNumber}}</span>
<hr />
<div class="mb-2"><b>Message: </b> <br />
  {{sendAction.message}}
  </div>
<div mat-dialog-actions class="align-right-btn">
  <button mat-raised-button (click)="cancel()">Close</button>
</div>
