import { BehaviorSubject, Observable } from "rxjs";
import { IInboundVoicePricelistItem } from "../interfaces/inbound/inbound-voice-pricelist-item.interface";
import { DataSource } from "@angular/cdk/table";

export class InboundVoicePricelistDataSource extends DataSource<any> {
  constructor(private subject: BehaviorSubject<IInboundVoicePricelistItem[]>) {
    super();
  }

  connect(): Observable<IInboundVoicePricelistItem[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}
