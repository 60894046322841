
<div class="full-width">
  <p class="campaign-title"> Status callback requests </p>
  <div> Url: <b>{{data.statusCallbackUrl}}</b></div>

  <small class="primary-color font-italic font-bold">
    *The requests are permanently deleted after 5 days.
  </small>
  <mat-grid-list cols="4" rowHeight="4.5rem" gutterSize="2rem" layout-fill class="mt-3">
    
    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width custom-mat-select">
        <mat-label>Select status</mat-label>
        <mat-select [formControl]="status">
          <mat-option *ngFor="let status of callbackStatusList" [value]="status.value">
            {{status.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<div class="mt-4" [hidden]="!loading">
  <div class="progress-spinner-container">
    <div class="progress-spinner-content">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="35"></mat-progress-spinner>
    </div>
  </div>
</div>

<mat-card class="mat-card-items mt-3">

  <div *ngIf="noItems" class="undefined-list mt-4">
    No data available.
  </div>

  <mat-card-content [hidden]="noItems || loading">
    <div class="items-table-container">
      <table mat-table [dataSource]="dataSource" class="full-width">
        
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            <span>UTC Date</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span>{{element.createdOnUtc | date:'dd/MM/yyy'}}</span><br />
              {{element.createdOnUtc | date:'HH:mm:ss':"+0000"}}
            </div>

          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <span>Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.httpStatusCode}}
          </td>
        </ng-container>

        <ng-container matColumnDef="error">
          <th mat-header-cell *matHeaderCellDef>
            <span>Error</span>
          </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.errorMessage!=null ? 'error-column':''">
            {{element.errorMessage}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true "></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator [length]="totalItems"
                   [pageSize]="pageSize"
                   (page)="handlePage($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
