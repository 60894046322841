<div *ngIf="autoRechargeInfo!=null">
  <h1 class="mb-5">
    Recharge
  </h1>

  <div class="row">
    <div class="col-md-10">
      <mat-card>
        <div class="row">
          <div class="col-md-4">
            <h3 class="mb-3">Current Balance</h3>
            <h4 class="mb-3 primary-color">{{currentCredit}} €</h4>
            <div class="recharge-btn">
              <button mat-raised-button color="accent" class="align-content-center" (click)="openPayment()">
                Add Credits
              </button>
            </div>
          </div>
          <div class="col-md-8" *ngIf="autoRechargeInfo!=null">
            <h3 class="mb-3">Auto Recharge</h3>

            <!--<div *ngIf="autoRechargeInfo.isAutoRechargeEnable">
              <p class="mb-0"><mat-checkbox color="accent" [(ngModel)]="autoRechargeInfo.isAutoRechargeEnable" [disabled]="true">Enable Auto Recharge</mat-checkbox></p>
              <p> Amount:&nbsp;&nbsp;<span><i class="lni-euro"></i>{{autoRechargeInfo.rechargeAmount}} </span></p>
              <p> Auto recharge credit limit:&nbsp;&nbsp;<span>{{autoRechargeInfo.autoRechargeCreditLimit}} </span></p>
              <div *ngIf="primaryPaymentMethod!=null">
                <p>Primary Payment Method</p>
                <p class=" small mb-3"><mat-card class="width-30"> <div> *** {{primaryPaymentMethod.last4}} {{primaryPaymentMethod.brand | titlecase}} </div></mat-card></p>
              </div>
              <div *ngIf="secondaryPaymentMethod!=null">
                <p>Secondary Payment Method</p>
                <p class=" small mb-3"><mat-card class="width-30"> <div> *** {{secondaryPaymentMethod.last4}} {{secondaryPaymentMethod.brand | titlecase}} </div></mat-card></p>
              </div>
            </div>
            <button mat-raised-button color="accent" class="mt-4" (click)="loadPaymentMethods()">
              <span class="lni-pencil-alt"></span>&nbsp;&nbsp;
              <span *ngIf="autoRechargeInfo.isAutoRechargeEnable">Edit information</span>
              <span *ngIf="!autoRechargeInfo.isAutoRechargeEnable">Set Auto Recharge</span>
            </button>
            <p class="primary-color" *ngIf="noPaymentMethod">First add a payment method !</p>-->

            <form [formGroup]="form" (ngSubmit)="submit(form.value)">
              <div>
                <div class="full-width text-left mg-top1rem mg-btm1rem">
                  <mat-checkbox formControlName="enableAutoRecharge" (change)="onChangeEnableAutoRecharge($event)" color="accent">Enable Auto Recharge</mat-checkbox>
                </div>

                <div *ngIf="showAutorechargeInfo" class="row">
                  <div class="col-md-6">
                    <mat-form-field class="full-width text-left mb-4">
                      <mat-label>Recharge amount</mat-label>
                      <input type="number" matInput placeholder=""
                             formControlName="amount"
                             #tooltip="matTooltip"
                             matTooltip="Minimum recharge {{minAmount}}€"
                             (keydown)="tooltip.show()" />
                      <mat-error *ngIf="amount.invalid && amount.touched">
                        The amount must be higher than {{minAmount}}€.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width text-left">
                      <mat-label>Recharge automatically when credit is lower than</mat-label>
                      <input type="number" matInput placeholder="" formControlName="autoRechargeCreditLimit" />
                      <mat-error *ngIf="autoRechargeCreditLimit.invalid && autoRechargeCreditLimit.touched">
                        The auto recharge credit limit can not be empty.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="full-width custom-mat-select">
                      <mat-label> Primary Payment Method</mat-label>
                      <mat-select [formControl]="primaryMethodId">
                        <mat-option *ngFor="let element of primaryMethods" [value]="element.id">
                          ***{{element.last4}} {{element.brand | titlecase}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width custom-mat-select secondary-payment">
                      <mat-label> Secondary Payment Method </mat-label>
                      <mat-select [formControl]="secondaryMethodId">
                        <mat-option *ngFor="let element of secondaryMethods" [value]="element.id">
                          ***{{element.last4}} {{element.brand | titlecase}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                </div>

              </div>
              <div *ngIf="primaryMethodId.value==secondaryMethodId.value && primaryMethodId.value!=null">
                <mat-error>
                  Please select different payment methods!
                </mat-error>
              </div>
              <div>
                <div class="mt-4">
                  <button mat-raised-button color="accent"
                          [disabled]="disableBtn || form.dirty==false" type="submit">
                    <span class="lni-save small"></span>&nbsp;&nbsp; Save
                  </button>
                </div>
              </div>

            </form>
          </div>

        </div>

      </mat-card>
    </div>
  </div>
</div>



