import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { IRequestOffer } from '../../../../core/interfaces/agent-conference/request-offer.interface';

@Injectable()
export class RequestOfferFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      name: [null, [
        Validators.required]],
      number: [null, [
        Validators.required]],
      email: [null, [Validators.required,
        Validators.pattern("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,10})$")]],
      agentsPhoneMonthly: [0, [
        Validators.required]],
      agentsPhoneAnnually: [0, Validators.required],
      agentsConference: [0, Validators.required],
      agentsLiveAnnually: [0],
      agentsLiveMonthly: [0],
      agentsRemoteMonthly: [0],
      agentsRemoteAnnually: [0],
      message:[null, Validators.required]
    })
  }

  getFormControl(property: string): UntypedFormControl {
    return this.form.get(property) as UntypedFormControl;
  }

  createRequestOffer(): IRequestOffer {
    var data = this.form.value;

    const request: IRequestOffer = {
      name: data.name,
      email: data.email,
      phoneNumber: data.number,
      message: data.message,
      numberOfAgentsConference: data.agentsConference,
      numberOfAgentsPhoneAnnually: data.agentsPhoneAnnually,
      numberOfAgentsPhoneMonthly: data.agentsPhoneMonthly,
      numberOfAgentsLiveAnnually: data.agentsLiveAnnually,
      numberOfAgentsLiveMonthly: data.agentsLiveMonthly,
      numberOfAgentsRemoteMonthly: data.agentsRemoteMonthly,
      numberOfAgentsRemoteAnnually: data.agentsRemoteAnnually,
    };

    return request;
  }
}
