import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable } from "rxjs";
import { IRequestStatusCallbackLog } from "../interfaces/common/request-status-callback-log.interface";


export class RequestStatusCallbackLogDatasource extends DataSource<any> {
  constructor(private subject: BehaviorSubject<IRequestStatusCallbackLog[]>) {
    super();
  }

  connect(): Observable<IRequestStatusCallbackLog[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}


