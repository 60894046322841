<mat-card *ngIf="!isAdmin" class="shadow">
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="search()">
      <mat-grid-list cols="12" rowHeight="4.5rem" gutterSize="2rem" layout-fill>
        <mat-grid-tile *ngIf="!narrowResults" colspan="2">
          <mat-form-field class="full-width custom-select-padding">
            <mat-label>Start date</mat-label>
            <input matInput [matDatepicker]="pickerStart"
                   (dateChange)="setMinEndDate($event)"
                   formControlName="startDate"
                   [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart
                            (monthSelected)="startMonthHandler($event, pickerStart)">
            </mat-datepicker>
            <mat-error *ngIf="startDate.invalid && startDate.touched">
              The start date is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="!narrowResults" colspan="2">
          <mat-form-field class="full-width custom-select-padding">
            <mat-label>End date</mat-label>
            <input matInput [matDatepicker]="pickerEnd"
                   [min]="minEndDate"
                   [max]="maxDate"
                   formControlName="endDate" />
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd
                            (monthSelected)="endMonthHandler($event, pickerEnd)"></mat-datepicker>
            <mat-error *ngIf="endDate.invalid && endDate.touched">
              The end date is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="4" *ngIf="narrowResults">
          <mat-form-field class="search-field full-width">
            <input matInput
                   type="text"
                   placeholder="Search by guid..."
                   formControlName="guid"
                   autocomplete="off">
            <button type="button" (click)="guid.setValue('')" matSuffix mat-icon-button aria-label="Clear">
              <b class="lni-close small"></b>
            </button>
            <mat-error *ngIf="guid.invalid && guid.touched">
              The guid is invalid
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
          <mat-form-field class="full-width custom-select-padding">
            <mat-label>Direction</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let item of typeArray" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [hidden]="type.value == 'Inbound'" colspan="2">
          <mat-form-field class="full-width custom-select-padding">
            <mat-label>Status type</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let item of statusArray" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
          <div class="text-center mb-3 search-btn">
            <button mat-raised-button color="accent" [disabled]="form.invalid" type="submit">
              Search
            </button>
          </div>
        </mat-grid-tile>

        <mat-grid-tile colspan="{{tabletMode  ? '4': '2'}}">
          <button mat-button color="primary"
                  (click)="showFilterArea()" class="mb-3"
                  type="button"
                  [disabled]="loading">
            <span [ngClass]='[iconClass]'></span>&nbsp;{{filterBtn}}
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </form>

  </mat-card-content>
</mat-card>

<div class="mt-4" [hidden]="!loading">
  <div class="progress-spinner-container">
    <div class="progress-spinner-content">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="75"></mat-progress-spinner>
    </div>
  </div>
</div>
<div *ngIf="noItems && filterArray.length==0" class="undefined-list mt-4">
  No data available.
</div>

<mat-card class="mat-card-items shadow" [ngClass]="tabletMode? 'mt-3':'mt-5'" [hidden]="!showMatCard && filterArray.length==0">
  <div class="row">
    <div class="col-md-4"><b class="ml-4">Total requests:</b>&nbsp;&nbsp;{{totalItems}}</div>
    <div class="col-md-4"></div>
    <div class="col-md-4 export-btn">
      <button mat-stroked-button color="primary" (click)="exportToExcel()" [disabled]="loading" class="mr-3 mb-2">
        <i class="lni-download"></i>&nbsp;&nbsp;Export to Excel
      </button>
    </div>
  </div>
  <mat-card-content>
    <div class="items-table-container" [style.max-height.px]="height">
      <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSourceOutbound" class="full-width" *ngIf="type.value=='Outbound'">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Request Id</span>
            <span *ngIf="tabletMode">Request Id</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'id'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">

            <span *ngIf="!tabletMode">{{element.id}}</span>

            <button *ngIf="tabletMode" mat-button color="accent" (click)="copyRequestIdToClipboard(element)">
              <span class="lni-clipboard"></span>
              &nbsp; Copy
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="Sender">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Sender</span>
            <span *ngIf="tabletMode">Sender</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Sender'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">{{element.sender}}</td>
        </ng-container>

        <ng-container matColumnDef="TelephoneNumber">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Telephone <br /> Number</span>
            <span *ngIf="tabletMode">Telephone <br /> Number</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'TelephoneNumber'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.isAnonymized==null || element.isAnonymized==false">{{element.telephoneNumber}}</span>
            <span *ngIf="element.isAnonymized == true">[anonymized]</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Timestamp">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Created on</span>
            <span *ngIf="tabletMode">Created on</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Timestamp'"
                             [propertyType]="'Timestamp'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.createdOnUtc | date:'d/MM/yyyy' }} <br />
            {{timeZoneId=='UTC'? (element.createdOnUtc | date:'h:mm:ss a':"+0000"):(element.createdOnUtc | date:'h:mm:ss a') }}
          </td>
        </ng-container>

        <ng-container matColumnDef="SentOn">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="!tabletMode">Sent on</span>
            <span *ngIf="tabletMode">Sent on</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.sentOnUtc != null; else notSent">
              {{element.sentOnUtc | date:'d/MM/yyyy' }} <br />
              {{timeZoneId=='UTC'? (element.sentOnUtc | date:'h:mm:ss a':"+0000"):(element.sentOnUtc | date:'h:mm:ss a') }}
            </ng-container>
            <ng-template #notSent>
              -
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Status</span>
            <span *ngIf="tabletMode">Status</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Status'"
                             [propertyType]="'String'"
                             [isExpression]="true"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="status" [ngClass]="{'failed': element.status.primaryStatus.code==400,
                  'success' : element.status.primaryStatus.code==200,
                  'pending' : element.status.primaryStatus.code==100 }" matTooltip="{{element.status.primaryStatus.description}}">
              {{element.status.statusCode}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="messageType">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="!tabletMode">Message type</span>
            <span *ngIf="tabletMode">Message<br />type</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.messageType}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="openSideDialog(element)" matTooltip="View Message">
              <span class="lni-eye"></span>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsOutbound sticky: true "></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsOutbound;"></tr>
      </table>

      <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSourceInbound" class="full-width" *ngIf="type.value=='Inbound'">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Request Id</span>
            <span *ngIf="tabletMode">Request Id</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'id'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">

            <span *ngIf="!tabletMode">{{element.id}}</span>

            <button *ngIf="tabletMode" mat-button color="accent" (click)="copyRequestIdToClipboard(element)">
              <span class="lni-clipboard"></span>
              &nbsp; Copy
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="InboundActiveNumber">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">DID number</span>
            <span *ngIf="tabletMode">Sender</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'InboundActiveNumber'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">{{element.inboundActiveNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="Source">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Client phone number</span>
            <span *ngIf="tabletMode">Phone<br />number</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Source'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">{{element.source}}</td>
        </ng-container>

        <ng-container matColumnDef="Timestamp">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">Created on</span>
            <span *ngIf="tabletMode">Created on</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Timestamp'"
                             [propertyType]="'Timestamp'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.createdOnUtc | date:'d/MM/yyyy' }} <br />
            {{timeZoneId=='UTC'? (element.createdOnUtc | date:'h:mm:ss a':"+0000"):(element.createdOnUtc | date:'h:mm:ss a') }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ReceivedOnUtcTimestamp">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="!tabletMode">Received on</span>
            <span *ngIf="tabletMode">Received on</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.receivedOnUtc | date:'d/MM/yyyy' }} <br />
            {{timeZoneId=='UTC'? (element.receivedOnUtc | date:'h:mm:ss a':"+0000"):(element.receivedOnUtc | date:'h:mm:ss a') }}
          </td>
        </ng-container>

        <ng-container matColumnDef="messageType">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="!tabletMode">Message type</span>
            <span *ngIf="tabletMode">Message<br />type</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.messageType}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="openSideDialog(element)" matTooltip="View Message">
              <span class="lni-eye"></span>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsInbound sticky: true "></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsInbound;"></tr>
      </table>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator [length]="totalItems"
                   [pageSize]="pageSize"
                   (page)="handlePage($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>




