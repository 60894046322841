import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/common.constants';
import { IActivityRequest } from '../../interfaces/common/activity-request.interface';
import { IExportFileRequestParams } from '../../interfaces/common/export-file-request-params.interface';
import { IResponseCall } from '../../interfaces/common/response-call.interface';
import { IWhatsappSelectTemplate } from '../../interfaces/whatsapp/whatsapp-provider.interface';

@Injectable()
export class WhatsappStatisticsService {

  private _baseUrl: string = `${ApiBaseUrl}whatsappstatistics`;

  constructor(private httpClient: HttpClient) { }

  getStatisticsActivity(request: IActivityRequest, isInbound: boolean) {
    return this.httpClient.post<any>(`${this._baseUrl}/detailed-statistics/${isInbound}`, request);
  }

  getWhatsappTemplate(request: IActivityRequest, templateId: string): Observable<IWhatsappSelectTemplate> {
    return this.httpClient.post<any>(`${this._baseUrl}/template/${templateId}`, request);
  }

  exportDetailedActivity(request: IExportFileRequestParams, isInbound: boolean, userCustomerCode: string): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/export/${isInbound}/${userCustomerCode}`, request);
  }
}
