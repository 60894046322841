<h1 class="mb-3">
  Payment Methods
</h1>

<div class="row">
  <div [ngClass]="tabletMode ? 'col-md-10' : 'col-md-8'">
    <p class="mb-5">Dillo doesn't store your card informations, instead we rely on Stripe to process your payment securely.</p>

    <table mat-table [dataSource]="dataSource" class="full-width mb-4">
      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef>Issuer</th>
        <td mat-cell *matCellDef="let element"> {{element.brand | titlecase }} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element"> {{element.type | titlecase }} </td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let element">**** {{element.last4}}</td>
      </ng-container>

      <ng-container matColumnDef="expireMonth">
        <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
        <td mat-cell *matCellDef="let element">{{element.expirationMonth}} / {{element.expirationYear}}</td>
      </ng-container>

      <ng-container matColumnDef="isDefault">
        <th mat-header-cell *matHeaderCellDef>Is Default</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox color="accent" [(ngModel)]="element.isDefault" [disabled]="true"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <a mat-icon-button (click)="openEditPaymentMethod(element)" matTooltip="Edit">
            <span class="lni-pencil-alt"></span>
          </a>
          <a mat-icon-button (click)="confirmDelete(element)" matTooltip="Delete">
            <span class="lni-trash"></span>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>


<a mat-raised-button color="accent" (click)="openEditPaymentMethod(null)">
  <span class="lni-plus"></span> &nbsp; Add a Payment Method
</a>
