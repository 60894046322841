import { Component, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-set-default-payment-method',
  templateUrl: './set-default-payment-method.component.html',
  styleUrls: ['./set-default-payment-method.component.scss']
})
export class SetDefaultPaymentMethodComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SetDefaultPaymentMethodComponent>) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close({ default: false })
  }

  setDefault() {
    this.dialogRef.close({ default: true })
  }
}
