import { Injectable } from '@angular/core';
import { ICustomerAutoRechargeInfo } from '../../../core/interfaces/customer/customer-auto-recharge-info';
import { RechargeEditFormService } from './recharge-edit-form.service';

@Injectable()
export class RechargeEditLoaderService {

  constructor(private formService: RechargeEditFormService) { }

  loadAutoRechargeInfo(autoRechargeInfo: ICustomerAutoRechargeInfo) {
    this.formService.form.patchValue({
      primaryMethodId: autoRechargeInfo.primaryPaymentMethodId,
      secondaryMethodId: autoRechargeInfo.secondaryPaymentMethodId,
      enableAutoRecharge: autoRechargeInfo.isAutoRechargeEnable,
      amount: autoRechargeInfo.rechargeAmount > 0 ? autoRechargeInfo.rechargeAmount: null ,
      autoRechargeCreditLimit: autoRechargeInfo.autoRechargeCreditLimit > 0 ? autoRechargeInfo.autoRechargeCreditLimit:null
    });
  }
}
