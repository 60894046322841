<mat-form-field class="full-width"  *ngIf="maxValue==null && minValue!=null">
  <input matInput [formControl]="timeCtrl" [ngxTimepicker]="min" aria-label="24hr format" [format]="24"  [min]="minValue" readonly>
  <ngx-material-timepicker #min (timeSet)="changeTime($event)" #timepicker></ngx-material-timepicker>
</mat-form-field>

<mat-form-field class="full-width" *ngIf="minValue==null && maxValue!=null">
  <input matInput [formControl]="timeCtrl" [ngxTimepicker]="max" aria-label="24hr format" [format]="24" [max]="maxValue" readonly>
  <ngx-material-timepicker #max (timeSet)="changeTime($event)" #timepicker></ngx-material-timepicker>
</mat-form-field>

<mat-form-field class="full-width" *ngIf="maxValue==null && minValue==null">
  <input matInput [formControl]="timeCtrl" [ngxTimepicker]="min" aria-label="24hr format" [format]="24" [min]="minValue" readonly>
  <ngx-material-timepicker #min (timeSet)="changeTime($event)" #timepicker></ngx-material-timepicker>
</mat-form-field>
