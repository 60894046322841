import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentBillingType, AgentType } from '../../../../core/constants/agent-type';
import { ICustomer } from '../../../../core/interfaces/customer/customer';
import { CustomerService } from '../../../../core/services/customers/customer.service';
import { ExtraServicesPricelistService } from '../../../../core/services/voice/extra-services-pricelist.service';
import { RequestOfferFormService } from './request-offer-form.service';

@Component({
  selector: 'app-request-agent-offer',
  templateUrl: './request-agent-offer.component.html',
  styleUrls: ['./request-agent-offer.component.scss'],
  providers: [RequestOfferFormService, CustomerService]
})
export class RequestAgentOfferComponent implements OnInit {

  public billingTypes = [AgentBillingType.Monthly, AgentBillingType.Annually];
  public agentTypes = [AgentType.Phone, AgentType.Conference];
  public error: boolean = false;
  public invalid: boolean = false;
  private customer: ICustomer;

  constructor(private dialogRef: MatDialogRef<RequestAgentOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null,
    private formService: RequestOfferFormService,
    private extraServicePricelistService: ExtraServicesPricelistService,
  private customerService:CustomerService) { }

  ngOnInit() {
    this.customerService.getCustomerFromCache().subscribe(x => {
      this.customer = x;
      if (x != null) {
        this.email.setValue(x.email);
      }
    })
  }

  get form() {
   return this.formService.form;
  }

  get name() {
    return this.formService.getFormControl("name");
  }

  get email() {
    return this.formService.getFormControl("email");
  }

  get number() {
    return this.formService.getFormControl("number");
  }

  get agentsPhoneMonthly() {
    return this.formService.getFormControl("agentsPhoneMonthly");
  }

  get agentsPhoneAnnually() {
    return this.formService.getFormControl("agentsPhoneAnnually");
  }

  get agentsConference() {
    return this.formService.getFormControl("agentsConference");
  }

  get message() {
    return this.formService.getFormControl("message");
  }

  get agentsLiveMonthly() {
    return this.formService.getFormControl("agentsLiveMonthly");
  }

  get agentsLiveAnnually() {
    return this.formService.getFormControl("agentsLiveAnnually");
  }

  get agentsRemoteMonthly() {
    return this.formService.getFormControl("agentsRemoteMonthly");
  }

  get agentsRemoteAnnually() {
    return this.formService.getFormControl("agentsRemoteAnnually");
  }

  submit() {
    this.error = false;
    this.invalid = false;

    let request = this.formService.createRequestOffer();

    if (request.numberOfAgentsPhoneMonthly == 0 && request.numberOfAgentsPhoneAnnually == 0 &&
      request.numberOfAgentsConference == 0) {
      this.invalid = true;
      return;
    }

    this.extraServicePricelistService.requestAgentOffer(request).subscribe(x => {
      if (x) {
        this.dialogRef.close(true)
        return;
      }
      this.error = true;
      
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
