import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/common.constants';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IInboundVoicePricelistItem } from '../../interfaces/inbound/inbound-voice-pricelist-item.interface';
import { DataSourceResponse } from '../../interfaces/data/datasourceresponse.interface';
import { IInboundVoicePricelistResponse } from '../../interfaces/inbound/inbound-voice-pricelist-response.interface';
import { IVoiceInboundPriceListItemsRequest } from '../../interfaces/common/pricelist-items-request.interface';

@Injectable({
  providedIn: 'root'
})
export class InboundVoicePricelistService {

  private _baseUrl: string = `${ApiBaseUrl}inboundvoicepricelist`;

  constructor(private httpClient: HttpClient) { }

  

  uploadFile(billingType: string, data: any) {
    return this.httpClient.post<any>(`${this._baseUrl}/upload/${billingType}`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }

  getPriceListItems(request: IVoiceInboundPriceListItemsRequest): Observable<DataSourceResponse<IInboundVoicePricelistItem>> {
    return this.httpClient.post<DataSourceResponse<IInboundVoicePricelistItem>>(`${this._baseUrl}/all/`, request);
  }

  createPriceListItem(pricelistItem: IInboundVoicePricelistItem): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/create`, pricelistItem);
  }

  deletePriceListItem(pricelistItem: IInboundVoicePricelistItem): Observable<number> {
    return this.httpClient.post<number>(`${this._baseUrl}/delete`, pricelistItem);
  }

  updatePriceListItem(pricelistItem: IInboundVoicePricelistItem): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, pricelistItem);
  }

  getPriceListItem(id: any, billingType: string): Observable<IInboundVoicePricelistItem> {
    return this.httpClient.get<IInboundVoicePricelistItem>(`${this._baseUrl}/route/${id}/${billingType}`);
  }

  getTotalItems(): Observable<IInboundVoicePricelistResponse[]> {
    return this.httpClient.get<IInboundVoicePricelistResponse[]>(`${this._baseUrl}/totalItems`);
  }

  getInboundBasePrices(request: IVoiceInboundPriceListItemsRequest): Observable<DataSourceResponse<IInboundVoicePricelistItem>> {
    return this.httpClient.post<DataSourceResponse<IInboundVoicePricelistItem>>(`${this._baseUrl}/userPrices`, request);
  }

  getRouteBillingTypes(code:any): Observable<IInboundVoicePricelistResponse[]> {
    return this.httpClient.get<IInboundVoicePricelistResponse[]>(`${this._baseUrl}/routeBillingTypes/${code}`);
  }
}
