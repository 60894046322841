<div [style.width.px]="width"
     [style.maxWidth.px]="maxWidth">

  <div class="phone-container d-flex justify-content-center align-items-center">
    <div [style.maxHeight.px]="maxHeight" class="marvel-device nexus5 device-width">
      <div class="top-bar"></div>
      <div class="screen">
        <div class="screen-container position-relative">

          <div *ngIf="isBackdropVisible" (click)="removeBackdrop()" class="contacts-backdrop"></div>
          <div *ngIf="isContactsModalVisible" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="contacts-modal p-2">
              <ng-container *ngIf="isInviteType; else contactType">
                <div class="contacts-message">Invite {{currentContact.name.formattedName}} via</div>

                <div class="contacts-message mt-2">
                  <ul style="list-style-type: none; padding: 0; margin: 0;">
                    <li *ngFor="let phoneObject of currentContact.phones">
                      <span>{{phoneObject.phone}}</span>
                    </li>
                  </ul>
                </div>

                <div class="mt-2 mr-2 d-flex justify-content-start align-items-center">
                  <ul style="list-style-type: none; padding: 0; margin: 0;">
                    <li *ngFor="let emailObject of currentContact.emails">
                      <span>{{emailObject.email}}</span>
                    </li>
                  </ul>
                </div>
              </ng-container>
              <ng-template #contactType>
                <div class="contacts-message">Create a new contact or add to an existing contact?</div>

                <div class="mt-2 mr-2 d-flex justify-content-end align-items-center">
                  <span class="contacts-create-btn p-1">Existing</span>
                  <span class="contacts-create-btn p-1">New</span>
                </div>
              </ng-template>
            </div>
          </div>

          <div *ngIf="isBottomSheetVisible" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-end">
            <div class="bottom-sheet p-2">
              <div class="d-flex justify-content-center align-content-center">
                <div class="topper"></div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="interactive-title mt-1">
                  <b>{{currentOption.whatsappMessageInteractiveContent.content.title}}</b>
                </div>
              </div>
              <div class="interactive-x">
                <span (click)="removeBackdrop()">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#434343"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
                </span>
              </div>

              <div class="interactive-sections mt-2">
                <div *ngFor="let section of currentOption.whatsappMessageInteractiveContent.content.sections">
                  <div class="mx-2 mb-2">
                    <div class="i-sec-title mb-2">{{section.title}}</div>
                    <div *ngFor="let row of section.rows">
                      <div class="row-sec position-relative mb-1">
                        <div>{{row.title}}</div>
                        <div class="row-description">{{row.description}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-message">Tab to select an item</div>
            </div>
          </div>

          <div class="chat">
            <div class="chat-container">
              <div class="user-bar">
                <div class="back">
                  <i class="bi bi-arrow-left"></i>
                </div>
                <div class="avatar">
                  <img src="https://console.dillo.cloud/images/dillo-icon.png" alt="Avatar">
                </div>
                <div class="name">
                  <span>Dillo</span>
                  <span class="status">online</span>
                </div>
                <div class="actions more">
                  <i class="bi bi-more-vert"></i>
                </div>
                <div class="actions attachment">
                  <i class="bi bi-attachment-alt"></i>
                </div>
                <div class="actions">
                  <i class="bi bi-phone"></i>
                </div>
              </div>
              <div class="conversation">
                <div class="conversation-container">
                  <div *ngIf="currentOption != null" class="message received"
                       [ngClass]="{'on-wide-style': currentOption.whatsappMessageContactsContent != null
                       || currentOption.whatsappMessageInteractiveContent != null}">

                    <div *ngIf="currentOption.components.header != null" class="text-container">
                      <div *ngIf="currentOption.components.header.type=='Text'"> <b>{{currentOption.components.header.text}}</b></div>
                      <div class="my-1" *ngIf="currentOption.components.header.type=='Image'"><img src="{{currentOption.components.header.example}}" alt="header-img" class="w-100 h-100" /></div>
                      <div class="my-1" *ngIf="currentOption.components.header.type=='Sticker'"><img src="{{currentOption.components.header.example}}" alt="header-img" class="w-100 h-100" /></div>
                      <div class="video-cc" *ngIf="currentOption.components.header.type=='Video'">
                        <video controls #videoPlayer class="w-100 h-100">
                          <source [src]="currentOption.components.header.example" type="video/mp4" />
                          Browser not supported
                        </video>
                      </div>
                      <div *ngIf="currentOption.components.header.type=='Location'"><img src="https://netdoctrinegac57.blob.core.windows.net/whatsapp-files-dev/share-location.png" alt="header-img" /></div>
                      <div *ngIf="currentOption.components.header.type=='Document'"><img src="https://netdoctrinegac57.blob.core.windows.net/whatsapp-files-dev/document-example.png" alt="header-img" class="w-100 h-100" /></div>
                      <div *ngIf="currentOption.components.header.type == 'Audio'">
                        <audio controls *ngIf="currentOption.components.header.example != null"
                               controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                               preload="auto"
                               class="play-recording mt-2" id="audio-{{currentOption.id}}">
                          <source [src]="currentOption.components.header.example" type="audio/aac">
                          <source [src]="currentOption.components.header.example" type="audio/amr">
                          <source [src]="currentOption.components.header.example" type="audio/mp4">
                          <source [src]="currentOption.components.header.example" type="audio/mpeg">
                          <source [src]="currentOption.components.header.example" type="audio/ogg; codecs=opus">
                          Your browser does not support the audio element.
                        </audio>
                      </div>

                      <ng-container *ngIf="currentOption.components.header.type=='Contacts'">
                        <div *ngFor="let contact of currentOption.whatsappMessageContactsContent.contacts">
                          <div class="mt-2 mb-2 custom-card">
                            <div class="contact-header d-flex justify-content-start align-items-center position-relative">
                              <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" height="38px" viewBox="0 0 20 20" width="48px" fill="#A7C4E5"><g><rect fill="none" height="20" width="20" /></g><g><g><path d="M10 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 3.5c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 11c-2.05 0-3.87-.95-5.07-2.44 1.45-.98 3.19-1.56 5.07-1.56s3.62.58 5.07 1.56c-1.2 1.49-3.02 2.44-5.07 2.44z" /></g></g></svg>
                              <span>
                                <b class="formatte-name">{{contact.name.formattedName}}</b>
                              </span>

                              <div *ngIf="currentOption.unstructuredData.useCase == 'contacts-dateTime'" class="contacts-dateTime">
                                {{currentOption.unstructuredData.date | date:'h:mm a'}}
                              </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="d-flex justify-content-center align-items-center">
                              <div (click)="openContactsModal(true, contact.name.formattedName)" class="card-btn right-separator c-left p-2">Invite</div>
                              <div (click)="openContactsModal(false)" class="card-btn c-right p-2">Add contact</div>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="currentOption.components.header.type=='Interactive'">
                        <div *ngIf="currentOption.whatsappMessageInteractiveContent.type == interactiveListType" class="mt-2 mb-2 custom-card">
                          <div class="contact-header position-relative">
                            <div class="pt-2 px-2">
                              <b *ngIf="currentOption.whatsappMessageInteractiveContent.content.header.type == 'Text'">
                                {{currentOption.whatsappMessageInteractiveContent.content.header.content.text}}
                              </b>
                            </div>

                            <div class="p-2" *ngIf="currentOption.whatsappMessageInteractiveContent.content.body.type == 'Text'">
                              {{currentOption.whatsappMessageInteractiveContent.content.body.text}}
                            </div>
                          </div>
                          <mat-divider></mat-divider>
                          <div class="d-flex justify-content-center align-items-center">
                            <div (click)="openBottomSheet()" class="card-btn w-100 c-both p-2">
                              <span style="position: relative; top: -2px;">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#4169E1"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z" /></svg>
                              </span>
                              {{currentOption.whatsappMessageInteractiveContent.content.title}}
                            </div>
                          </div>
                        </div>

                        <div *ngIf="currentOption.whatsappMessageInteractiveContent.type == interactiveButtonsType" class="mt-2 mb-2 custom-card">
                          <div class="p-2" *ngIf="currentOption.whatsappMessageInteractiveContent.content.body.type == 'Text'">
                            {{currentOption.whatsappMessageInteractiveContent.content.body.text}}
                          </div>
                        </div>

                        <div class="mt-1 custom-card" *ngFor="let button of currentOption.whatsappMessageInteractiveContent.content.buttons">
                          <div class="card-btn w-100 c-all p-2">
                            {{button.title}}
                          </div>
                        </div>

                      </ng-container>

                      <div *ngIf="currentOption.components.header.type=='Interactive'"> <b>{{currentOption.components.header.text}}</b></div>

                      <ng-container>
                        <ng-container *ngIf="currentOption.category != 'AUTHENTICATION'; else authDescription">
                          <div [innerHTML]="currentOption.components.body.text" class="mt-1 mb-1"></div>
                        </ng-container>

                        <ng-template #authDescription>
                          <span><b>123456</b> is your verification code.</span>
                          <span *ngIf="currentOption.components.body.addSecurityRecommendation">
                            For your security, do not share this code.
                          </span>
                        </ng-template>
                      </ng-container>
                      <div><small class="gray-color">{{currentOption.components.footer.text}}</small></div>

                      <div *ngIf="currentOption.category == 'AUTHENTICATION' && currentOption.components.footer.codeExpirationMinutes != null">
                        <small class="gray-color">
                          This code expires in {{currentOption.components.footer.codeExpirationMinutes}} minutes.
                        </small>
                      </div>
                    </div>

                    <div *ngIf="currentOption.components.header == null" class="text-container">
                      <ng-container *ngIf="currentOption.components.buttons != null && currentOption.components.buttons.length > 0; else bodyText">
                        <ng-container *ngIf="currentOption.components.buttons[0].subtype != oneTapType && currentOption.components.buttons[0].subtype != copyCodeType; else authDescription">
                          <div [innerHTML]="currentOption.components.body.text" class="mt-1 mb-1"></div>
                        </ng-container>

                        <ng-template #authDescription>
                          <span><b>123456</b> is your verification code.</span>
                          <span *ngIf="currentOption.components.body.addSecurityRecommendation">
                            For your security, do not share this code.
                          </span>
                        </ng-template>

                        <div><small class="gray-color">{{currentOption.components.footer.text}}</small></div>

                        <div *ngIf="currentOption.components.footer.codeExpirationMinutes != null">
                          <small class="gray-color">
                            This code expires in {{currentOption.components.footer.codeExpirationMinutes}} minutes.
                          </small>
                        </div>
                      </ng-container>

                      <ng-template #bodyText>
                        <div [innerHTML]="currentOption.components.body.text" class="mt-1 mb-1"></div>
                        <div><small class="gray-color">{{currentOption.components.footer.text}}</small></div>
                      </ng-template>
                    </div>

                    <div *ngIf="currentOption.components.buttons != null && currentOption.components.buttons.length > 0">
                      <ng-container *ngIf="currentOption.components.buttons[0].subtype == oneTapType; else textBtn">
                        <div *ngFor="let item of currentOption.components.buttons; let i = index" class="mt-1 message-button-container">
                          {{currentOption.components.buttons[i].autofillText}}
                        </div>
                      </ng-container>

                      <ng-template #textBtn>
                        <div *ngFor="let item of currentOption.components.buttons; let i = index" class="mt-1 message-button-container">
                          {{currentOption.components.buttons[i].text}}
                        </div>
                      </ng-template>
                    </div>
                  </div>

                </div>
                <div class="conversation-compose">
                  <div class="emoji">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="smiley" x="3147" y="3209"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z" fill="#7d8489" /></svg>
                  </div>
                  <div class="input-msg"><small>Type a message</small></div>
                  <div class="photo">
                    <i class="lni lni-camera"></i>
                  </div>
                  <button class="send">
                    <div class="circle">
                      <mat-icon>send</mat-icon>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
