import { ExtraService } from "./extra-service.constants"

export const AgentType = {
  Conference: "Conference",
  Phone: "Phone",
  Live: "Live",
  Remote: "Remote"
}

export const AgentPhoneBilligType: { [key: string]: string } = {
  [ExtraService.AgentPriceAnnualFee]: 'Annually',
  [ExtraService.AgentPriceMonthlyFee]: 'Monthly'
}

export const AgentBillingType = {
  Annually: "Annually",
  Monthly: "Monthly"
}

export const LiveAgentBillingType: { [key: string]: string } = {
  ['AnnuallyFee']: "Annually",
  ['MonthlyFee']: "Monthly"
}

export const LiveAgentBillingTypeName = {
  AnnuallyFee: "AnnuallyFee",
  MonthlyFee: "MonthlyFee"
}

export const RemoteAgentBillingType: { [key: string]: string } = {
  ['AnnuallyFee']: "Annually",
  ['MonthlyFee']: "Monthly"
}

export const RemoteAgentBillingTypeName = {
  AnnuallyFee: "AnnuallyFee",
  MonthlyFee: "MonthlyFee"
}
