import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { UserRoles } from '../api-authorization/api-authorization.constants';
import { AuthorizeService } from '../api-authorization/authorize.service';
import { ActionQueueKeys, ActionQueueService } from './management/core/services/common/action-queue.service';
import { LocalStorageService } from './management/core/services/common/local-storage.service';
import { ManageAccountService } from './management/core/services/common/manage-account.service';
import { SurveyService } from './management/core/services/survey/survey.service';
import { ChecklistComponent } from './management/shared-surveys/checklist/checklist.component';
import { PendingSurveyComponent } from './management/shared-surveys/pending-surveys/pending-survey.component';
import { SurveyComponent } from './management/shared-surveys/survey/survey.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [LocalStorageService, ManageAccountService, SurveyService]
})
export class AppComponent implements OnInit, AfterViewInit {

  public title = 'app';
  public loaded = false;
  public userRole: string = '';
  public isSingle: boolean = false;
  private customerCode: string;

  @ViewChild(ChecklistComponent) checklistComponent: ChecklistComponent;
  @ViewChild(PendingSurveyComponent) pendingSurveyComponent: PendingSurveyComponent;

  //important note: is init to null now and not false, in this way will not load twice unde some circumstances
  public checklistStateSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public surveyStateSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private manageAccountService: ManageAccountService,
    private localStorageService: LocalStorageService,
    private authorizeService: AuthorizeService,
    private router: Router,
    private surveyService: SurveyService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private actionQueueService: ActionQueueService) {

    const item = this.localStorageService.getWithExpiry("BetaModules");

    if (item != null) {
      return;
    }

    this.manageAccountService.getAppSettings().subscribe(value => {
      if (value == null) {
        return;
      }

      this.localStorageService.setWithExpiry("BetaModules", value, 1);
    });
  }

  ngOnInit() {
    this.authorizeService.isAccessTokenExpired().subscribe(expired => {
      if (expired) {
        this.router.navigate(["/authentication/logout"], { state: { local: true } });
        return;
      }
    });

    this.authorizeService.getUser().subscribe(user => {
      if (user == null || user.UserRole == null) {
        return;
      }

      this.userRole = user.UserRole;
      this.customerCode = user.CustomerCode;

      if (this.userRole === UserRoles.Customer || this.userRole === UserRoles.Reseller) {
        this.loadActiveSurvey();
      }
    });

    this.checklistStateSubject.pipe(distinctUntilChanged()).subscribe(state => {
      if (this.checklistComponent != null &&
        this.checklistComponent.checklists.every(x => x.tasks.every(e => e.isCompleted))) {
        this.checklistComponent.hasChecklists.next(false);
      }

      this.handleStateChange('checklist');
    });

    this.surveyStateSubject.pipe(distinctUntilChanged()).subscribe(state => {
      this.handleStateChange('survey');
    });
  }

  ngAfterViewInit() {
    if (this.pendingSurveyComponent) {
      this.pendingSurveyComponent.isSaved.subscribe(value => {
        if (value == null) {
          return;
        }

        this.loadChecklists();
        this.loadPendingSurveys();
      });
    }

    if (this.checklistComponent) {
      this.checklistComponent.hasChecklists.subscribe(value => {
        if (value == null) {
          return;
        }

        this.isSingle = !value;
      });
    }
  }

  checkSurveyInterval(): boolean {
    const intervalStr = this.localStorageService.get(`lastSurveyDate_${this.customerCode}`);

    if (intervalStr == null) {
      return false; // No stored date means it's time for a new survey
    }

    const lastSurveyDate = new Date(intervalStr);
    const now = new Date();

    // Include hours, minutes, and seconds for accurate comparison
    const lastSurveyDateTime = Date.UTC(
      lastSurveyDate.getUTCFullYear(),
      lastSurveyDate.getUTCMonth(),
      lastSurveyDate.getUTCDate(),
      lastSurveyDate.getUTCHours(),
      lastSurveyDate.getUTCMinutes(),
      lastSurveyDate.getUTCSeconds()
    );

    const nowDateTime = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );

    this.getTimeDifference(lastSurveyDateTime, nowDateTime);

    return nowDateTime < lastSurveyDateTime;
  }

  getTimeDifference(lastSurveyDate: number, now: number) {
    const differenceInMilliseconds = lastSurveyDate - now;
    let differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    if (differenceInMinutes < 0) {
      differenceInMinutes = 0;
    }

    console.log(`***Time until survey check: ${differenceInMinutes.toFixed(1)} minutes***`);
  }

  handleStateChange(changedSubject: 'survey' | 'checklist') {
    const surveyState = this.surveyStateSubject.value;
    const checklistState = this.checklistStateSubject.value;

    if (surveyState === true && checklistState === true) {
      if (changedSubject === 'survey') {
        this.checklistStateSubject.next(false);
      } else {
        this.surveyStateSubject.next(false);
      }
    }
  }

  loadActiveSurvey() {
    if (this.loaded) {
      return;
    }

    this.loaded = true;

    if (this.checkSurveyInterval()) {
      this.actionQueueService.completeWaitingAction(ActionQueueKeys.surveyKey);

      this.loadChecklists();
      this.loadPendingSurveys();
      return;
    }

    this.surveyService.getSurvey('').subscribe(survey => {
      if (survey == null) {
        console.log(`***No survey available***`);

        this.actionQueueService.completeWaitingAction(ActionQueueKeys.surveyKey);
        this.loadChecklists();
        this.loadPendingSurveys();
        return;
      }

      this.actionQueueService.enqueue(() => this.openSurveyDialog(survey), ActionQueueKeys.surveyKey);
    });
  }

  openSurveyDialog(survey) {
    const isMobile = this.breakpointObserver.isMatched(Breakpoints.Handset);

    const dialogRef = this.dialog.open(SurveyComponent, {
      autoFocus: false,
      data: {
        survey: survey,
        customerCode: this.customerCode
      },
      disableClose: true,
      minWidth: isMobile ? '100vw' : '65vw',
      maxHeight: isMobile ? '100vh' : '80vh',
      height: isMobile ? '100vh' : 'auto',
      panelClass: isMobile ? 'full-screen-dialog' : '',
    });

    dialogRef.componentInstance.surveySaved.subscribe(() => {
      this.actionQueueService.completeWaitingAction(ActionQueueKeys.surveyKey, 500);
      this.loadChecklists();
      this.loadPendingSurveys();
    });
  }

  loadChecklists() {
    if (this.checklistComponent) {
      this.checklistComponent.loadChecklists();
    }
  }

  loadPendingSurveys() {
    if (this.pendingSurveyComponent) {
      this.pendingSurveyComponent.loadPendingSurveys();
    }
  }

  //this.authorizeService.isAccessTokenExpired().subscribe(expired => {
  //  console.log('isTokenExpired', expired);
  //  if (expired === true) {
  //    this.router.navigate(["/authentication/logout"], { state: { local: true } });
  //  }
  //});
}

