export interface IWhatsappMessageInteractiveContent {
  /**
   * See WhatsappMessageInteractiveType enum
   */
  type: string;
  content: any;
}

export enum WhatsappMessageInteractiveType {
  Buttons = "Buttons",
  List = "List",
  Product = "Product",
  ProductList = "ProductList"
}

