import { Injectable } from '@angular/core';
import { ViewInboundActiveNumberSettingsFormService } from './view-inbound-active-number-settings-form.service';

@Injectable()
export class ViewInboundActiveNumberSettingsLoaderService {

  constructor(private formService: ViewInboundActiveNumberSettingsFormService) { }

  loadData(connectionSettings: any) {
    this.formService.form.patchValue({
      user: connectionSettings.User,
      authUser: connectionSettings.AuthUser,
      secret: connectionSettings.Secret,
      host: connectionSettings.Host,
      port: connectionSettings.Port,
      extension: connectionSettings.Extension,
      telephoneNumber: connectionSettings.TelephoneNumber,
      additionalInfo: connectionSettings.AdditionalInfo,
      stasisApp: connectionSettings.StasisApp
    });
  }
}
