<div class="float-right" (click)="close()"><i class='lni lni-close'></i></div>
<div class="text-center credit-pop-up">
  <h2><i class='lni lni-hand'></i></h2>
  <h4> ALERT</h4>
  <h4>CREDIT BELOW THE THRESHOLD</h4>

  <p class="mg-top2rem">Your credit is below the threshold you set:</p>
  <p> the current credit is {{currentCredit | number : '1.3-5'}}<i class='lni lni-euro'></i>.</p>
  <p>
    <span *ngIf="isReseller">Top up immediately or activate the self-recharge function, otherwise go to account settings to modify the alert threshold!</span>
    <span *ngIf="!isReseller">Go to account settings to modify the alert threshold! </span>
  </p>

  <div *ngIf="isReseller" class="mg-top1rem">
    <button mat-raised-button
            cdkFocusInitial
            color="accent"
            (click)="goToRecharge()">
      RECHARGE
    </button>
  </div>

  <!--<div *ngIf="!isReseller" class="mg-top1rem">
  <button mat-raised-button
          cdkFocusInitial
          color="accent"
          (click)="close()">
    OK, I UNDERSTAND
  </button>
</div>-->

  <div class="mg-top1rem">
    <button mat-raised-button
            cdkFocusInitial
            color="accent"
            (click)="goToAllertSettings()">
      SET THRESHOLD
    </button>
  </div>
</div>
