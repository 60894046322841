export const MicroserviceChannles= {
  DidRequest: 'DidRequest',
  Campaign: 'Campaign',
  Anonymization: 'Anonymization',
  AgentRequest: 'AgentRequest',
  EmailRequest: 'EmailRequest',
  Email: 'Email',
  Voice: 'Voice',
  Sms: 'Sms',
  General: 'General'
}


export const MicroserviceChannelsList = [
  { value: '', name: 'All' },
  { value: MicroserviceChannles.DidRequest, name: 'Did Request' },
  { value: MicroserviceChannles.Campaign, name: 'Campaign' },
  { value: MicroserviceChannles.Anonymization, name: 'Anonymization' },
  { value: MicroserviceChannles.AgentRequest, name: 'Agent Request' },
  { value: MicroserviceChannles.EmailRequest, name: 'Email Request' },
  { value: MicroserviceChannles.Email, name: 'Email' },
  { value: MicroserviceChannles.Voice, name: 'Voice' },
  { value: MicroserviceChannles.Sms, name: 'Sms' },
  { value: MicroserviceChannles.General, name: 'General' }
  
]

export const MicroserviceStatusList = [
  { value: 'Running', name: 'Running' },
  { value: 'Stopped', name: 'Stopped' },
  //{ value: 'MayBeRunning', name: 'MayBeRunning' },
  { value: '', name: 'All' }
]
