import { DataSource } from '@angular/cdk/table';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import { PaymentMethods } from '../../../../core/constants/payment.constants';
import { ScreenSize } from '../../../../core/constants/screen-size.constants';
import { IPaymentMethod } from '../../../../core/interfaces/payment/payment-method';
import { PaymentsService } from '../../../../core/services/common/payments.service';
import { DeleteItemsConfirmationComponent } from '../../delete-items-confirmation/delete-items-confirmation.component';
import { EditPaymentMethodComponent } from './edit-payment-method/edit-payment-method.component';

declare var Stripe: any;

@Component({
  selector: 'app-manage-payment-methods',
  templateUrl: './manage-payment-methods.component.html',
  styleUrls: ['./manage-payment-methods.component.scss'],
  providers:[PaymentsService]
})
export class ManagePaymentMethodsComponent implements OnInit {

  public dataSource: PaymentMethodsDataSource | null;
  public displayedColumns = ['brand', 'type', 'details', 'expireMonth', 'isDefault', 'actions']
  public innerWidth: any;
  public tabletMode: boolean = false;


  private paymentMethods: BehaviorSubject<IPaymentMethod[]> = new BehaviorSubject<IPaymentMethod[]>([]);
  private stripeCustomerId: string = null
  
  constructor(private titleService: Title,
    private dialog: MatDialog,
    private route:Router,
    private paymentService: PaymentsService) {

    this.titleService.setTitle("Payment Methods | Dillo");

    this.getStripeInfo();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = event.target.innerWidth;


    if (this.innerWidth < ScreenSize.BreakpointLaptop) {
      this.tabletMode = true;
      return;
    }

    this.tabletMode = false;
  }

  ngOnInit() {

    window.innerWidth < ScreenSize.BreakpointLaptop ? this.tabletMode = true : this.tabletMode = false;

    this.dataSource = new PaymentMethodsDataSource(this.paymentMethods);
    //console.log("paymentMethods", this.dataSource);
    
  }

  deletePaymentMethod(paymentMethod:IPaymentMethod) {
    this.paymentService.deletePaymentMethod(paymentMethod.id,this.stripeCustomerId).subscribe(x => {
     
      let arr = this.paymentMethods.getValue();
      

      arr.forEach((item, index) => {
        if (item.id === paymentMethod.id) {
          arr.splice(index, 1);
        }

        arr.map(item => {
          if (item.id == x) {
            item.isDefault = true
          }
        })
      });
      this.paymentMethods.next(arr);
    });
    
  }

  confirmDelete(element: IPaymentMethod) {
    let dialogMessage = `<div>Are you sure you want to delete this payment method?</div>`;

    this.paymentService.getAutoRechargeInfo().subscribe(response => {
      if (response != null) {

        if (response.primaryPaymentMethod != null && response.primaryPaymentMethod.id == element.id) {
          dialogMessage = `<div> This payment method is saved as primary method on Auto Recharge! </div><div>Are you sure you want to delete this payment method?</div>`;
        }

        if (response.secondaryPaymentMethod != null && response.secondaryPaymentMethod.id == element.id) {
          dialogMessage = `<div> This payment method is saved as secondary method on Auto Recharge! </div><div>Are you sure you want to delete this payment method?</div>`;
        }
      }
      this.openDeleteDialog(dialogMessage, element);
    })
    
  }

  openDeleteDialog(dialogMessage: string, paymentMethod: IPaymentMethod) {
    const dialogRef = this.dialog.open(DeleteItemsConfirmationComponent, {
      minWidth: "25rem",
      minHeight: "7rem",
      panelClass: "logs-dialog-container",
      data: dialogMessage
    });

    dialogRef.afterClosed().subscribe(response => {
      if (!response) {
        return;
      }

      this.deletePaymentMethod(paymentMethod);
    });
  }

  getStripeInfo() {
    this.paymentService.isStripeCustomer().subscribe(x => {
      this.stripeCustomerId = x;

      if (x != null) {
        this.getPaymentMethods();
      }
    });
  }

  getPaymentMethods() {
    this.paymentService.getPaymentMethods(this.stripeCustomerId).subscribe({
      next: data => {
        this.paymentMethods.next(data);
      }

    });
  } 
  

  openEditPaymentMethod(element) {
    const existDefault = this.checkIfExistDefault();
    this.paymentService.createSetupIntent().subscribe(x => {
      if (x == null) {
        return;
      }
      var minWidth = this.tabletMode ? '20rem' : '25rem';
      var maxWidth = this.tabletMode ? '25rem' : '30rem';
      const dialogRef = this.dialog.open(EditPaymentMethodComponent, {
        minWidth: minWidth,
        minHeight: "5rem",
        maxWidth: maxWidth,
        panelClass: "logs-dialog-container",
        disableClose: true,
        data: {
          intentClientSecret: x,
          stripeCustomerId: this.stripeCustomerId,
          paymentMethod: element,
          exists:existDefault
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (response == null) {
          return;
        }
        this.handelResponseEdit(response);

      })
    });
  }

  handelResponseEdit(response) {
    //console.log("responseAddPayment=", response);
    if (response.saved && response.paymentMethod != null) {
      this.getPaymentMethods();
    }
  }

  checkIfExistDefault(): boolean {
    let arr = this.paymentMethods.getValue();
    let count: number = 0;

    if (arr == null) {
      return false;
    }
    from(arr).pipe(
      map(x => x.isDefault == true ? 1 : 0),
      reduce((a, b) => a + b)
    ).subscribe(x => {
      count = x;
    });
    if (count > 0) {
      return true;
    }
    return false;
  }

  
}



export class PaymentMethodsDataSource extends DataSource<any>{
  constructor(private subject: BehaviorSubject<IPaymentMethod[]>) {
    super();
  }

  connect(): Observable<IPaymentMethod[]> {
    return this.subject.asObservable();
  }

  disconnect() { };
}
