import { Component, Input } from '@angular/core';
import { IWhatsappContact } from '../../../core/interfaces/whatsapp/whatsapp-message-contacts-content';
import { WhatsappMessageInteractiveType } from '../../../core/interfaces/whatsapp/whatsapp-message-interactive-content';
import { WhatsappTemplateOtpButtonSubtype } from '../../../core/interfaces/whatsapp/whatsapp-template.interface';

@Component({
  selector: 'app-phone-viewer',
  templateUrl: './phone-viewer.component.html',
  styleUrl: './phone-viewer.component.scss'
})
export class PhoneViewerComponent {

  //should not be changed
  public width = 280;
  public maxWidth: number = 280;
  public maxHeight: number = 520;

  @Input() currentOption: any = null;

  public oneTapType: string = WhatsappTemplateOtpButtonSubtype.ONE_TAP;
  public copyCodeType: string = WhatsappTemplateOtpButtonSubtype.COPY_CODE;

  //contacts backdrop
  public isBackdropVisible: boolean = false;
  public isContactsModalVisible: boolean = false;
  public isBottomSheetVisible: boolean = false;

  public isInviteType: boolean = false;

  public currentContact: IWhatsappContact;

  //interactive types
  public interactiveListType = WhatsappMessageInteractiveType.List;
  public interactiveButtonsType = WhatsappMessageInteractiveType.Buttons;

  constructor() { }

  ngOnInit() {
  }

  createBackdrop() {
    this.isBackdropVisible = true;
  }

  removeBackdrop() {
    this.isBackdropVisible = false;
    this.isContactsModalVisible = false;
    this.isBottomSheetVisible = false;
  }

  openContactsModal(isInvite: boolean, formattedName?: string) {
    this.createBackdrop();
    this.isContactsModalVisible = true;
    this.isInviteType = isInvite;

    if (isInvite && formattedName != null) {
      this.currentContact = this.currentOption.whatsappMessageContactsContent.contacts
        .find(x => x.name.formattedName == formattedName);
    }
  }

  openBottomSheet() {
    this.createBackdrop();
    this.isBottomSheetVisible = true;
  }

}
