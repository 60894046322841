import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApiRequest } from '../../../core/interfaces/sms/api-request.interface';
import { InboundApiRequestDataSource } from '../../../core/datasource/sms/inbound-api-request-data-source';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-view-inbound-api-requests',
  templateUrl: './view-inbound-api-requests.component.html',
  styleUrls: ['./view-inbound-api-requests.component.scss']
})
export class ViewInboundApiRequestsComponent implements OnInit {

  public dataSource: InboundApiRequestDataSource | null;
  public apiRequests: BehaviorSubject<IApiRequest[]> = new BehaviorSubject<IApiRequest[]>([]);
  public displayedColumns=["channel", "requestId"];

  constructor(public dialogRef: MatDialogRef<ViewInboundApiRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IApiRequest[] | null) {
   
  }

  ngOnInit() {
    this.dataSource = new InboundApiRequestDataSource(this.apiRequests);
    console.log("api requests:", this.data);
    this.apiRequests.next(this.data);
  }

}
