<div *ngIf="hasSurveys">
  <button style="z-index: 999" [ngClass]="{'isSingle': isSingle}" class="fixed-button" (click)="toggleSurveys()">
    <mat-icon class="button-icon">arrow_left_alt</mat-icon> What's left?
  </button>
</div>

<div class="side-popup" [class.open]="toggleStateSubject.value">
  <div class="bkg-lighter w-100 d-flex flex-column checklist-header p-3">
    <div class="w-100 d-flex flex-row justify-content-between">
      <h3>Surveys to complete</h3>
      <button class="no-style" (click)="toggleSurveys()">&#x2715;</button>
    </div>
    <p>It seems you have left some questions behind!</p>
    <p>Remember that surveys help us improve your user experience, answer the questions and enjoy the full potential of Dillo.</p>
  </div>

  <div class="survey-list checklist-content p-3">
    <div *ngFor="let survey of surveys">
      <span class="survey-title">{{ survey.name }}</span>
      <span class="survey-description">{{ survey.description }}</span>
      <div *ngIf="survey.status == 'Skipped' || survey.status == 'RetryNeeded'" class="w-100 d-flex flex-row justify-content-between align-items-center mt-2">
        <button type="button" [disabled]="inProgress" class="no-style review" (click)="loadActiveSurvey(survey.surveyId)">Review your responses</button>
      </div>
      <hr class="group-separator" />
    </div>
  </div>
</div>
