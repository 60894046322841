<h1 mat-dialog-title>Intents</h1>
<p class="text-gray-dark">Bot name:&nbsp;{{bot.name}} </p>

<div *ngIf="!noSelectedBot">
  <div mat-dialog-content *ngIf="botIntents.length>0">
    <div *ngFor="let item of botIntents; let i = index">
      <div class="row mt-1">
        <div class="col-sm-8 tag-name"><b>{{item.name}} </b></div>
        <div class="col-sm-4">
          <button mat-button color="accent" (click)="copyToClipboard(item)">
            <span class="lni-plus"></span>
            &nbsp; Add
          </button>
        </div>
      </div>
    </div>
    <div class="info-txt">*Please don't modify this value after insert.</div>
  </div>

  <div *ngIf="this.botIntents.length==0 && isLoaded">
    The selected bot doesn't have any intents.
  </div>
</div>
<div *ngIf="noSelectedBot">
  First you must select an active bot in the previous step.
</div>
