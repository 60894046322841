
<mat-card class="shadow">
  <mat-card-content>
    <mat-grid-list cols="6" rowHeight="4.5rem" gutterSize="2rem" layout-fill>
      <mat-grid-tile colspan="2">
        <mat-form-field class="full-width custom-select-padding">
          <mat-label>Start date</mat-label>
          <input matInput [matDatepicker]="pickerStart"
                 [formControl]="startDate"
                 [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart
                          (daySelected)="startDateHandler($event, pickerStart)">
          </mat-datepicker>
          <mat-error *ngIf="startDate.invalid && startDate.touched">
            The start date is required.
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <mat-form-field class="full-width custom-select-padding">
          <mat-label>End date</mat-label>
          <input matInput [matDatepicker]="pickerEnd"
                 [min]="minEndDate"
                 [max]="maxDate"
                 [formControl]="endDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #pickerEnd
                          (daySelected)="endDateHandler($event, pickerEnd)">
          </mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <div class="text-center mb-3">
          <button mat-raised-button color="accent" type="submit" (click)="loadPayments()">
            Search
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
<div class="mt-4" [hidden]="!loading">
  <div class="progress-spinner-container">
    <div class="progress-spinner-content">
      <mat-progress-spinner mode="indeterminate" color="primary" diameter="75"></mat-progress-spinner>
    </div>
  </div>
</div>
<div *ngIf="noItems" class="undefined-list mt-4">
  No data available.
</div>

<mat-card class="mt-5 shadow" [hidden]="!showMatCard">
  <mat-card-content>
    <div class="items-table-container" [style.max-height.px]="height">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="full-width">

        <ng-container matColumnDef="Timestamp">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode"> Date</span>
            <span *ngIf="tabletMode"> Date</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Timestamp'"
                             [propertyType]="'Timestamp'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            <!--{{element.createdOnUtc | date:'medium'}}-->
            <div>{{element.createdOnUtc | date:'dd/MM/yyyy'}}</div>
            <div>{{element.createdOnUtc | date:'HH:mm:ss' :"+0000"}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Amount">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode"> Amount</span>
            <span *ngIf="tabletMode"> Amount</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Amount'"
                             [propertyType]="'Number'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">{{element.amount}}</td>
        </ng-container>


        <ng-container matColumnDef="AmountWithVat">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">  Amount With VAT</span>
            <span *ngIf="tabletMode">  Amount With VAT</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'AmountWithVat'"
                             [propertyType]="'Number'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">{{element.amountWithVat | number : '1.3-5'}}</td>
        </ng-container>

        <ng-container matColumnDef="PaymentMethod">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">  Payment Method</span>
            <span *ngIf="tabletMode"> Payment Method</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'PaymentMethod'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.paymentMethod !='BankTransfer'">{{element.paymentMethod}}</span>
            <span *ngIf="element.paymentMethod =='BankTransfer'"> Bank transfer</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <span mat-sort-header *ngIf="!tabletMode">  Status</span>
            <span *ngIf="tabletMode"> Status</span>
            <app-filter-grid *ngIf="!tabletMode" [propertyName]="'Status'"
                             [propertyType]="'String'"
                             (filter)="addFilterComponent($event)">
            </app-filter-grid>
          </th>
          <td mat-cell *matCellDef="let element">{{element.status}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <a mat-icon-button (click)="viewTransferInfo(element)" matTooltip="Bank transfer information" *ngIf="element.bankTransferInformation !=null">
              <span class="lni-eye"></span>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator [length]="totalItems"
                   [pageSize]="pageSize"
                   (page)="handlePage($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
