import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-random-message',
  templateUrl: './random-message.component.html',
  styleUrls: ['./random-message.component.scss']
})
export class RandomMessageComponent implements OnInit {

  public dialogMessage: string;

  constructor(public dialogRef: MatDialogRef<RandomMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | null) {

    if (this.data == null) {
      return;
    }
    this.dialogMessage = data;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
