import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

export const slide = trigger('slide', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('0.5s ease-in', style({ transform: 'translateX(0%)' }))
  ]),
  transition(':leave', [
    animate('0.5s ease-out', style({ transform: 'translateX(100%)' }))
  ])
]);

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slide]
})
export class SlidePanelComponent implements OnInit {
  @Input() activePane: PaneType = 'left';

  constructor() { }

  ngOnInit() {
  }

}
type PaneType = 'left' | 'right';

