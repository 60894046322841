import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  public barChartOptions: ChartOptions = {
    responsive: true
  };

  public barChartLabels: string[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataset[] = [];

  constructor(private dialogRef: MatDialogRef<BarChartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (data == null) {
      return;
    }

    this.barChartLabels = data.labels;
    this.barChartLegend = data.showLegend;
    this.barChartData = data.chartData;
    this.barChartOptions.plugins = data.plugins;
  }

  ngOnInit() {
  }

}
