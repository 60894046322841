import { DataSource } from '@angular/cdk/table';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { MicroserviceChannelsList, MicroserviceStatusList } from '../../../../core/constants/microservice-instance.constants';
import { ScreenSize } from '../../../../core/constants/screen-size.constants';
import { FilterGridData } from '../../../../core/interfaces/filter/filter-operator';
import { IMicroserviceInstance } from '../../../../core/interfaces/monitoring/microservice-instace';
import { IMicroserviceInstaceRequest } from '../../../../core/interfaces/monitoring/microservice-instace-request';
import { MonitoringService } from '../../../../core/services/monitoring/monitoring.service';
import { RandomMessageComponent } from '../../random-message/random-message.component';

@Component({
  selector: 'app-microservice-instaces',
  templateUrl: './microservice-instaces.component.html',
  styleUrls: ['./microservice-instaces.component.scss']
})
export class MicroserviceInstacesComponent implements OnInit {

  public height: Number = 0; //the height of the grid

  public instances: BehaviorSubject<IMicroserviceInstance[]> = new BehaviorSubject<IMicroserviceInstance[]>([]);
  public dataSource: MicroserviceInstanceDatasource | null;

  private page: number = 1;
  public pageSize: number = 20;
  private pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public totalItems: number;
  public displayedColumns = ['Name', 'IpAddress', 'Channel', 'Status', 'lastActivityDate', 'action'];

  public loading: boolean = false;
  public noItems: boolean = false;
  public showMatCard: boolean = false;

  public allMicroserviceChanels = MicroserviceChannelsList;
  public allMicroserviceStatuses = MicroserviceStatusList;
  public selectedChannel: UntypedFormControl = new UntypedFormControl();
  public selectedStatus: UntypedFormControl = new UntypedFormControl();


  public innerWidth: any;
  public tabletMode: boolean = false;

  public filterArray: FilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private monitoringService: MonitoringService,
    private dialog: MatDialog,
    private titleService: Title) {

    this.titleService.setTitle("Dillo | Microservice Instance")
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.innerWidth = event.target.innerWidth;


    if (this.innerWidth < ScreenSize.BreakpointLaptop) {
      this.tabletMode = true;
      return;
    }
    this.tabletMode = false;
  }

  ngOnInit() {

    const observer = new ResizeObserver(entries => {
      //important! adjust the value to be subtracted for a good fit of the table on the page 
      this.height = entries[0].contentRect.height - 389;
    });

    observer.observe(document.querySelector('body'));

    window.innerWidth < ScreenSize.BreakpointLaptop ? this.tabletMode = true : this.tabletMode = false;

    this.dataSource = new MicroserviceInstanceDatasource(this.instances);

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p == null) {
        return;
      }

      this.getMicroserviceInstances();
    });

    //this.getMicroserviceInstances();
  }


   readMicroservices(){
     this.page = 1;
     this.paginator.firstPage();
    this.getMicroserviceInstances();
    }


  getMicroserviceInstances() {
    this.loading = true;
    this.showMatCard = false;
    this.noItems = false;

    var request: IMicroserviceInstaceRequest = {
      channel: this.selectedChannel.value,
      status:this.selectedStatus.value,
      page: this.page == null ? 1 : this.page,
      pageSize: this.pageSize,
      filters: this.filterArray,
      sortDirection: this.sortDirection,
      sortField: this.sortField
    };

   

    this.monitoringService.getMicroserviceInstances(request).subscribe({
      next: data => {
        if (request.page == 1) {
          this.totalItems = data.total; 
        }
        this.instances.next(data.data);
        this.loading = false;
        if (this.totalItems > 0) {
          this.showMatCard = true;

        }
        else {
          this.noItems = true;
        }
      }
    });
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  openDescription(element: IMicroserviceInstance) {
    var dialogMessage = `<div>${element.description}</div>`;
    const dialogRef = this.dialog.open(RandomMessageComponent, {
      maxWidth: "25rem",
      minHeight: "7rem",
      data: dialogMessage
    });
  }

  addFilterComponent(event: FilterGridData) {
    //console.log('filter', event);
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      var existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);

    }

    this.page = 1;
    this.getMicroserviceInstances();

  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.page = 1;

    //call again the load method;
    this.getMicroserviceInstances();
  }

}

export class MicroserviceInstanceDatasource extends DataSource<any> {
  constructor(private subject: BehaviorSubject<IMicroserviceInstance[]>) {
    super();
  }

  connect(): Observable<IMicroserviceInstance[]> {
    return this.subject.asObservable();
  }

  disconnect() { }
}
